import {Grid} from '@mui/material';
import TypedFilterInput from './TypedFilterInput';
import {noOp} from '../../common/utils/utils';
import AddFilterButton from '../buttons/AddFilterButton';
import React from 'react';
import {AbstractFilter} from '../../types/AbstractFilter';

interface DayFiltersProps {
    filterArray: AbstractFilter[];
    activeFilterType: string;
    customFilters: string[][];
    isNewLayout: boolean;
    isFilterRemovable: boolean;
    showDayZeroAddFilters: boolean;
    handleDeleteFilter: (s: string) => void;
    setAddFiltersDialogOpen: (b: boolean) => void;
    selectedTab: number;
}

export default function DayFilters({
                                       filterArray,
                                       selectedTab,
                                       activeFilterType,
                                       customFilters,
                                       isNewLayout,
                                       isFilterRemovable,
                                       showDayZeroAddFilters,
                                       handleDeleteFilter,
                                       setAddFiltersDialogOpen
                                   }: DayFiltersProps) {
    return (<>
        {filterArray.map((filter, i) => {
            if (
                activeFilterType !== filter.type &&
                activeFilterType !== 'all' &&
                activeFilterType !== 'custom'
            ) {
                return null;
            } else if (
                activeFilterType === 'custom' &&
                customFilters[selectedTab] &&
                !customFilters[selectedTab].includes(filter.label) &&
                filter.label !== 'Date'
            ) {
                return null;
            }
            if (selectedTab > 0 && filter.label === 'Date') {
                return null;
            }
            return (
                <Grid
                    key={i}
                    item
                    xs={isNewLayout ? 12 : 12}
                    sm={isNewLayout ? 12 : 6}
                    md={isNewLayout ? 12 : 4}
                    lg={isNewLayout ? 6 : 3}
                    xl={isNewLayout ? 4 : 2}
                >
                    <TypedFilterInput
                        filter={filter}
                        checkActiveCount={noOp}
                        deleteCallback={
                            isFilterRemovable
                                ? () => handleDeleteFilter(filter.label)
                                : undefined
                        }
                    />
                </Grid>
            );
        })}
        {showDayZeroAddFilters && (
            <AddFilterButton onClick={() => setAddFiltersDialogOpen(true)} />
        )}
    </>)
}
