import {AbstractStateHoldingFilter} from './AbstractStateHoldingFilter';

export class StateHoldingTimeFilter extends AbstractStateHoldingFilter {

    private _to: any;
    private readonly _setTo: (v: any) => void;
    private _from: any;
    private readonly _setFrom: (v: any) => void;

    constructor(label: string, tooltip: string, type: string, from: any, to: any) {
        super('time', label, tooltip, type);
        this._to = to;
        this._setTo = (v: any) => this._to = v
        this._from = from;
        this._setFrom = (v: any) => this._from = v
    }

    static of(label: string, tooltip: string, type: string): StateHoldingTimeFilter {
        return new StateHoldingTimeFilter(label, tooltip, type, null, null);
    }

    static from(label: string, tooltip: string, type: string, from: any, to: any): StateHoldingTimeFilter {
        return new StateHoldingTimeFilter(label, tooltip, type, from, to);
    }

    get to(): any {
        return this._to;
    }

    get setTo(): (v: any) => void {
        return this._setTo;
    }

    get from(): any {
        return this._from;
    }

    get setFrom(): (v: any) => void {
        return this._setFrom;
    }
}
