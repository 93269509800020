import React, { ReactNode } from 'react';

import Grid from '@mui/material/Grid';
import { useMediaQuery, useTheme } from '@mui/material';

import ProfileSettingsSidebar from '../component/parts/ProfileSettingsSidebar';
import { Page } from '../constants/enums';
import { colors as c } from '../common/theme';

interface Props {
    children: NonNullable<ReactNode>;
    page: Page;
}

export default function ProfilePage({ children, page }: Props) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Grid container padding="16px 24px" height={isSmallScreen? 'auto' : 'calc(100vh - 64px)'}>
            <ProfileSettingsSidebar page={page} />
            <Grid
                item
                xs={12}
                sm={6}
                md={9}
                sx={{ backgroundColor: c.bg.light }}
            >
                {children}
            </Grid>
        </Grid>
    );
}
