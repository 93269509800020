import React from 'react';
import {NumericFilter} from '../../types/NumericFilter';
import FromToInput from '../parts/FromToInput';
import {DateFilter} from '../../types/DateFilter';
import FromToDate from '../parts/FromToDate';
import {TimeFilter} from '../../types/TimeFilter';
import FromToTime from '../parts/FromToTime';
import {AutocompleteFilter} from '../../types/AutocompleteFilter';
import FilterAutocomplete from '../parts/FilterAutocomplete';
import {BooleanFilter} from '../../types/BooleanFilter';
import TrueFalseOrEmpty from '../parts/TrueFalseOrEmpty';
import {AbstractFilter} from '../../types/AbstractFilter';
import {StateHoldingFilter} from '../../types/StateHoldingFilter';
import {StateHoldingTimeFilter} from '../../types/StateHoldingTimeFilter';
import {StateHoldingBooleanFilter} from '../../types/StateHoldingBooleanFilter';
import {StateHoldingAutocompleteFilter} from '../../types/StateHoldingAutocompleteFilter';
import WrappedFilterAutocomplete from '../parts/WrappedFilterAutocomplete';
import WrappedFromToInput from '../parts/WrappedFromToInput';
import WrappedFromToTime from '../parts/WrappedFromToTime';
import {StateHoldingSelectFilter} from '../../types/StateHoldingSelectFilter';
import SelectInput from '../parts/SelectInput';

interface Props {
    filter: AbstractFilter;
    checkActiveCount: () => void;
    disableNulls?: boolean;
    deleteCallback?: () => void;
}

export default function TypedFilterInput({filter, checkActiveCount, disableNulls, deleteCallback}: Props) {
    if (filter instanceof NumericFilter) {
        return <FromToInput
            disableNulls={disableNulls}
            checkActiveCount={checkActiveCount}
            value={filter.value}
            setValue={filter.setValue}
            label={filter.label}
            tooltip={filter.tooltip}
            deleteCallback={deleteCallback}
        />;
    } else if (filter instanceof DateFilter) {
        return <FromToDate
            label={filter.label}
            dateTo={filter.to}
            setDateTo={filter.setTo}
            dateFrom={filter.from}
            setDateFrom={filter.setFrom}
            tooltip={filter.tooltip}
        />;
    } else if (filter instanceof TimeFilter) {
        return <FromToTime
            disableNulls={disableNulls}
            checkActiveCount={checkActiveCount}
            label={filter.label}
            dateTo={filter.to}
            setDateTo={filter.setTo}
            dateFrom={filter.from}
            setDateFrom={filter.setFrom}
            tooltip={filter.tooltip}
            deleteCallback={deleteCallback}
        />;
    } else if (filter instanceof AutocompleteFilter) {
        return <FilterAutocomplete
            disableNulls={disableNulls}
            checkActiveCount={checkActiveCount}
            label={filter.label}
            path={filter.path}
            value={filter.value}
            setValue={filter.setValue}
            tooltip={filter.tooltip}
            deleteCallback={deleteCallback}
        />;
    } else if (filter instanceof BooleanFilter || filter instanceof StateHoldingBooleanFilter) {
        return <TrueFalseOrEmpty
            disableNulls={disableNulls}
            checkActiveCount={checkActiveCount}
            topLabel={filter.label}
            value={filter.value}
            setValue={filter.setValue}
            tooltip={filter.tooltip}
            deleteCallback={deleteCallback}
        />;
    } else if (filter instanceof StateHoldingAutocompleteFilter) {
        return <WrappedFilterAutocomplete
            disableNulls={disableNulls}
            checkActiveCount={checkActiveCount}
            label={filter.label}
            path={filter.path}
            value={filter.value}
            setValue={filter.setValue}
            tooltip={filter.tooltip}
            deleteCallback={deleteCallback}
        />;
    } else if (filter instanceof StateHoldingFilter) {
        return <WrappedFromToInput
            disableNulls={disableNulls}
            checkActiveCount={checkActiveCount}
            value={filter.value}
            setValue={filter.setValue}
            label={filter.label}
            tooltip={filter.tooltip}
            deleteCallback={deleteCallback}
        />;
    } else if (filter instanceof StateHoldingTimeFilter) {
        return <WrappedFromToTime
            disableNulls={disableNulls}
            checkActiveCount={checkActiveCount}
            label={filter.label}
            dateTo={filter.to}
            setDateTo={filter.setTo}
            dateFrom={filter.from}
            setDateFrom={filter.setFrom}
            tooltip={filter.tooltip}
            deleteCallback={deleteCallback}
        />;
    } else if (filter instanceof StateHoldingSelectFilter) {
        return <SelectInput
            setValue={filter.setValue}
            label={filter.label}
            tooltip={filter.tooltip}
            value={filter.value}
            options={filter.values}
        />
    }
    return null;
}
