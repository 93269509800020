import React, { FC } from 'react';
import { Input, styled } from '@mui/material';
import { colors as c } from '../../common/theme';
type TextInputProps = {
    value: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    type?: string;
};

// this overwrites the default input style when browser autofills the input
const StyledInput = styled(Input)`
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #8fd1d6 !important;
        -webkit-box-shadow: 0 0 0px 1000px #121515 inset !important;
        box-shadow: 0 0 0px 1000px #121515 inset !important;
    }
`;

const TextInput: FC<TextInputProps> = ({ value, onChange, type = 'text' }) => {
    return (
        <StyledInput
            sx={{
                backgroundColor: c.gray.darker,
                borderRadius: '4px',
                border: `1px solid ${c.gray.dim}`,
                color: c.lightBlue,
                paddingY: '13px',
            }}
            inputProps={{
                style: { textIndent: 12, padding: 0 },
            }}
            type={type}
            autoCorrect="off"
            fullWidth
            disableUnderline
            value={value}
            onChange={onChange}
        />
    );
};

export default TextInput;
