import React, { MouseEventHandler } from 'react';

import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

interface Props {
    date: any;
    handleChange: (v: any) => void;
    label: string;
    disabled?: boolean;
    onMouseEnter?: MouseEventHandler<any> | undefined;
}

export default function CustomTimePicker({
    date,
    handleChange,
    label,
    disabled,
    onMouseEnter,
}: Props) {
    return (
        <MobileTimePicker
            onOpen={() => onMouseEnter}
            disabled={disabled}
            ampm={true}
            value={date}
            onChange={handleChange}
            slotProps={{
                actionBar: { actions: ['clear', 'accept'] },
                textField: {
                    placeholder: label,
                    variant: 'standard',
                    InputProps: {
                        disableUnderline: true,
                        slotProps: { input: { sx: { padding: 0 } } },
                    },
                },
            }}
        />
    );
}
