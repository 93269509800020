/* eslint-disable react/prop-types */
import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import CustomDateTimePicker from './CustomDateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Box } from '@mui/material';

export default function PriceFilterToolbar({dateTo, handleDateToChange, dateFrom, handleDateFromChange, period, handleChangePeriod}) {

    return (
        <Box sx={{padding: 1, paddingLeft: 0}}>
            <Toolbar>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Box sx={{paddingRight: 2}}>
                        <CustomDateTimePicker date={dateFrom} handleChange={handleDateFromChange} label={'Date from'}/>
                    </Box>
                    <Box sx={{paddingRight: 2}}>
                        <CustomDateTimePicker date={dateTo} handleChange={handleDateToChange} label={'Date to'}/>
                    </Box>
                </LocalizationProvider>
                <div>
                    <InputLabel shrink>Period</InputLabel>
                    <Select
                        style={{height: '2.5625em'}}
                        value={period}
                        defaultValue={period}
                        onChange={handleChangePeriod}>
                        <MenuItem value="ONE_MIN">Minute</MenuItem>
                        <MenuItem value="FIVE_MIN">5 Minutes</MenuItem>
                        <MenuItem value="FIFTEEN_MIN">15 Minutes</MenuItem>
                        <MenuItem value="THIRTY_MIN">30 Minutes</MenuItem>
                        <MenuItem value="SIXTY_MIN">60 Minutes</MenuItem>
                        <MenuItem value="ONE_DAY">Day</MenuItem>
                        <MenuItem value="ONE_WEEK">Week</MenuItem>
                        <MenuItem value="ONE_MONTH">Month</MenuItem>
                    </Select>
                </div>
            </Toolbar>
        </Box>
    );
}
