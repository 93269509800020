import React from 'react';
import {colors as c} from '../../common/theme';

const ExcelIcon = () => {
 
    return (
        <svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.375 2.50084H11.25V0.625842C11.25 0.439592 11.1675 0.263342 11.0238 0.144592C10.8813 0.0258422 10.69 -0.0254078 10.51 0.0120922L0.51 1.88709C0.21375 1.94209 0 2.19959 0 2.50084V16.2508C0 16.5508 0.21375 16.8096 0.51 16.8646L10.51 18.7396C10.5475 18.7471 10.5863 18.7508 10.625 18.7508C10.77 18.7508 10.9113 18.7008 11.0238 18.6071C11.1675 18.4883 11.25 18.3108 11.25 18.1258V16.2508H19.375C19.72 16.2508 20 15.9708 20 15.6258V3.12584C20 2.78084 19.72 2.50084 19.375 2.50084ZM8.595 11.4646C8.8225 11.7233 8.79625 12.1183 8.53625 12.3458C8.4175 12.4496 8.27125 12.5008 8.125 12.5008C7.95125 12.5008 7.77875 12.4283 7.655 12.2871L5.8375 10.2108L4.24375 12.2608C4.12 12.4183 3.935 12.5008 3.75 12.5008C3.61625 12.5008 3.48125 12.4583 3.36625 12.3696C3.09375 12.1571 3.045 11.7646 3.25625 11.4921L4.99875 9.25209L3.28 7.28709C3.0525 7.02834 3.07875 6.63334 3.33875 6.40584C3.5975 6.17834 3.99125 6.20334 4.22125 6.46459L5.78125 8.24709L7.6325 5.86709C7.845 5.59584 8.2375 5.54584 8.51 5.75834C8.7825 5.96959 8.83125 6.36209 8.61875 6.63584L6.61875 9.20584L8.595 11.4646ZM18.75 15.0008H11.25V13.7508H13.125C13.47 13.7508 13.75 13.4708 13.75 13.1258C13.75 12.7808 13.47 12.5008 13.125 12.5008H11.25V11.2508H13.125C13.47 11.2508 13.75 10.9708 13.75 10.6258C13.75 10.2808 13.47 10.0008 13.125 10.0008H11.25V8.75084H13.125C13.47 8.75084 13.75 8.47084 13.75 8.12584C13.75 7.78084 13.47 7.50084 13.125 7.50084H11.25V6.25084H13.125C13.47 6.25084 13.75 5.97084 13.75 5.62584C13.75 5.28084 13.47 5.00084 13.125 5.00084H11.25V3.75084H18.75V15.0008Z"
                fill={c.primary}
            />
        </svg>
    );
};

export default ExcelIcon;
