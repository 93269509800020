import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import { Box, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import DividerFullWidth from '../parts/DividerFullWidth';
import { useFilterContext } from '../../common/context/FilterContext';
import TableSummary from './TableSummary';
import ProcessSummary from './ProcessSummary';
import DataTable from './DataTable';
import ChartView from './ChartView';
import CustomSwitch from '../inputs/CustomSwitch';
import FilterToolbar from './FilterToolbar';
import OutputStatistics from './OutputStatistics';
import StatisticsSelect from '../inputs/StatisticsSelect';
import {colors as c} from '../../common/theme';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
            {value === index && <>{children}</>}
        </div>
    );
}

export default function FilterBase() {
    const { response, pageAndRows, isNewLayout } = useFilterContext();
    const [selectedStatistics, setSelectedStatistics] = useState<'day0' | 'day2'>('day0');
    const [tab, setTab] = useState<number>(0);
    const [avg, setAvg] = useState<boolean>(true);

    const isDay2 = selectedStatistics === 'day2';

    const handleChange = (newValue: number) => {
        if (newValue === 1) {
            pageAndRows.set(0, 12);
        } else {
            pageAndRows.set(0, 100);
        }
        setTab(newValue);
    };

    const handleSwitch = () => {
        setAvg(!avg);
    };
    return (
        <Box style={{marginBottom: '80px'}}>
            <Grid container padding="16px 24px" spacing={2}>
                <Grid item xs={isNewLayout ? 6 : 12}>
                    <FilterToolbar />
                </Grid>
                <Grid item xs={isNewLayout ? 6 : 12}>
                    <Paper>
                        <OutputStatistics avg={avg} handleSwitch={handleSwitch} />
                        {!isNewLayout && (
                            <>
                                <Box display="flex" alignItems="center" paddingBottom="14px" padding="24px">
                                    <StatisticsSelect
                                        value={selectedStatistics}
                                        onChange={setSelectedStatistics}
                                        disabled={
                                            response.result &&
                                            (response.result.tickersCount === 0 || response.isLoading)
                                        }
                                    />
                                    <ProcessSummary priceData={response.result} />
                                    <Box display="flex" gap="10px" alignItems="center" marginLeft="24px">
                                        <Typography>Median</Typography>
                                        <CustomSwitch checked={avg} onChange={handleSwitch} />
                                        <Typography>Average</Typography>
                                    </Box>
                                </Box>
                                <DividerFullWidth />
                                <TableSummary showAvg={avg} hideSearchInfo priceData={isDay2 ? response.result.nextDaySummary : response.result} />
                            </>
                        )}
                    </Paper>
                </Grid>
            </Grid>
            <Paper
                sx={{
                    padding: '4px 16px 20px',
                    margin: '24px',
                    marginTop: isNewLayout ? '0px' : '24px',
                }}
            >
                {response.isLoading || (response.result && !response.result.finished) ? (
                    <LinearProgress />
                ) : null}
                <Box display="flex" gap={2} paddingY="16px">
                    <Button
                        variant="outlined"
                        sx={{
                            transition: 'background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out',
                            textTransform: 'none',
                            fontWeight: 600,
                            padding: '12px 16px',
                            fontSize: '14px',
                            border: '1px solid',
                            color: tab === 0 ? c.primary : c.gray.medium,
                            borderColor: tab === 0 ? c.primary : 'transparent',
                            backgroundColor: tab === 0 ? c.gray.dark : 'transparent',
                            '&:hover': {
                                color:tab === 0 ? c.white : c.primary,
                                borderColor:tab === 0 ? c.gray.light: 'transparent',
                                backgroundColor: tab === 0 ? c.gray.dark:'transparent',
                            },
                          }}
                        onClick={() => handleChange(0)}
                    >
                        Table view
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{
                            transition: 'background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out',
                            textTransform: 'none',
                            fontWeight: 600,
                            padding: '12px 16px',
                            fontSize: '14px',
                            border: '1px solid',
                            color: tab === 1 ? c.primary : c.gray.medium,
                            borderColor: tab === 1 ? c.primary : 'transparent',
                            backgroundColor: tab === 1 ? c.gray.dark : 'transparent',
                            '&:hover': {
                                color:tab === 1 ? c.white : c.primary,
                                borderColor:tab === 1 ? c.gray.light: 'transparent',
                                backgroundColor: tab === 1 ? c.gray.dark:'transparent',
                            },
                          }}
                        onClick={() => handleChange(1)}
                    >
                        Chart view
                    </Button>
                </Box>
                <DividerFullWidth />
                <TabPanel value={tab} index={0}>
                    <DataTable />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <ChartView />
                </TabPanel>
            </Paper>
        </Box>
    );
}
