import React, { useState } from 'react';
import {
    Box,
    Dialog,
    FormControl,
    FormControlLabel,
    IconButton, ListSubheader,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Range } from '../../types/Range';
import FilledButton from '../buttons/FilledButton';
import CustomSwitch from '../inputs/CustomSwitch';
import { colors as c } from '../../common/theme';

interface Props {
    dialogOpen: boolean;
    setDialogOpen: (v: boolean) => void;
    addInput: (v: string) => void;
    ranges: Range[];
}

export default function DaySelect({
    dialogOpen,
    setDialogOpen,
    addInput,
    ranges,
}: Props) {
    const [name, setName] = useState<string>('');
    const [range, setRange] = useState<string>('');
    const [dayInput, setDayInput] = React.useState<boolean>(true);

    const handleSwitch = () => {
        setDayInput(!dayInput);
    };

    const add = function () {
        if (dayInput) {
            addInput(name);
            setDialogOpen(false);
            setName('');
        } else {
            addInput(range);
            setDialogOpen(false);
            setRange('');
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleChangeRange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setRange(event.target.value as string);
    };

    function rangeSelect() {
        return (
            <FormControl sx={{ width: '100%' }}>
                <Select
                    value={range}
                    onChange={handleChangeRange as any}
                    displayEmpty
                    sx={{ fontSize: '14px', lineHeight: '24px' }}
                    slotProps={{ input: { sx: { padding: '10px 14px' } } }}
                >
                    <MenuItem value="" disabled>
                        <Typography
                            fontSize="14px"
                            color="rgba(255, 255, 255, 0.7)"
                        >
                            Select range
                        </Typography>
                    </MenuItem>
                    {ranges.map((e, i) => (
                        e.key === null ? <ListSubheader key={i}>{e.label}</ListSubheader> : <MenuItem key={e.key} value={e.key}>{e.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    return (
        <Dialog
            fullWidth
            open={dialogOpen}
            onClose={handleCloseDialog}
            PaperProps={{
                sx: { border: `1px solid ${c.divider}` },
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom={`1px solid ${c.divider}`}
                paddingLeft="16px"
            >
                <Typography fontWeight={600}>Add input</Typography>
                <IconButton
                    color="primary"
                    onClick={handleCloseDialog}
                    aria-label="close"
                    sx={{ padding: '11px', marginRight: '5px' }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box display="flex" alignItems="center" padding="16px">
                <Typography
                    fontSize="14px"
                    marginRight="18px"
                    color={dayInput ? c.lightBlue : c.white}
                >
                    Range
                </Typography>
                <FormControlLabel
                    checked={dayInput}
                    onChange={handleSwitch}
                    control={<CustomSwitch />}
                    label="Day"
                    labelPlacement="end"
                    slotProps={{
                        typography: {
                            color: dayInput ? c.white : c.lightBlue,
                            fontSize: '14px',
                            marginLeft: '10px',
                        },
                    }}
                />
            </Box>
            <Box paddingX="16px">
                {dayInput ? (
                    <TextField
                        autoComplete="off"
                        hiddenLabel
                        type="number"
                        onChange={(event) => setName(event.target.value)}
                        defaultValue={name}
                        sx={{ width: '100%' }}
                        inputProps={{
                            sx: {
                                padding: '11px 14px',
                                fontSize: '14px',
                                lineHeight: '24px',
                            },
                        }}
                        placeholder="Select day (T ± 1), for example +3 or -1"
                        variant="outlined"
                        fullWidth
                    />
                ) : (
                    rangeSelect()
                )}
            </Box>
            <Box padding="16px 16px 27px">
                <FilledButton type="button" onClick={add}>
                    Add
                </FilledButton>
            </Box>
        </Dialog>
    );
}
