import {AbstractFilter} from './AbstractFilter';

export class BaseDateTimeFilter extends AbstractFilter {
    private readonly _to: any;
    private readonly _setTo: (v: any) => void;
    private readonly _from: any;
    private readonly _setFrom: (v: any) => void;

    constructor(label: string, tooltip: string, type: string, to: any, setTo: (v: any) => void, from: any, setFrom: (v: any) => void) {
        super(label, tooltip, type);
        this._to = to;
        this._setTo = setTo;
        this._from = from;
        this._setFrom = setFrom;
    }

    get to(): any {
        return this._to;
    }

    get from(): any {
        return this._from;
    }

    get setTo(): (v: any) => void {
        return this._setTo;
    }

    get setFrom(): (v: any) => void {
        return this._setFrom;
    }
}
