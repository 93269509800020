import React, { Dispatch, SetStateAction } from 'react';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box, Stack } from '@mui/material';

import { FilterArray } from '../filter/FilterArray';
import SetupsMenu from '../filter/SetupsMenu';
import DividerFullWidth from './DividerFullWidth';
import { useFilterContext } from '../../common/context/FilterContext';
import { colors as c } from '../../common/theme';

const classes = {
    filterTypeBtn: {
        padding: '12px 16px',
        minWidth: 'max-content',
        marginRight: '8px',
        border: '1px solid transparent',
        textTransform: 'none',
        transition:
            'color 0.3s ease-out, border-color 0.3s ease-out, background-color 0.3s ease-out',
        fontWeight: 600,
        color: c.gray.medium,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
};

interface Props {
    enabled?: boolean;
    activeFilterTab: string;
    setActiveFilterTab: (s: string) => void;
    tabs: FilterArray[];
    setTabs: Dispatch<SetStateAction<FilterArray[]>>;
    customFilters: string[][];
    setCustomFilters: Dispatch<SetStateAction<string[][]>>;
}

export default function FilterInputHeader({
    enabled,
    activeFilterTab,
    setActiveFilterTab,
    tabs,
    setTabs,
    customFilters,
    setCustomFilters,
}: Props) {
    const { isNewLayout} = useFilterContext()
   
    const activeButtonStyle = {
        backgroundColor: c.gray.dim,
        color: c.primary,
        borderColor: c.primary,
    };
    const inactiveBtnStyle = { color: c.gray.medium };
    if (!isNewLayout)
        return (
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding="13px 24px"
            >
                <Typography fontSize="20px" fontWeight={600}>
                    Input
                </Typography>
                <Box>
                    <Button
                        disableRipple
                        sx={classes.filterTypeBtn}
                        variant="outlined"
                        style={
                            activeFilterTab === 'all'
                                ? activeButtonStyle
                                : inactiveBtnStyle
                        }
                        onClick={() => setActiveFilterTab('all')}
                    >
                        All
                    </Button>
                    <Button
                        disableRipple
                        sx={classes.filterTypeBtn}
                        variant="outlined"
                        style={
                            activeFilterTab === 'desc'
                                ? activeButtonStyle
                                : inactiveBtnStyle
                        }
                        onClick={() => setActiveFilterTab('desc')}
                    >
                        Descriptive
                    </Button>
                    <Button
                        disableRipple
                        sx={classes.filterTypeBtn}
                        variant="outlined"
                        style={
                            activeFilterTab === 'tech'
                                ? activeButtonStyle
                                : inactiveBtnStyle
                        }
                        onClick={() => setActiveFilterTab('tech')}
                    >
                        Technical
                    </Button>
                    <Button
                        disableRipple
                        sx={classes.filterTypeBtn}
                        variant="outlined"
                        style={
                            activeFilterTab === 'custom'
                                ? activeButtonStyle
                                : inactiveBtnStyle
                        }
                        onClick={() => setActiveFilterTab('custom')}
                    >
                        Custom
                    </Button>
                </Box>
                {enabled ? (
                    <SetupsMenu
                        {...{ tabs, setTabs, customFilters, setCustomFilters }}
                    />
                ) : (
                    <Box width={311} />
                )}
            </Box>
        );

    return (
        <Stack divider={<DividerFullWidth />}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding="13px 24px"
            >
                <Typography fontSize="20px" fontWeight={600}>
                    Input
                </Typography>
                {enabled ? (
                    <SetupsMenu
                        {...{ tabs, setTabs, customFilters, setCustomFilters }}
                    />
                ) : (
                    <Box width={311} />
                )}
            </Box>
            <Box padding="13px 24px">
                <Button
                    disableRipple
                    sx={classes.filterTypeBtn}
                    variant="outlined"
                    style={
                        activeFilterTab === 'all'
                            ? activeButtonStyle
                            : inactiveBtnStyle
                    }
                    onClick={() => setActiveFilterTab('all')}
                >
                    All
                </Button>
                <Button
                    disableRipple
                    sx={classes.filterTypeBtn}
                    variant="outlined"
                    style={
                        activeFilterTab === 'desc'
                            ? activeButtonStyle
                            : inactiveBtnStyle
                    }
                    onClick={() => setActiveFilterTab('desc')}
                >
                    Descriptive
                </Button>
                <Button
                    disableRipple
                    sx={classes.filterTypeBtn}
                    variant="outlined"
                    style={
                        activeFilterTab === 'tech'
                            ? activeButtonStyle
                            : inactiveBtnStyle
                    }
                    onClick={() => setActiveFilterTab('tech')}
                >
                    Technical
                </Button>
                <Button
                    disableRipple
                    sx={classes.filterTypeBtn}
                    variant="outlined"
                    style={
                        activeFilterTab === 'custom'
                            ? activeButtonStyle
                            : inactiveBtnStyle
                    }
                    onClick={() => setActiveFilterTab('custom')}
                >
                    Custom
                </Button>
            </Box>
        </Stack>
    );
}
