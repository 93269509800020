interface IError {
    status: number;
    statusText: string;
}

export class Error implements IError{
    status: number;
    statusText: string;

    constructor(status: number, statusText: string) {
        this.status = status;
        this.statusText = statusText;
    }
}
