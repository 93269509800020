export enum StaticUrls {
    LOGIN = '/login',
    FORBIDDEN = '/forbidden',
    FAIL = '/fail',
    RESET_LINK = '/reset-link',
    SIGNUP = '/signup',
    RESET = '/reset-password',
    SUBSCRIPTION = '/subscription',
    FILTER = '/',
    ADMIN = '/admin',
    USER_MANUAL = 'https://quanted.gitbook.io/docs/',
    MY_SETUPS = '/account/setups',
}
