import React, { useState } from 'react';
import { useLocation } from 'react-router';

import Alert from '@mui/material/Alert';

import { sendBody } from '../common/utils/sendBody';
import HeaderFooterWrapper from '../component/parts/HeaderFooterWrapper';
import FilledButton from '../component/buttons/FilledButton';
import Label from '../component/inputs/Label';
import TextInput from '../component/inputs/TextInput';

export default function ResetPasswordPage() {
    const uuid = new URLSearchParams(useLocation().search).get('uuid');

    const [password, setPassword] = useState<string>('');
    const [password2, setPassword2] = useState<string>('');
    const [err, serErr] = useState<string | undefined>(undefined);

    const submit = (event: React.FormEvent) => {
        event.preventDefault();
        sendBody(
            {
                password: password,
                password2: password2,
                uuid: uuid,
            },
            '/api/user/reset-password',
            'POST',
            () => (window.location.href = '/'),
            serErr
        );
    };

    return (
        <HeaderFooterWrapper>
            <form
                onSubmit={submit}
                style={{ maxWidth: '360px', width: '100%' }}
            >
                {err ? (
                    <Alert
                        severity="error"
                        variant="outlined"
                        sx={{ marginBottom: '12px' }}
                    >
                        {err}
                    </Alert>
                ) : null}
                <Label label="Password" marginBottom="35px">
                    <TextInput
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Label>
                <Label label="Confirm password" marginBottom="35px">
                    <TextInput
                        type="password"
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                    />
                </Label>
                <FilledButton type="submit" fullWidth>
                    Reset
                </FilledButton>
            </form>
        </HeaderFooterWrapper>
    );
}
