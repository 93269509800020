/* eslint-disable react/prop-types */
import React from 'react';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {getColorFromString} from '../../common/utils/utils';

export default function InsiderChart({chart}) {

    return (
        <ResponsiveContainer width="100%" height={500}  style={{paddingTop: '16px'}}>
            <LineChart data={chart.data}>
                <XAxis dataKey="name"/>
                <YAxis
                    // allowDataOverflow={true}
                    // domain={[bottom, top]}
                    // type="number"
                />
                <CartesianGrid strokeDasharray="3 3"/>
                <Tooltip/>
                <Legend />
                {chart.names.map((name, i) => {
                    return <Line key={i} type="monotone" connectNulls={true} dataKey={name} stroke={getColorFromString(name)}/>
                } )}
            </LineChart>
        </ResponsiveContainer>
    );
}
