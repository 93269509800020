import React from 'react';

import {Tooltip} from '@mui/material';

import {colors as c} from '../../common/theme';
interface LabelProps {
    label: string;
    tooltip: string;
    defaultStyle?: boolean;
}

export default function TooltipLabel({label, tooltip, defaultStyle}: LabelProps) {
    return (<Tooltip title={tooltip}>
        <span style={defaultStyle ? undefined : {color: c.gray.medium, fontSize: '1rem'}}>
            {label}
        </span>
    </Tooltip>);
}
