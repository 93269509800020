import React from 'react';
import { Button, Typography } from '@mui/material';
import {colors as c} from '../../common/theme';

type OutlinedButtonProps = {
    children: React.ReactNode;
    type?: 'button' | 'submit' | 'reset';
    fullWidth?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    fontSize?: number;
    borderRadius?: string;
};
const OutlinedButton = ({
    children,
    type,
    fullWidth,
    disabled,
    onClick,
    fontSize = 16,
    borderRadius = '8px',
}: OutlinedButtonProps) => {

    return (
        <Button
            onClick={onClick}
            disabled={disabled}
            type={type}
            variant="outlined"
            sx={{
                transition:
                    'background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out',
                color: c.white,
                padding: '12px 16px',
                borderRadius,
                border: '1px solid #0F9F8C',
                flexShrink: 0,
                ':hover': {
                    borderColor: c.gray.light,
                    backgroundColor: 'transparent',
                    color: c.primary,
                },
                ':disabled': {
                    backgroundColor: 'gray',
                    borderColor: 'gray',
                },
            }}
            fullWidth={fullWidth}
            disableRipple
        >
            <Typography
                fontWeight={600}
                fontSize={fontSize}
                textTransform="none"
                whiteSpace="nowrap"
            >
                {children}
            </Typography>
        </Button>
    );
};

export default OutlinedButton;
