import React, {useState} from 'react';
import FromToTime, {FromToTimeProps} from './FromToTime';

export default function WrappedFromToTime({
    dateTo,
    setDateTo,
    dateFrom,
    setDateFrom,
    label,
    tooltip,
    checkActiveCount,
    disableNulls,
    deleteCallback,
}: FromToTimeProps) {
    const [stateFrom, setStateFrom] = useState(dateFrom);
    const [stateTo, setStateTo] = useState(dateTo);

    const handleChangeFrom = (v: any) => {
        setDateFrom(v);
        setStateFrom(v);
    };
    const handleChangeTo = (v: any) => {
        setDateTo(v);
        setStateTo(v);
    };
    return <FromToTime
        dateTo={stateTo}
        setDateTo={handleChangeTo}
        dateFrom={stateFrom}
        setDateFrom={handleChangeFrom}
        label={label}
        tooltip={tooltip}
        checkActiveCount={checkActiveCount}
        disableNulls={disableNulls}
        deleteCallback={deleteCallback}
    />;
}
