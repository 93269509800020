import React, {useState} from 'react';
import { Toolbar} from '@mui/material';
import FromToTime from '../parts/FromToTime';
import PriceChart from './PriceChart';

interface Props {
    ticker: string;
    date: string;
    timeFrom: any;
    timeTo: any;
}

export default function TimeframeToolbarChart({ ticker, date, timeFrom, timeTo } : Props) {
    const [outputFrom, setOutputFrom] = useState(timeFrom);
    const [outputTo, setOutputTo] = useState(timeTo);

    return <>
        <Toolbar sx={{padding:'8px 16px'}}>
            <FromToTime label="Timeframe" dateTo={outputTo} setDateTo={setOutputTo} dateFrom={outputFrom} setDateFrom={setOutputFrom} />
        </Toolbar>
        <PriceChart ticker={ticker} date={date} timeFrom={outputFrom} timeTo={outputTo} />
    </>;
}
