import React, { memo } from 'react';
import { Box, FormControl, MenuItem, Select, styled } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import Label from '../inputs/Label';
import { colors as c } from '../../common/theme';

interface Props {
    topLabel: string;
    tooltip?: string;
    value: any;
    setValue: any;
    checkActiveCount: () => void;
    disableNulls?: boolean;
    deleteCallback?: () => void;
}

const StyledExpandMore = styled(ExpandMore)({
    fill: c.primary,
  });

export default memo(function TrueFalseOrEmpty({
    topLabel,
    value,
    setValue,
    tooltip,
    checkActiveCount,
    disableNulls,
    deleteCallback,
}: Props) {
    const handleClick = (event: any) => {
        setValue(event.target.value);
    };

    const clearValuesAndRemoveFilter = () => {
        if (deleteCallback) {
            setValue(null);
            deleteCallback();
        }
    };

    const disable = disableNulls && !value;
    return (
        <Label
            label={topLabel}
            tooltip={tooltip}
            deleteCallback={
                deleteCallback ? clearValuesAndRemoveFilter : undefined
            }
        >
            <Box
                display="flex"
                border="1px solid"
                sx={{
                    transition:
                        'border-color 0.3s ease-out, background-color 0.3s ease-out',
                }}
                borderColor={!value ? c.gray.dim : `${c.primary}66`}
                bgcolor={!value ? c.gray.darker : c.gray.dark}
                borderRadius={1}
                padding="12px"
                minWidth={300}
            >
                <FormControl fullWidth>
                    <Select
                        variant="standard"
                        onMouseEnter={checkActiveCount}
                        disabled={disable}
                        value={value}
                        defaultValue={value}
                        disableUnderline
                        IconComponent={StyledExpandMore}
                        onChange={handleClick}
                        slotProps={{
                            input: { sx: { padding: 0 } },
                        }}
                        MenuProps={{
                            slotProps: {
                                root: {
                                    slotProps: { backdrop: { sx: { backgroundColor: 'transparent' } } },
                                },
                            },
                        }}
                    >
                        <MenuItem
                            value=""
                            sx={{
                                fontWeight: 600,
                                ':hover': { color: c.primary },
                            }}
                        >
                            <em>-</em>
                        </MenuItem>
                        <MenuItem
                            value="true"
                            sx={{
                                fontWeight: 600,
                                ':hover': { color: c.primary },
                            }}
                        >
                            Yes
                        </MenuItem>
                        <MenuItem
                            value="false"
                            sx={{
                                fontWeight: 600,
                                ':hover': { color: c.primary },
                            }}
                        >
                            No
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Label>
    );
});
