import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CenteredDiv from '../component/parts/CenteredDiv';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import {Box, CardHeader} from '@mui/material';
import styles from '../component/navigation/Navigation.module.css';
import ProductDisplay from '../component/checkout/ProductDisplay';

export default function SubscriptionPage() {
    const [message, setMessage] = useState<string[]>([]);

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setMessage(['Order placed!','You will receive an email confirmation.']);
        }

        if (query.get('canceled')) {
            setMessage(['Order canceled.']);
        }
    }, []);

    return message.length > 0
        ?   <CenteredDiv>
            <Card style={{textAlign: 'center'}}>
                <Typography variant="h5" color="inherit">
                    <img src="/logo200x62.png" alt="Quanted" className={styles.title} />
                </Typography>
                <CardHeader title={message[0]} subheader={message.length > 1 ? message[1] : ''}/>
                <CardActions>
                    <Box style={{width: '100%'}} display="flex" alignItems="center" justifyContent="center">
                        <Button component={Link} to="/" variant="outlined" color="primary">Home</Button>
                    </Box>
                </CardActions>
            </Card>
        </CenteredDiv>
        : <CenteredDiv wide>
            <ProductDisplay />
        </CenteredDiv>;
}
