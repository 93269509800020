import React, {ReactNode, Suspense} from 'react';

import {LinearProgress} from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import {useAppContext} from './common/context/AppContext';
import {getTheme} from './common/theme';
import './App.css';

interface ContentProps {
    isInitialized: () => boolean;
    children: NonNullable<ReactNode>;
}

interface StandardBodyProps {
    children: NonNullable<ReactNode>;
}

export default function StandardBody({children} : StandardBodyProps) {
    const {initialized} = useAppContext();

    let localInitialized = false;

    const initPromise = new Promise<boolean>(resolve => {
        if (initialized) {
            resolve(true);
        }
    });

    function isInitialized() {
        if (localInitialized) {
            return localInitialized;
        }

        throw initPromise.then(init => {
            localInitialized = init;
        });
    }

    function Content({ children, isInitialized }: ContentProps) {
        return (
            <>
                {isInitialized() && children}
            </>
        );
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={getTheme()}>
                <CssBaseline />
                <Suspense fallback={<LinearProgress color="secondary" />}>
                    <Content isInitialized={isInitialized}>
                        {children}
                    </Content>
                </Suspense>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
