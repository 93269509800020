import React from 'react';
import moment from 'moment';

import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

import {StaticUrls} from '../../staticUrls';
import {colors as c} from '../theme';

export function getCookie(name: string) {
    const value = '; ' + document.cookie;
    const parts: string[] = value.split('; ' + name + '=');
    if (parts.length === 2) {
        const pop = parts.pop();
        if (pop) {
            return pop.split(';').shift();
        }
    }
    return null;
}

export function formatDate(date: string) {
    if(date.indexOf('T') > 0)
        return date.split('T')[0];
    return date.slice(0, 16);
}

export function monthAndDay(date: string) {
    return moment(date).format('MMM DD HH:MM');
}

export function largeNumber(x: number) {
    if (!x) return 0;
    return (
        <Tooltip id="log-level" title={numberWithCommas(x)}>
            <Typography fontSize="14px" lineHeight="20px">
                {shortenNumber(x)}
            </Typography>
        </Tooltip>
    );
}

export function largeComparedNumber(curr: number, prev: number) {
    let prefix, color;
    if (curr === prev || prev === 0) {
        prefix = '';
        color = '';
    } else {
        const isMore = curr > prev;
        prefix = isMore ? '+' : '-';
        color = isMore ? c.primary : c.red;
    }
    return (
        <Tooltip id="log-level" title={numberWithCommas(curr)}>
            <b style={{color: color}}>{prefix + shortenNumber(curr)}</b>
        </Tooltip>
    );
}

export function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function shortenNumber(x: number) {
    if(x > 1_000_000_000_000) {
        return (x / 1_000_000_000_000).toFixed(2) + 'T';
    }
    if(x > 1_000_000_000) {
        return (x / 1_000_000_000).toFixed(2) + 'B';
    }
    if(x > 1_000_000) {
        return (x / 1_000_000).toFixed(2) + 'M';
    }
    if(x > 1_000) {
        return (x / 1_000).toFixed(2) + 'K';
    }
    return x;
}

export function gap(gap: number) {
    if (!gap) return 0
    const prefix = (gap > 0) ? '+' : '';
    return <span style={{color: (gap > 0 ? c.primary : c.red)}}>{prefix + gap}%</span>;
}

export function percentage(number: number) {
    if (!number) return 0
    return <span>{number}%</span>;
}

export function yesOrNo(value: boolean) {
    return <span style={{color: (value ? c.primary : c.red)}}>{value ? 'Yes' : 'No'}</span>;
}

export function compared(curr: number, prev: number) {
    let prefix, color;
    if (curr === prev || prev === 0) {
        prefix = '';
        color = '';
    } else {
        const isMore = curr > prev;
        prefix = isMore ? '+' : '-';
        color = isMore ? c.primary : c.red;
    }
    return <span style={{color: color}}>{prefix + curr}</span>;
}

export function diff(diff: number) {
    const prefix = (diff > 0) ? '+' : '';
    return <span style={{color: (diff > 0 ? c.primary : c.red)}}>{prefix + numberWithCommas(diff)}</span>;
}

export function getColorFromString(str: string) {
    let type = str;
    while (type.length && type.length > 0 && type.length < 5) {
        type += type;
    }
    let hash = 0;
    if (type.length === 0) return hash;
    for (let i = 0; i < type.length; i++) {
        hash = type.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 255;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color + '80';
}

export function formatQueryDate(date: string) {
    return moment(date).format('YYYY-MM-DD');
}

export function formatQueryTime(date: string | null) {
    if (!date) return null;
    return moment(date).format('HHmm');
}

export function formatTimeCustom(date: string) {
    return moment(date).format('HH:mm');
}

export function formatTime(date: string | null) {
    if (!date) return '';
    return moment(date).format('hh:mm A');
}

export function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

export function joinArray(array: string[] | string) {
    return Array.isArray(array) ? array.join(',') : array
}

export function hasAdminRole(roles: string[] | undefined) {
    if (!roles) return false;
    return roles.includes('ROLE_ADVANCED');
}

export function encodedLocation() {
    const uri = window.location.pathname + window.location.search;
    if (uri.startsWith(StaticUrls.LOGIN)) {
        return ''
    }
    return encodeURIComponent(uri);
}

export function noOp(): () => void {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {}
}

export function appendScript(src: string) {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.head.appendChild(script);
}
