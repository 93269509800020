import React, { Dispatch, SetStateAction, memo, useState } from 'react';
import moment from 'moment-timezone';
import qs from 'query-string';
import { Box, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import ApiService from '../../common/utils/apiService';
import OutlinedButton from '../buttons/OutlinedButton';
import { useFilterContext } from '../../common/context/FilterContext';
import { FilterType } from '../../common/context/FilterContextTypes';
import { dtoToFilter } from '../../common/context/dtoUtil';
import { FilterArray } from './FilterArray';
import CreateSetup from './CreateSetup';
import Setup from '../../types/Setup';
import {colors as c} from '../../common/theme';

interface Props {
    tabs: FilterArray[];
    setTabs: Dispatch<SetStateAction<FilterArray[]>>;
    customFilters: string[][];
    setCustomFilters: Dispatch<SetStateAction<string[][]>>;
}

const StyledExpandMore = styled(ExpandMore)({
    fill: c.primary,
  });

export default memo(function SetupsMenu({ tabs, setTabs, customFilters, setCustomFilters }: Props) {
    const setupResponse = ApiService<Setup[]>('setup');
    const { filters, clear } = useFilterContext();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [setup, setSetup] = useState('');

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSetup(event.target.value as string);
    };

    function parseQueryAndSetParams(s: Setup) {
        clear();
        const params = qs.parse(s.link);
        Object.entries(filters).forEach(([key, value]) => {
            const paramVal = params[value.queryKey];
            if (paramVal) {
                if (value.type === FilterType.DATE && typeof paramVal === 'string') {
                    value.set(moment(paramVal, 'YYYYMMDD'));
                } else if (value.type === FilterType.TIME && typeof paramVal === 'string') {
                    value.set(moment(paramVal, 'HHmm'));
                } else {
                    value.set(paramVal);
                }
            }
        });
        setTabs([]);
        updateFiltersBySetup(s.name);
        if (s.multidayFilters) {
            //fix parsing and set
            const filterArray = dtoToFilter(JSON.parse(s.multidayFilters));
            setTabs(filterArray);
        }
    }

    const saveCustomFilters = (setupName: string) => {
        localStorage.setItem(`customFilters-${setupName}`, JSON.stringify(customFilters));
    };

    const updateFiltersBySetup = (setupName: string) => {
        const savedFilters = localStorage.getItem(`customFilters-${setupName}`);
        if (savedFilters) {
            const parsedSavedFilters = JSON.parse(savedFilters);
            setCustomFilters(parsedSavedFilters);
        } else {
            setCustomFilters([[]]);
        }
    };

    return (
        <Box display="flex" gap="14px">
            <Box
                border="1px solid"
                borderColor={c.gray.dim}
                borderRadius={1}
                display="flex"
                padding="6px 0 6px 14px"
                alignItems="center"
                width={189}
                sx={{
                    ':hover': { borderColor: c.gray.light },
                }}
            >
                <Select
                    fullWidth
                    variant="standard"
                    value={setup}
                    onChange={handleChange}
                    IconComponent={StyledExpandMore}
                    disableUnderline
                    displayEmpty
                    MenuProps={{
                        slotProps: {
                            root: {
                                slotProps: { backdrop: { sx: { backgroundColor: 'transparent' } } },
                            },
                        },
                    }}
                    sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        marginRight: '14px',
                        padding: 0,
                    }}
                >
                    <MenuItem value="" disabled>
                        Setups
                    </MenuItem>
                    {setupResponse.result &&
                        setupResponse.result.map((setup) => (
                            <MenuItem
                                key={setup.id}
                                value={setup.id}
                                onClick={() => parseQueryAndSetParams(setup)}
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    ':hover': { color: c.primary },
                                }}
                            >
                                {setup.name}
                            </MenuItem>
                        ))}
                </Select>
            </Box>
            <OutlinedButton onClick={() => setDialogOpen(true)} fontSize={14} borderRadius="4px">
                Save setup
            </OutlinedButton>
            <CreateSetup
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                updateSetups={() => setupResponse.fetchData('setup')}
                tabs={tabs}
                saveCustomFilters={saveCustomFilters}
            />
        </Box>
    );
});
