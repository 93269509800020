import React from 'react';
import { ViewAgenda } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';

import CustomSwitch from '../inputs/CustomSwitch';
import { useFilterContext } from '../../common/context/FilterContext';

const LayoutSwich = () => {
    const {isNewLayout, setIsNewLayout} = useFilterContext();
    
    return (
        <Tooltip title="Toggle between layouts" sx={{ marginX: 2 }}>
            <Box display="flex" alignItems="center" gap={1}>
                <ViewAgenda />
                <CustomSwitch checked={isNewLayout} onChange={()=>setIsNewLayout(!isNewLayout)}/>
                <AutoAwesomeMosaicIcon sx={{ transform: 'rotate(270deg)' }} />
            </Box>
        </Tooltip>
    );
};

export default LayoutSwich;
