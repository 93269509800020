/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import PriceDataTable from '../component/details/PriceDataTable';
import Details from '../component/details/Details';
import InsiderOwnership from '../component/details/InsiderOwnership';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import moment from 'moment-timezone';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{padding: 0}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function DetailsPage({props}) {
    const ticker = props.match.params.ticker;

    window.document.title = ticker;

    const [value, setValue] = React.useState(0);
    const [date, setDate] = useState(moment());

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{padding: 2}}>
            <Details ticker={ticker} date={date} setDate={setDate}/>
            <Box sx={{paddingTop: 2}}/>
            <Paper>
                <AppBar position="static" color="transparent">
                    <Tabs value={value} onChange={handleChange} indicatorColor="primary">
                        <Tab label="Price data" {...a11yProps(0)} />
                        <Tab label="Insider ownership" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <PriceDataTable ticker={ticker} />
                </TabPanel>
                <TabPanel value={value} index={1} style={{padding:0}}>
                    <InsiderOwnership ticker={ticker} date={date} />
                </TabPanel>
            </Paper>
        </Box>
    );
}
