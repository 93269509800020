/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import ApiError from '../parts/ApiError';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {Paper} from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import {formatDate, formatQueryDate, numberWithCommas} from '../../common/utils/utils';
import InsiderTransactionsRow from './InsiderTransactionsRow';
import ExpandLessOrMore from '../parts/ExpandLessOrMore';
import InsiderChart from './InsiderChart';
import apiService from '../../common/utils/apiService';

const row = {
    '& td, th': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  };

export default function InsiderOwnership({ticker, date}) {
    const size = 120;
    const response = apiService('company/insider-ownership/' + ticker + '?asOf=' + formatQueryDate(date));
    const [recheck, setRecheck] = useState(true);
    const [open, setOpen] = useState(Array.from({size}, () => false));

    if (response.error) {
        return <ApiError error={response.error}/>;
    }

    if (response.isLoading) {
        return <LinearProgress />;
    }

    const handleClickRow = (i) => {
        open[i] = !open[i];
        setOpen(open);
        setRecheck(!recheck);
    };

    const isOpen = (i) => {
        return open[i];
    };

    return (
        <div>
            <InsiderChart chart={response.result.chart}/>
            <List>
                <ListItem>Insider ownership: {response.result.percentage}%</ListItem>
            </List>
            <TableContainer component={Paper}>
                <Table aria-label="insiders table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Transactions</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Last transaction</TableCell>
                            <TableCell align="left">Shares owned</TableCell>
                        </TableRow>
                    </TableHead>
                    {response.result.owners.map((owner, i) => (
                        <TableBody key={i}>
                            <TableRow key={i} sx={row} onClick={() => handleClickRow(i)}>
                                <TableCell>
                                    <ExpandLessOrMore expanded={isOpen(i)}/>
                                </TableCell>
                                <TableCell align="left">{owner.name}</TableCell>
                                <TableCell align="left">{formatDate(owner.lastTransaction)}</TableCell>
                                <TableCell align="left">{numberWithCommas(owner.beneficialAssetSum)}</TableCell>
                            </TableRow>
                            <InsiderTransactionsRow colSpan={4} isOpen={isOpen(i)} owner={owner} />
                        </TableBody>
                    ))}
                </Table>
            </TableContainer>
        </div>
    );
}
