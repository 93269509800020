import {useState, useEffect, useCallback} from 'react';
import {Error} from '../../types/Error';
import {ApiResponse} from '../../types/ApiResponse';
import {encodedLocation} from './utils';

export default function ApiService<T>(path : string, timeout? : number, text?: boolean) {
    const [result, setResult] = useState<T | any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | any>(null);

    const fetchData = useCallback(async (url: string) => {
        setIsLoading(true);
        try {
            setError(null);
            const response = await fetch('/api/' + url);
            if (response.ok) {
                if (text) {
                    const text = await response.text();
                    setResult(text);
                } else {
                    const json = await response.json();
                    setResult(json);
                }
            } else if (response.status === 403) {
                window.location.href = '/login?redir=' + encodedLocation();
            } else {
                setError(new Error(response.status, response.statusText));
            }
        } catch (e) {
            setError(e);
        }
        setIsLoading(false);
    }, [text])

    useEffect(() => {
        if (timeout && timeout < 0) {
            setIsLoading(false);
        } else {
            fetchData(path);
            if (timeout && timeout > 0) {
                const interval = setInterval(() => fetchData(path), timeout);
                return () => clearInterval(interval);
            }
        }
        return () => clearInterval(0);
    }, [path, setIsLoading, setError, timeout, text, fetchData]);

    function invalidateResult() {
        setResult(null);
    }

    return new ApiResponse<T>(result, isLoading, error, fetchData, invalidateResult);
}
