import React from 'react';
import { colors as c } from '../../common/theme';
const SetupsIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.6091 3.16406C19.2894 1.75501 18.0293 0.703125 16.5234 0.703125C14.776 0.703125 13.3594 2.11972 13.3594 3.86719C13.3594 5.61466 14.776 7.03125 16.5234 7.03125C18.0293 7.03125 19.2894 5.97937 19.6091 4.57031H21.0938C21.4821 4.57031 21.7969 4.25552 21.7969 3.86719C21.7969 3.47886 21.4821 3.16406 21.0938 3.16406H19.6091ZM18.2812 3.86719C18.2812 2.89638 17.4942 2.10938 16.5234 2.10938C15.5526 2.10938 14.7656 2.89638 14.7656 3.86719C14.7656 4.83799 15.5526 5.625 16.5234 5.625C17.4942 5.625 18.2812 4.83799 18.2812 3.86719Z"
                fill={c.gray.light}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5234 15.4688C18.0293 15.4688 19.2894 16.5206 19.6091 17.9297H21.0938C21.4821 17.9297 21.7969 18.2445 21.7969 18.6328C21.7969 19.0211 21.4821 19.3359 21.0938 19.3359H19.6091C19.2894 20.745 18.0293 21.7969 16.5234 21.7969C14.776 21.7969 13.3594 20.3803 13.3594 18.6328C13.3594 16.8853 14.776 15.4688 16.5234 15.4688ZM18.2812 18.6328C18.2812 17.662 17.4942 16.875 16.5234 16.875C15.5526 16.875 14.7656 17.662 14.7656 18.6328C14.7656 19.6036 15.5526 20.3906 16.5234 20.3906C17.4942 20.3906 18.2812 19.6036 18.2812 18.6328Z"
                fill={c.gray.light}
            />
            <path
                d="M11.9531 4.57031C12.3415 4.57031 12.6562 4.25552 12.6562 3.86719C12.6562 3.47886 12.3415 3.16406 11.9531 3.16406H1.40625C1.01792 3.16406 0.703125 3.47886 0.703125 3.86719C0.703125 4.25552 1.01792 4.57031 1.40625 4.57031H11.9531Z"
                fill={c.gray.light}
            />
            <path
                d="M12.6562 18.6328C12.6562 19.0211 12.3415 19.3359 11.9531 19.3359H1.40625C1.01792 19.3359 0.703125 19.0211 0.703125 18.6328C0.703125 18.2445 1.01792 17.9297 1.40625 17.9297H11.9531C12.3415 17.9297 12.6562 18.2445 12.6562 18.6328Z"
                fill={c.gray.light}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.89091 11.9531C3.21064 13.3622 4.47075 14.4141 5.97656 14.4141C7.72404 14.4141 9.14062 12.9975 9.14062 11.25C9.14062 9.50252 7.72404 8.08594 5.97656 8.08594C4.47075 8.08594 3.21064 9.13781 2.89091 10.5469H1.40625C1.01792 10.5469 0.703125 10.8617 0.703125 11.25C0.703125 11.6383 1.01792 11.9531 1.40625 11.9531H2.89091ZM5.97656 13.0078C5.00576 13.0078 4.21875 12.2208 4.21875 11.25C4.21875 10.2792 5.00576 9.49219 5.97656 9.49219C6.94737 9.49219 7.73438 10.2792 7.73438 11.25C7.73438 12.2208 6.94737 13.0078 5.97656 13.0078Z"
                fill={c.gray.light}
            />
            <path
                d="M9.84375 11.25C9.84375 10.8617 10.1585 10.5469 10.5469 10.5469H21.0938C21.4821 10.5469 21.7969 10.8617 21.7969 11.25C21.7969 11.6383 21.4821 11.9531 21.0938 11.9531H10.5469C10.1585 11.9531 9.84375 11.6383 9.84375 11.25Z"
                fill={c.gray.light}
            />
        </svg>
    );
};

export default SetupsIcon;
