import React from 'react';
import { Typography } from '@mui/material';
import HeaderFooterWrapper from '../component/parts/HeaderFooterWrapper';

export default function FailurePage() {
    return (
        <HeaderFooterWrapper>
            <Typography variant="h4" component="h2" gutterBottom>
                Sorry, it seems we&apos;re having some technical difficulties :(
            </Typography>
            <Typography variant="body1" gutterBottom>
                Please try again in a few minutes, if the problem persists drop
                us a mail to help@quanted.io
            </Typography>
        </HeaderFooterWrapper>
    );
}
