import {AbstractFilter} from './AbstractFilter';

export abstract class AbstractStateHoldingFilter extends AbstractFilter {
    private readonly _className: string;

    constructor(className: string, label: string, tooltip: string, type: string) {
        super(label, tooltip, type);
        this._className = className;
    }

    className(): string {
        return this._className;
    }
}
