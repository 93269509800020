import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import CompanyTable from '../component/companies/CompanyTable';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';

export default function CompanyPage() {
    const [ticker, setTicker] = useState('');

    const handleChangeTicker = (event) => {
        setTicker(event.target.value);
    };

    return (
        <div>
            <Grid container alignContent={'center'} alignItems={'center'}>
                <Grid item xs={12} sx={{padding: 2}}>
                    <Paper>
                        <Toolbar>
                            <TextField style={{width: '50%'}} label="Ticker search" value={ticker} onChange={handleChangeTicker}/>
                        </Toolbar>
                    </Paper>
                </Grid>
                <Grid item xs={12} sx={{padding: 2}}>
                    <CompanyTable ticker={ticker} />
                </Grid>
            </Grid>
        </div>
    );
}
