import React, { memo, useState } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import TextField from '@mui/material/TextField';
import { Box, Typography } from '@mui/material';

import Label from '../inputs/Label';
import getBorderColor from '../../common/utils/getBorderColor';
import { colors as c } from '../../common/theme';

interface NumberFormatCustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = React.forwardRef<
    NumericFormatProps,
    NumberFormatCustomProps
>(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
        />
    );
});

export interface FromToInputProps {
    value: any;
    setValue: (v: any) => void;
    label: string;
    tooltip?: string;
    checkActiveCount: () => void;
    disableNulls?: boolean;
    deleteCallback?: () => void;
}

export default memo(function FromToInput({
    value,
    setValue,
    label,
    tooltip,
    checkActiveCount,
    disableNulls,
    deleteCallback,
}: FromToInputProps) {
    const [err, setErr] = useState(false);
    const [focused, setFocused] = useState(false);
    const borderColor = getBorderColor(focused, value, err);

    const handleChangeFrom = (event: any) => {
        validateAndSet(event.target.value, value[1] ? value[1] : '');
    };

    const handleChangeTo = (event: any) => {
        validateAndSet(value[0] ? value[0] : '', event.target.value);
    };

    function validateAndSet(from: any, to: any) {
        if (from && to && parseInt(from, 10) > parseInt(to, 10)) {
            setErr(true);
        } else {
            setErr(false);
        }
        setValue([from, to]);
    }

    const clearValuesAndRemoveFilter = () => {
        if (deleteCallback) {
            setValue([null, null]);
            deleteCallback();
        }
    };

    const disabled = disableNulls && !value[0] && !value[1];

    return (
        <Label
            label={label}
            tooltip={tooltip}
            deleteCallback={
                deleteCallback ? clearValuesAndRemoveFilter : undefined
            }
        >
            <Box
                display="flex"
                border="1px solid"
                sx={{
                    transition:
                        'border-color 0.3s ease-out, background-color 0.3s ease-out',
                }}
                borderColor={borderColor}
                bgcolor={!value[0] && !value[1] ? c.gray.darker : c.gray.dark}
                borderRadius={1}
                padding="12px"
                minWidth={300}
                gap={2}
            >
                <TextField
                    onMouseEnter={checkActiveCount}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    disabled={disabled}
                    error={err}
                    helperText={
                        err && (
                            <Typography fontSize="10px">
                                Value must be lower than to
                            </Typography>
                        )
                    }
                    size="small"
                    autoComplete="off"
                    variant="standard"
                    value={value[0] ? value[0] : ''}
                    onChange={handleChangeFrom}
                    InputProps={{
                        disableUnderline: true,
                        inputComponent: NumericFormatCustom as any,
                        placeholder: 'From',
                        slotProps: { input: { sx: { padding: 0 } } },
                    }}
                />
                <Typography color={c.gray.dim}>-</Typography>
                <TextField
                    onMouseEnter={checkActiveCount}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    disabled={disabled}
                    error={err}
                    helperText={
                        err && (
                            <Typography fontSize="10px">
                                Value must be larger than from
                            </Typography>
                        )
                    }
                    size="small"
                    autoComplete="off"
                    variant="standard"
                    value={value[1] ? value[1] : ''}
                    onChange={handleChangeTo}
                    InputProps={{
                        disableUnderline: true,
                        inputComponent: NumericFormatCustom as any,
                        placeholder: 'To',
                        slotProps: { input: { sx: { padding: 0 } } },
                    }}
                />
            </Box>
        </Label>
    );
});
