import React, { useState } from 'react';

import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';

import { sendBody } from '../common/utils/sendBody';
import { useQueryString } from '../common/utils/useQueryString';
import FilledButton from '../component/buttons/FilledButton';
import Label from '../component/inputs/Label';
import TextInput from '../component/inputs/TextInput';
import HeaderFooterWrapper from '../component/parts/HeaderFooterWrapper';
import { colors as c } from '../common/theme';

export default function LoginPage() {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [err, setErr] = useState<string | undefined>(undefined);

    const [redirect] = useQueryString('redir');

    const callback = () => {
        if (redirect) {
            window.location.href = redirect;
        } else {
            window.location.href = '/';
        }
    };

    const submit = (event: React.FormEvent) => {
        event.preventDefault();
        sendBody(
            {
                username: username,
                password: password,
            },
            '/login',
            'POST',
            callback,
            setErr
        );
    };

    return (
        <HeaderFooterWrapper>
            <form
                onSubmit={submit}
                style={{ maxWidth: '360px', width: '100%' }}
            >
                <Typography fontSize={36} fontWeight={600} lineHeight="44px">
                    Log in
                </Typography>
                <Typography color={c.lightBlue} margin="12px 0 32px">
                    Welcome back! Please enter your details.
                </Typography>
                {err ? (
                    <Alert
                        severity="error"
                        variant="outlined"
                        sx={{ marginBottom: '12px' }}
                    >
                        {err}
                    </Alert>
                ) : null}
                <Label label="Login" marginBottom="20px">
                    <TextInput
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Label>
                <Label label="Password">
                    <TextInput
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Label>
                <Typography
                    fontWeight={600}
                    fontSize={14}
                    textAlign="center"
                    marginY="24px"
                >
                    <Link href="/reset-link">Forgot password</Link>
                </Typography>
                <FilledButton type="submit" fullWidth>
                    Sign in
                </FilledButton>
                <Typography
                    textAlign="center"
                    fontSize={14}
                    color={c.gray.light}
                    marginY="32px"
                >
                    Don&apos;t have an account?{' '}
                    <Link href="/signup" fontWeight={600}>
                        Sign up
                    </Link>
                </Typography>
            </form>
        </HeaderFooterWrapper>
    );
}
