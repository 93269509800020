import React from 'react';

const FirstPage = () => {
    return (
        <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5 1L1 5L5 9"
                stroke="#545454"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect x="8" width="2" height="10" rx="1" fill="#545454" />
        </svg>
    );
};

export default FirstPage;
