import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import { Box, Stack, Typography } from '@mui/material';

import { sendBody } from '../common/utils/sendBody';
import FilledButton from '../component/buttons/FilledButton';
import Label from '../component/inputs/Label';
import TextInput from '../component/inputs/TextInput';
import { colors as c } from '../common/theme';

export default function ChangePasswordPage() {
    const [current, setCurrent] = useState<string>('');
    const [newPsw, setNewPsw] = useState<string>('');
    const [newPsw2, setNewPsw2] = useState<string>('');

    const [err, serErr] = useState(undefined);

    const submit = (event: React.FormEvent) => {
        event.preventDefault();
        sendBody(
            {
                currentPsw: current,
                newPsw: newPsw,
                newPsw2: newPsw2,
            },
            '/api/user/change-password',
            'PUT',
            () => (window.location.href = '/'),
            serErr
        );
    };

    return (
        <form onSubmit={submit}>
            <Typography
                fontSize="20px"
                fontWeight={600}
                padding="30px 54px 24px"
                lineHeight="20px"
            >
                Change password
            </Typography>
            <Box margin="0 16px 24px 54px" height="1px" bgcolor={c.divider} />
            <Stack
                padding="0 16px 16px 54px"
                alignItems="flex-start"
                spacing={3}
            >
                {err ? (
                    <Alert severity="error" variant="outlined">
                        {err}
                    </Alert>
                ) : null}
                <Label label="Current password" width="100%" maxWidth="311px">
                    <TextInput
                        type="password"
                        value={current}
                        onChange={(e) => setCurrent(e.target.value)}
                    />
                </Label>
                <Label label="New password" width="100%" maxWidth="311px">
                    <TextInput
                        type="password"
                        value={newPsw}
                        onChange={(e) => setNewPsw(e.target.value)}
                    />
                </Label>
                <Label label="Confirm password" width="100%" maxWidth="311px">
                    <TextInput
                        type="password"
                        value={newPsw2}
                        onChange={(e) => setNewPsw2(e.target.value)}
                    />
                </Label>
                <FilledButton type="submit">Change</FilledButton>
            </Stack>
        </form>
    );
}
