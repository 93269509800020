import React from 'react';
import { Link } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { colors as c } from '../../common/theme';

type ProfileSettingsListItemProps = {
    selected: boolean;
    icon: React.ReactNode;
    text: string;
    href: string;
};

const ProfileSettingsListItem: React.FC<ProfileSettingsListItemProps> = ({
    selected,
    icon,
    text,
    href,
}) => {
   
    return (
        <ListItemButton
            component={Link}
            to={href}
            selected={selected}
            sx={{
                borderRadius: 1,
            }}
        >
            <ListItemIcon
                sx={{ path: { fill: selected ? c.primary : c.gray.light } }}
            >
                {icon}
            </ListItemIcon>
            <ListItemText
                primary={text}
                sx={{ color: selected ? c.primary : c.gray.light, whiteSpace: 'nowrap'}}
            />
        </ListItemButton>
    );
};

export default ProfileSettingsListItem;
