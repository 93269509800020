import {getCookie} from './utils';

export function sendBody(body: any, path: string, method: string, callback?: () => void, setError?: (v: any) => void) {
    const cookie = getCookie('XSRF-TOKEN');
    const requestOptions: RequestInit = {
        method: method,
        headers: {
            ...(cookie ? {'X-XSRF-TOKEN': cookie} : {}),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    };
    fetch(document.location.origin + path, requestOptions)
        .then((response) => {
            if (!response.ok && response.status !== 302) { throw response; }
            if (callback) {
                callback();
            }
        }).catch((response) => {
            response.json().then((res: any) => {
                if (setError) {
                    setError(res.status);
                }
            }).catch(() => {
                if (setError) {
                    setError('Request failed');
                }
            });
    });
}

export function sendDelete(path: string, callback?: () => void, setError?: (v: any) => void) {
    const cookie = getCookie('XSRF-TOKEN');
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: {
            ...(cookie ? {'X-XSRF-TOKEN': cookie} : {})
        }
    };
    fetch(document.location.origin + path, requestOptions)
        .then((response) => {
            if (!response.ok && response.status !== 302) { throw response; }
            if (callback) {
                callback();
            }
        }).catch((response) => {
        response.json().then((res: any) => {
            if (setError) {
                setError(res.status);
            }
        }).catch(() => {
            if (setError) {
                setError('Request failed');
            }
        });
    });
}
