import {AbstractStateHoldingFilter} from './AbstractStateHoldingFilter';

export class StateHoldingFilter extends AbstractStateHoldingFilter {
    private _value: any;
    private readonly _setValue: (v: any) => void;
    constructor(label: string, tooltip: string, type: string, value: any) {
        super('num', label, tooltip, type);
        this._value = value;
        this._setValue = (v: any) => this._value = v
    }

    static of(label: string, tooltip: string, type: string): StateHoldingFilter {
        return new StateHoldingFilter(label, tooltip, type, [null, null]);
    }

    static from(label: string, tooltip: string, type: string, value: any): StateHoldingFilter {
        return new StateHoldingFilter(label, tooltip, type, value);
    }

    get value(): any {
        return this._value;
    }

    get setValue(): (v: any) => void {
        return this._setValue;
    }
}
