import {FilterArray} from '../../component/filter/FilterArray';
import {AbstractStateHoldingFilter} from '../../types/AbstractStateHoldingFilter';
import {StateHoldingFilter} from '../../types/StateHoldingFilter';
import {StateHoldingBooleanFilter} from '../../types/StateHoldingBooleanFilter';
import {StateHoldingAutocompleteFilter} from '../../types/StateHoldingAutocompleteFilter';
import {StateHoldingTimeFilter} from '../../types/StateHoldingTimeFilter';
import {formatQueryTime} from '../utils/utils';
import moment from 'moment-timezone';
import {StateHoldingSelectFilter} from '../../types/StateHoldingSelectFilter';

class Filter {
    public type: string;

    constructor(type: string) {
        this.type = type;
    }

}

export class NumberFilter extends Filter{
    public name: string;
    public value: any;

    constructor(name: string, value: any) {
        super('number');
        this.name = name;
        this.value = value;
    }
}

export class StringFilter extends Filter {
    public name: string;
    public stringValue: any;

    constructor(name: string, value: any) {
        super('string');
        this.name = name;
        this.stringValue = value;
    }
}

class BoolFilter extends Filter{
    public name: string;
    public booleanValue: any;

    constructor(name: string, value: any) {
        super('bool');
        this.name = name;
        this.booleanValue = value;
    }
}

class AutocompleteFilter extends Filter{
    public name: string;
    public listValue: any;

    constructor(name: string, value: any) {
        super('autocomplete');
        this.name = name;
        this.listValue = value;
    }
}

export class TimeFilter extends Filter{
    public name: string;
    public from: any;
    public to: any;

    constructor(name: string, from: any, to: any) {
        super('time');
        this.name = name;
        this.from = from;
        this.to = to;
    }
}

export class ArrayDTO {
    public name: string;
    public type: string;
    public filters: Filter[];

    constructor(name: string, type: string, filters: AbstractStateHoldingFilter[]) {
        this.name = name;
        this.type = type;
        this.filters = [];
        filters.forEach((e) => {
            if (e instanceof StateHoldingFilter) {
                this.filters.push(new NumberFilter(e.label, e.value))
            } else if (e instanceof StateHoldingTimeFilter) {
                this.filters.push(new TimeFilter(e.label, formatQueryTime(e.from), formatQueryTime(e.to)))
            } else if (e instanceof StateHoldingBooleanFilter) {
                this.filters.push(new BoolFilter(e.label, e.value))
            } else if (e instanceof StateHoldingAutocompleteFilter) {
                this.filters.push(new AutocompleteFilter(e.label, e.value))
            } else if (e instanceof StateHoldingSelectFilter) {
                this.filters.push(new StringFilter(e.label, e.value))
            }
        })
    }
}

export function bodyToDto(body: FilterArray[]): ArrayDTO[] {
    const result: ArrayDTO[] = [];
    if (body.length > 0) {
        body.forEach((e) => {
            result.push(new ArrayDTO(e.name, e.type, e.filters))
        })
    }
    return result;
}

interface FilterDesc {
    tooltip: string;
    type: string;
}

interface Group {
    [name: string] : FilterDesc
}

const map: Group = {
    'Performance %': {tooltip: 'Day performance percentage of the stock', type: 'tech'},
    'Performance Open - HOD %': {tooltip: 'Day change from Open to HOD', type: 'tech'},
    'Performance Open - LOD %': {tooltip: 'Day change from Open to LOD', type: 'tech'},
    'Performance HOD - Close %': {tooltip: 'Day change from HOD to Close', type: 'tech'},
    'Performance LOD - Close %': {tooltip: 'Day change from LOD to Close', type: 'tech'},
    'Performance LOD - HOD %': {tooltip: 'Day change from LOD to HOD', type: 'tech'},
    'Performance HOD - LOD %': {tooltip: 'Day change from HOD to LOD', type: 'tech'},
    'Performance PM High - HOD %': {tooltip: 'Change from Pre Market High to HOD', type: 'tech'},
    'PM Gap %': {tooltip: 'Difference between previous day close and current day pre-market open', type: 'tech'},
    'PM High Gap %': {tooltip: 'Difference between previous day close and current day pre-market high', type: 'tech'},
    'Price': {tooltip: 'select the price of the stock', type: 'desc'},
    'RVOL': {tooltip: 'select daily (9:30am-4:00pm) relative volume (ratio between current volume and 1-month average)', type: 'desc'},
    'RVOL (pre market)': {tooltip: 'select pre market (4:00am-9:30am) relative volume (ratio between current volume and 1-month average)', type: 'desc'},
    'Average volume': {tooltip: 'select daily (9:30am-4:00pm) average volume', type: 'desc'},
    'Average volume (pre market)': {tooltip: 'select pre market (4:00am-9:30am) average volume', type: 'desc'},
    'Gap %': {tooltip: 'Difference between previous day close and current day open', type: 'tech'},
    'Shares outstanding': {tooltip: 'select shares outstanding (represents the total number of shares issued by the company and held by its shareholders)', type: 'desc'},
    'Volume': {tooltip: 'select the number of shares traded in the security daily', type: 'desc'},
    'Market cap': {tooltip: 'select market cap (the total market value of all of a company’s outstanding shares)', type: 'desc'},
    'Volume (pre market)': {tooltip: 'select the number of shares traded in the security in pre market', type: 'desc'},
    'Volume in dollars $': {tooltip: 'The total value of the stock in dollars', type: 'desc'},
    'HOD time': {tooltip: 'select the timeframe of high of day', type: 'tech'},
    'LOD time': {tooltip: 'select the timeframe of low of day', type: 'tech'},
    'PM high time': {tooltip: 'select the timeframe of pre market high time', type: 'tech'},
    'PM low time': {tooltip: 'select the timeframe of pre market low time', type: 'tech'},
    'PM Fade %': {tooltip: 'Pre-market high to open price performance', type: 'tech'},
    'PM Break': {tooltip: 'Did HOD exceed PM High', type: 'tech'},
}

export function dtoToFilter(dto: ArrayDTO[]): FilterArray[] {
    const result: FilterArray[] = [];
    if (dto.length > 0) {
        dto.forEach((d) => {
            const filters: AbstractStateHoldingFilter[] = [];
            for (const filter of d.filters) {
                if (filter.type === 'number') {
                    const numFilter = filter as NumberFilter;
                    const element = map[numFilter.name];
                    filters.push(StateHoldingFilter.from(numFilter.name, element.tooltip, element.type, numFilter.value));
                } else if (filter.type === 'bool') {
                    const boolFilter = filter as BoolFilter;
                    const element = map[boolFilter.name];
                    filters.push(StateHoldingBooleanFilter.from(boolFilter.name, element.tooltip, element.type, boolFilter.booleanValue));
                }else if (filter.type === 'time') {
                    const timeFilter = filter as TimeFilter;
                    const element = map[timeFilter.name];
                    filters.push(StateHoldingTimeFilter.from(
                        timeFilter.name,
                        element.tooltip,
                        element.type,
                        timeFilter.from ? moment(timeFilter.from, 'HHmm') : timeFilter.from,
                        timeFilter.to ? moment(timeFilter.to, 'HHmm') : timeFilter.to))
                }
            }
            result.push(new FilterArray(d.name, d.type, filters))
        })
    }
    return result;
}
