import React, { ReactNode } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { colors as c } from '../../common/theme';

type HeaderFooterWrapperProps = {
    children: ReactNode;
};

const HeaderFooterWrapper = ({ children }: HeaderFooterWrapperProps) => {
    return (
        <Stack justifyContent="space-between" height="100vh">
            <Box
                padding={4}
                width={193}
                component="img"
                src="/logo200x62.png"
                alt="Quanted"
            />
            <Stack alignItems="center" paddingX={2}>
                {children}
            </Stack>

            <Typography fontSize={14} color={c.gray.light} padding={4}>
                © Quanted {new Date().getFullYear()}
            </Typography>
        </Stack>
    );
};

export default HeaderFooterWrapper;
