import React, {useCallback, useState} from 'react';
import {FormControl, Grid, IconButton, MenuItem, Select, Toolbar, Tooltip, Typography,SelectChangeEvent, styled} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

import {useFilterContext} from '../../common/context/FilterContext';
import {sendBody} from '../../common/utils/sendBody';
import {TradingViewChart} from '../../chart';
import Pagination from './Pagination';
import SortSelect from './SortSelect';
import {colors as c} from '../../common/theme';
import { makeTimeString } from '../../types/types';

enum StorageKey {
    chartState ='cState'
}

type ChartsPerPage = 3 | 4 | 6 | 12;

const StyledExpandMore = styled(ExpandMore)({
    fill: c.primary,
  });

export default function ChartView() {
    const [chartsPerRow, setChartsPerRow] = useState<number>(0);
    const {dataResponse, fetch, getQuery} = useFilterContext();
    const chartStateVal = localStorage.getItem(StorageKey.chartState);
    const chartStateObj: object = chartStateVal ? JSON.parse(chartStateVal) : null;
    const [chartState, setChartState] = useState<object>(chartStateObj);

    const handleChangeChartsPerRow = (event: SelectChangeEvent<number>) => {
        setChartsPerRow(event.target.value as number)
    };

    const pagination = (
        <Grid container justifyContent="space-between" paddingTop="13px" paddingBottom="8px">
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item>
                        <FormControl sx={{border: `1px solid ${c.gray.dim}`, borderRadius: 1, padding: '6px 0 6px 14px',':hover': { borderColor: c.gray.light }, }}>
                            <Select
                                IconComponent={StyledExpandMore}
                                value={chartsPerRow}
                                onChange={handleChangeChartsPerRow}
                                variant="standard"
                                disableUnderline
                                MenuProps={{
                                    slotProps: {
                                        root: {
                                            slotProps: { backdrop: { sx: { backgroundColor: 'transparent' } } },
                                        },
                                    },
                                }}
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    marginRight: '14px',
                                    padding: 0,
                                    width: 159,
                                }}
                            >
                                <MenuItem value={0} disabled>
                                    Charts per row
                                </MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <SortSelect />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item><Pagination pages={[12]}/></Grid>
        </Grid>
    );

    const exclude = function (ticker: string, date: string) {
        sendBody(
            {},
            '/api/filter/exclude'  + getQuery() + '&ticker=' + ticker + '&day=' + date,
            'DELETE',
            () => fetch()
        );
    };

    let xs : ChartsPerPage  = 12;
    let chartSize : 'small' | 'mid' | 'full' = 'full';
    if (chartsPerRow === 2 || chartsPerRow === 0) {
        xs = 6
        chartSize = 'mid';
    } else if (chartsPerRow === 4) {
        xs = 3;
        chartSize = 'small';
    } else if (chartsPerRow === 3) {
        xs = 4;
        chartSize = 'small';
    }

    const syncCharts = useCallback((state: object) => {
        localStorage.setItem(StorageKey.chartState, JSON.stringify(state))
        setChartState(state);
    }, [setChartState]);

    return (
        <>
            {pagination}
            <Grid container>
                { dataResponse.result && dataResponse.result.data
                    ? dataResponse.result.data
                        .map((row, i) => (
                            <Grid key={i} item xs={xs} sx={{borderTop:`1px solid ${c.divider}`, borderLeft:`1px solid ${c.divider}`, padding: '8px'}}>
                                <Toolbar sx={{paddingX: 2, paddingY: 0}} variant="dense">
                                    <Typography variant="h3" sx={{padding: 0, flex: '1 1 100%'}} >
                                        {row.ticker + ' - ' + row.date}
                                    </Typography>
                                    <Tooltip title="Exclude from statistics">
                                        <IconButton sx={{padding: 0}} size="small" onClick={() => exclude(row.ticker, row.date)}>
                                            <CloseIcon color="primary"/>
                                        </IconButton>
                                    </Tooltip>
                                </Toolbar>
                                <TradingViewChart theme="Dark" size={chartSize}
                                                  symbol={row.ticker} chartState={chartState} date={row.date} 
                                                  timeFrom={makeTimeString('04:00')}
                                                  timeTo={makeTimeString('20:00')} 
                                                  intradayRange={row.intradayRange}
                                                  syncCharts={syncCharts}
                                                   />
                            </Grid>
                        )) : null}
            </Grid>
            {pagination}
        </>
    );
}
