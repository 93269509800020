import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import { Checkbox, FormControlLabel } from '@mui/material';

import { sendBody } from '../common/utils/sendBody';
import FilledButton from '../component/buttons/FilledButton';
import Label from '../component/inputs/Label';
import TextInput from '../component/inputs/TextInput';
import HeaderFooterWrapper from '../component/parts/HeaderFooterWrapper';
import { colors as c } from '../common/theme';

export default function SignUpPage() {
    const termsUrl = 'https://www.quanted.io/terms-and-conditions';
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [err, serErr] = useState<string | undefined>(undefined);
    const [accept, setAccept] = useState<boolean>(false);
    const [marketingPermission, setMarketingPermission] =
        useState<boolean>(false);

    const submit = (event: React.FormEvent) => {
        event.preventDefault();
        sendBody(
            {
                email: username,
                password: password,
                marketingPermission,
            },
            '/api/user/signup',
            'POST',
            () => (window.location.href = '/'),
            serErr
        );
    };

    return (
        <HeaderFooterWrapper>
            <form
                onSubmit={submit}
                style={{ maxWidth: '360px', width: '100%' }}
            >
                <Typography fontSize={36} fontWeight={600} lineHeight="44px">
                    Sign Up
                </Typography>
                <Typography color={c.lightBlue} margin="12px 0 32px">
                    Welcome back! Please enter your details.
                </Typography>
                {err ? (
                    <Alert
                        severity="error"
                        variant="outlined"
                        sx={{ marginBottom: '12px' }}
                    >
                        {err}
                    </Alert>
                ) : null}
                <Label label="Login" marginBottom="35px">
                    <TextInput
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Label>
                <Label label="Password" marginBottom="35px">
                    <TextInput
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Label>
                <FormControlLabel
                    sx={{ alignItems: 'flex-start' }}
                    control={
                        <Checkbox
                            sx={{
                                paddingTop: 0,
                            }}
                            checked={marketingPermission}
                            onChange={() =>
                                setMarketingPermission(!marketingPermission)
                            }
                            name="marketingPermission"
                        />
                    }
                    label={
                        <Typography fontSize={14} fontWeight={300}>
                            Yes, I would like to receive marketing emails from
                            Quanted with news, offers, and updates
                        </Typography>
                    }
                />
                <FormControlLabel
                    sx={{
                        alignItems: 'flex-start',
                        marginTop: '8px',
                        marginBottom: '35px',
                    }}
                    control={
                        <Checkbox
                            sx={{
                                paddingTop: 0,
                            }}
                            checked={accept}
                            onChange={() => setAccept(!accept)}
                            name="checkedA"
                        />
                    }
                    label={
                        <Typography fontSize={14} fontWeight={300}>
                            I accept{' '}
                            <Link
                                target="_blank"
                                href={termsUrl}
                                sx={{
                                    textDecoration: 'underline',
                                    textUnderlineOffset: '2px',
                                }}
                            >
                                Terms and conditions
                            </Link>
                        </Typography>
                    }
                />
                <FilledButton type="submit" fullWidth disabled={!accept}>
                    Sign Up
                </FilledButton>
                <Typography
                    fontWeight={600}
                    fontSize={14}
                    textAlign="center"
                    marginY="35px"
                >
                    <Link href="/login">Back to login</Link>
                </Typography>
            </form>
        </HeaderFooterWrapper>
    );
}
