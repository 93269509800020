export const groupedDayZeroFilters = {
    all: [
      'Price',
      'Market cap',
      'Shares outstanding',
      'ATR',
      'ADR',
      'Tickers',
      'Sectors',
      'Industries',
      'Countries',
      'Performance %',
      'Performance Open - HOD %',
      'Performance Open - LOD %',
      'Performance HOD - Close %',
      'Performance LOD - Close %',
      'Performance LOD - HOD %',
      'Performance HOD - LOD %',
      'Performance PM High - HOD %',
      'Gap %',
      'PM Gap %',
      'PM High Gap %',
      'Change Previous Day %',
      'Change 5 Days %',
      'Change 10 Days %',
      'Change 20 Days %',
      'PM Break',
      'PM Fade %',
      'HOD time',
      'LOD time',
      'PM high time',
      'PM low time',
      'Volume',
      'Volume (pre market)',
      'RVOL',
      'RVOL (pre market)',
      'Volume in dollars $',
      'Volume Yesterday %',
      'Average volume',
      'Average volume (pre market)',
    ],
    descriptive: [
      'Price',
      'Market cap',
      'Shares outstanding',
      'ATR',
      'ADR',
      'Tickers',
      'Sectors',
      'Industries',
      'Countries',
    ],
    technical: [
      'Performance %',
      'Performance Open - HOD %',
      'Performance Open - LOD %',
      'Performance HOD - Close %',
      'Performance LOD - Close %',
      'Performance LOD - HOD %',
      'Performance HOD - LOD %',
      'Performance PM High - HOD %',
      'Gap %',
      'PM Gap %',
      'PM High Gap %',
      'Change Previous Day %',
      'Change 5 Days %',
      'Change 10 Days %',
      'Change 20 Days %',
      'PM Break',
      'PM Fade %',
      'HOD time',
      'LOD time',
      'PM high time',
      'PM low time',
    ],
    volume: [
      'Volume',
      'Volume (pre market)',
      'RVOL',
      'RVOL (pre market)',
      'Volume in dollars $',
      'Volume Yesterday %',
      'Average volume',
      'Average volume (pre market)',
    ],
  };
  
  export const groupedDayTFilters = {
    all: [
      'Performance %',
      'Performance Open - HOD %',
      'Performance Open - LOD %',
      'Performance HOD - Close %',
      'Performance LOD - Close %',
      'Performance LOD - HOD %',
      'Performance HOD - LOD %',
      'Performance PM High - HOD %',
      'Gap %',
      'PM Gap %',
      'PM High Gap %',
      'PM Break',
      'PM Fade %',
      'HOD time',
      'LOD time',
      'PM high time',
      'PM low time',
      'Volume',
      'Volume (pre market)',
      'RVOL',
      'RVOL (pre market)',
      'Volume in dollars $',
      'Average volume',
      'Average volume (pre market)',
    ],
    technical: [
      'Performance %',
      'Performance Open - HOD %',
      'Performance Open - LOD %',
      'Performance HOD - Close %',
      'Performance LOD - Close %',
      'Performance LOD - HOD %',
      'Performance HOD - LOD %',
      'Performance PM High - HOD %',
      'Gap %',
      'PM Gap %',
      'PM High Gap %',
      'PM Break',
      'PM Fade %',
      'HOD time',
      'LOD time',
      'PM high time',
      'PM low time',
    ],
    volume: [
      'Volume',
      'Volume (pre market)',
      'RVOL',
      'RVOL (pre market)',
      'Volume in dollars $',
      'Average volume',
      'Average volume (pre market)',
    ],
  };
  
  export const groupedRangeFilters = {
    all: ['Performance %', 'Volume', 'Volume in dollars $'],
    technical: ['Performance %'],
    volume: ['Volume', 'Volume in dollars $'],
  };

export const maxAmountOfDayZeroFilters = groupedDayZeroFilters.all.length;
export const maxAmountOfDayTFilters = groupedDayTFilters.all.length;
export const maxAmountOfRangeFilters = groupedRangeFilters.all.length;
