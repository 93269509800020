import React, { useState } from 'react';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';

import { sendBody } from '../common/utils/sendBody';
import HeaderFooterWrapper from '../component/parts/HeaderFooterWrapper';
import FilledButton from '../component/buttons/FilledButton';
import Label from '../component/inputs/Label';
import TextInput from '../component/inputs/TextInput';

export default function SendResetLinkPage() {
    const [username, setUsername] = useState<string>('');

    const submit = (event: React.FormEvent) => {
        event.preventDefault();
        sendBody(
            { email: username },
            '/api/user/send-reset-link',
            'POST',
            () => (window.location.href = '/')
        );
    };

    return (
        <HeaderFooterWrapper>
            <form
                onSubmit={submit}
                style={{ maxWidth: '360px', width: '100%' }}
            >
                <Label label="Email" marginBottom="35px">
                    <TextInput
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Label>
                <FilledButton type="submit" fullWidth>
                    Send reset link
                </FilledButton>
                <Typography
                    fontWeight={600}
                    fontSize={14}
                    textAlign="center"
                    marginY="35px"
                >
                    <Link href="/login">Back to login</Link>
                </Typography>
            </form>
        </HeaderFooterWrapper>
    );
}
