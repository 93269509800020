import React from 'react';
import { Typography } from '@mui/material';
import HeaderFooterWrapper from '../component/parts/HeaderFooterWrapper';

export default function ForbiddenPage() {
    return (
        <HeaderFooterWrapper>
            <Typography variant="h4" component="h2" gutterBottom>
                Invalid request.
            </Typography>
            <Typography>
                It seems that the page you requested is either not found or
                forbidden.
            </Typography>
        </HeaderFooterWrapper>
    );
}
