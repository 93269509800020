import {BaseFilter} from './BaseFilter';

export class AutocompleteFilter extends BaseFilter {
    private readonly _path: string;

    constructor(label: string, tooltip: string, type: string, value: any, setValue: (v: any) => void, path: string) {
        super(label, tooltip, type, value, setValue);
        this._path = path;
    }

    get path(): string {
        return this._path;
    }

    static of(label: string, tooltip: string, type: string, value: any, setValue: (v: any) => void, path: string): AutocompleteFilter {
        return new AutocompleteFilter(label, tooltip, type, value, setValue, path);
    }
}
