import {AbstractStateHoldingFilter} from './AbstractStateHoldingFilter';

export class StateHoldingSelectFilter extends AbstractStateHoldingFilter {
    private _value: any;
    private _values: string[];
    private readonly _setValue: (v: any) => void;
    constructor(label: string, tooltip: string, type: string, value: any, values: string[]) {
        super('select', label, tooltip, type);
        this._value = value;
        this._values = values;
        this._setValue = (v: any) => {
            this._value = v
        }
    }

    static of(label: string, tooltip: string, type: string, values: string[]): StateHoldingSelectFilter {
        return new StateHoldingSelectFilter(label, tooltip, type, values.length > 0 ? values[0] : undefined, values);
    }

    static from(label: string, tooltip: string, type: string, value: any, values: string[]): StateHoldingSelectFilter {
        return new StateHoldingSelectFilter(label, tooltip, type, value, values);
    }

    get value(): any {
        return this._value;
    }

    get setValue(): (v: string) => void {
        return this._setValue;
    }

    get values(): string[] {
        return this._values;
    }
}
