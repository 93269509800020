import React from 'react';
import { Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
    Box,
    Divider,
    Drawer,
    List,
    ListItemButton,
    ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { sendBody } from '../../common/utils/sendBody';
import { useAppContext } from '../../common/context/AppContext';
import { StaticUrls } from '../../staticUrls';
import { hasAdminRole } from '../../common/utils/utils';
import styles from './Navigation.module.css';
import Person from '../icons/Person';
import LayoutSwich from './LayoutSwich';
import {colors as c} from '../../common/theme';

export default function Navigation() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const { global: { username, roles, subscription } = {}, } = useAppContext();
 
    const [menuOpen, setMenuOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setMenuOpen(true);
    };

    const handleDrawerClose = () => {
        setMenuOpen(false);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        sendBody({}, '/logout', 'POST', () => (window.location.href = '/'));
    };

    return (
        <>
            <AppBar position="static" sx={{ boxShadow: 'none' }}>
                <Toolbar className={styles.toolbar}>
                    <div className={styles.navigation}>
                        <Button
                            component={Link}
                            to="/"
                            size="large"
                            color="inherit"
                            className={styles.link}
                            onClick={() => (window.document.title = 'Quanted')}
                        >
                            <Box
                                width={100}
                                component="img"
                                src="/logo200x62.png"
                                alt="Quanted"
                            />
                        </Button>
                        <div className={styles.desktopMenu}>
                            <Button
                                target="_blank"
                                href={StaticUrls.USER_MANUAL}
                                size="large"
                                color="inherit"
                                className={styles.link}
                            >
                                User manual
                            </Button>
                            {subscription === 'FREE' ? (
                                <Button
                                    component={Link}
                                    to={StaticUrls.SUBSCRIPTION}
                                    size="large"
                                    color="inherit"
                                    className={styles.link}
                                >
                                    Upgrade
                                </Button>
                            ) : null}
                            {hasAdminRole(roles) ? (
                                <Button
                                    component={Link}
                                    to={StaticUrls.ADMIN}
                                    size="large"
                                    color="inherit"
                                    className={styles.link}
                                >
                                    Admin panel
                                </Button>
                            ) : null}
                            {/*<Button*/}
                            {/*    component={Link}*/}
                            {/*    to={'/'}*/}
                            {/*    size="large"*/}
                            {/*    color="inherit"*/}
                            {/*    className={styles.link}*/}
                            {/*>Daily Filter*/}
                            {/*</Button>*/}
                            {/*<Button*/}
                            {/*    component={Link}*/}
                            {/*    to={'/company'}*/}
                            {/*    size="large"*/}
                            {/*    color="inherit"*/}
                            {/*    className={styles.link}*/}
                            {/*>Companies*/}
                            {/*</Button>*/}
                        </div>
                    </div>
                    <div className={styles.container}>
                        {username ? (
                            <>
                                {window.location.pathname === StaticUrls.FILTER ? <LayoutSwich /> : null}
                                <Typography fontSize={14} marginRight="16px">
                                    {username}
                                </Typography>
                                <IconButton
                                    sx={{
                                        backgroundColor: c.gray.dim,
                                        borderWidth: '1px',
                                        borderColor: c.gray.dim,
                                        borderStyle: 'solid',
                                        width: '40px',
                                        height: '40px',
                                        ':hover': {
                                            borderColor: c.primary,
                                            backgroundColor: c.gray.dim,
                                            path: { stroke: 'white' },
                                        },
                                    }}
                                    disableRipple
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <Person />
                                </IconButton>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    hideBackdrop
                                >
                                    <MenuItem
                                        component="a"
                                        href="/account"
                                        sx={{
                                            ':hover': { color: c.primary },
                                            fontSize: '14px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Account
                                    </MenuItem>
                                    <MenuItem
                                        component="a"
                                        href="/account/setups"
                                        sx={{
                                            ':hover': { color: c.primary },
                                            fontSize: '14px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        My setups
                                    </MenuItem>
                                    <MenuItem
                                        onClick={logout}
                                        sx={{
                                            ':hover': { color: c.primary },
                                            fontSize: '14px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </>
                        ) : null}
                    </div>
                    <div className={styles.mobileMenu}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            size="large"
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer anchor="top" open={menuOpen} onClose={handleDrawerClose} hideBackdrop>
                <List>
                    {hasAdminRole(roles) ? (
                        <ListItemButton component="a" href={StaticUrls.ADMIN}>
                            <ListItemText
                                primary="Admin panel"
                                sx={{ ':hover': { color: c.primary } }}
                            />
                        </ListItemButton>
                    ) : null}
                    <Divider />
                    <ListItemButton component="a" href="/account">
                        <ListItemText
                            primary="Account"
                            sx={{ ':hover': { color: c.primary } }}
                        />
                    </ListItemButton>
                    <ListItemButton component="a" href="/account/setups">
                        <ListItemText
                            primary="My setups"
                            sx={{ ':hover': { color: c.primary } }}
                        />
                    </ListItemButton>
                    <ListItemButton onClick={logout}>
                        <ListItemText
                            primary="Logout"
                            sx={{ ':hover': { color: c.primary } }}
                        />
                    </ListItemButton>
                </List>
            </Drawer>
        </>
    );
}
