import React, { useState } from 'react';

import { Box, Tooltip, Typography } from '@mui/material';

import DeleteButton from '../buttons/DeleteButton';
import { colors as c } from '../../common/theme';

type LabelProps = {
    children: React.ReactNode;
    label: string;
    marginBottom?: string;
    width?: string;
    maxWidth?: string;
    tooltip?: string;
    deleteCallback?: () => void;
};

const Label = ({
    children,
    label,
    marginBottom,
    width,
    tooltip,
    deleteCallback,
}: LabelProps) => {
    const [showDeleteBtn, setShowDeleteBtn] = useState(false);

    return (
        <Box
            {...{ marginBottom, width }}
            onMouseOver={() => setShowDeleteBtn(true)}
            onMouseOut={() => setShowDeleteBtn(false)}
        >
            <Box
                display="flex"
                alignItems="center"
                marginBottom="8px"
                gap="4px"
            >
                <Tooltip title={tooltip} placement="top-start">
                    <Typography fontSize={14} lineHeight="14px" color={c.lightBlue}>
                        {label}
                    </Typography>
                </Tooltip>
                {deleteCallback && (
                    <DeleteButton
                        show={showDeleteBtn}
                        deleteCallback={deleteCallback}
                    />
                )}
            </Box>
            {children}
        </Box>
    );
};

export default Label;
