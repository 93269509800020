import React, { memo } from 'react';
import moment from 'moment';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Box, Typography } from '@mui/material';

import CustomDateTimePicker from './CustomDateTimePicker';
import { useAppContext } from '../../common/context/AppContext';
import Label from '../inputs/Label';
import { colors as c } from '../../common/theme';

interface Props {
    dateTo: any;
    setDateTo: (v: any) => void;
    dateFrom: any;
    setDateFrom: (v: any) => void;
    label: string;
    tooltip?: string;
}

export default memo(function FromToDate({
    dateTo,
    setDateTo,
    dateFrom,
    setDateFrom,
    label,
    tooltip,
}: Props) {
    const { global } = useAppContext();
    const handleDateToChange = (date: any) => {
        setDateTo(date);
    };

    const handleDateFromChange = (date: any) => {
        setDateFrom(date);
    };

    return (
        <Label label={label} tooltip={tooltip}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Box
                    display="flex"
                    border="1px solid"
                    borderColor={`${c.primary}66`}
                    bgcolor={c.gray.dark}
                    borderRadius={1}
                    padding="12px"
                    minWidth={300}
                    gap={2}
                >
                    <CustomDateTimePicker
                        date={dateFrom}
                        handleChange={handleDateFromChange}
                        minDate={
                            global?.subscription === 'FREE'
                                ? moment().subtract(1, 'months')
                                : null
                        }
                        maxDate={dateTo}
                        label="From"
                    />
                    <Typography color={c.gray.dim}>-</Typography>
                    <CustomDateTimePicker
                        date={dateTo}
                        handleChange={handleDateToChange}
                        minDate={dateFrom}
                        label="To"
                    />
                </Box>
            </LocalizationProvider>
        </Label>
    );
});
