import React from 'react';
import moment from 'moment';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

interface Props {
    date: any;
    minDate?: any;
    maxDate?: any;
    handleChange: (v: any) => void;
    label: string;
}

interface Props {
    date: any;
    minDate?: any;
    maxDate?: any;
    handleChange: (v: any) => void;
    label: string;
}
export default function CustomDateTimePicker({
    date,
    minDate,
    maxDate,
    handleChange,
    label,
}: Props) {
    return (
        <MobileDatePicker
            minDate={minDate ? minDate : moment('2000-01-01')}
            maxDate={maxDate ? maxDate : moment()}
            disableFuture
            slotProps={{
                textField: {
                    placeholder: label,
                    variant: 'standard',
                    InputProps: {
                        disableUnderline: true,
                        slotProps: { input: { sx: { padding: 0 } } },
                    },
                },
            }}
            format="MM/DD/YYYY"
            value={date}
            onChange={handleChange}
        />
    );
}
