import React from 'react';
import {Box, Paper} from '@mui/material';
import SearchSummary from '../component/filter/SearchSummary';

export default function AdminPage() {
    return (
        <Box sx={{padding: 1}}>
            <Paper>
                <SearchSummary type="DAILY"/>
            </Paper>
        </Box>
    );
}
