import React, {useState} from 'react';
import FilterAutocomplete, {FilterAutocompleteProps} from './FilterAutocomplete';

export default function WrappedFilterAutocomplete({
    label,
    path,
    value,
    setValue,
    tooltip,
    checkActiveCount,
    disableNulls,
    deleteCallback,
}: FilterAutocompleteProps) {
    const [list, setList] = useState(value);
    const handleChange = (v: any) => {
        setValue(v);
        setList(v);
    };
    return <FilterAutocomplete
        label={label}
        path={path}
        value={list}
        setValue={handleChange}
        checkActiveCount={checkActiveCount}
        tooltip={tooltip}
        disableNulls={disableNulls}
        deleteCallback={deleteCallback}
    />;
}
