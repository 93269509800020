import React from 'react';
import {
    FormControl,
    Grid,
    MenuItem,
    Select,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { noOp } from '../../common/utils/utils';
import SubscriptionsIcon from '../icons/SubscriptionsIcon';
import SetupsIcon from '../icons/SetupsIcon';
import PasswordIcon from '../icons/PasswordIcon';
import ProfileSettingsListItem from './ProfileSettingsListItem';
import { Page } from '../../constants/enums';
import { colors as c } from '../../common/theme';

const ProfileSettingsSidebar = ({ page }: { page: Page }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ backgroundColor: c.bg.light }}
            borderRight={isSmallScreen ? 'none' : `${c.divider} 1px solid`}
        >
            <FormControl
                fullWidth
                sx={{ display: isSmallScreen ? 'flex' : 'none' }}
            >
                <Select
                    value={page}
                    onChange={noOp()}
                    MenuProps={{
                        sx: {
                            '& .MuiMenuItem-root.Mui-selected': {
                                backgroundColor: 'transparent',
                            },
                        },
                    }}
                >
                    <MenuItem value={Page.SUBSCRIPTION}>
                        <ProfileSettingsListItem
                            selected={page === Page.SUBSCRIPTION}
                            icon={<SubscriptionsIcon />}
                            text="Subscription"
                            href="/account/subscription"
                        />
                    </MenuItem>
                    <MenuItem value={Page.SETUPS}>
                        <ProfileSettingsListItem
                            selected={page === Page.SETUPS}
                            icon={<SetupsIcon />}
                            text="My setups"
                            href="/account/setups"
                        />
                    </MenuItem>
                    <MenuItem value={Page.PASSWORD}>
                        <ProfileSettingsListItem
                            selected={page === Page.PASSWORD}
                            icon={<PasswordIcon />}
                            text="Change password"
                            href="/account/change-password"
                        />
                    </MenuItem>
                </Select>
            </FormControl>
            <Stack
                sx={{
                    padding: '30px 24px',
                    display: isSmallScreen ? 'none' : 'flex',
                }}
                spacing={4}
            >
                <Typography
                    fontWeight={600}
                    fontSize="20px"
                    marginBottom="42px"
                    lineHeight="20px"
                >
                    Profile settings
                </Typography>
                <ProfileSettingsListItem
                    selected={page === Page.SUBSCRIPTION}
                    icon={<SubscriptionsIcon />}
                    text="Subscription"
                    href="/account/subscription"
                />
                <ProfileSettingsListItem
                    selected={page === Page.SETUPS}
                    icon={<SetupsIcon />}
                    text="My setups"
                    href="/account/setups"
                />
                <ProfileSettingsListItem
                    selected={page === Page.PASSWORD}
                    icon={<PasswordIcon />}
                    text="Change password"
                    href="/account/change-password"
                />
            </Stack>
        </Grid>
    );
};

export default ProfileSettingsSidebar;
