/* eslint-disable react/prop-types */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import Collapse from '@mui/material/Collapse';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import {diff, formatDate, numberWithCommas} from '../../common/utils/utils';
import Link from '@mui/material/Link';

export default function InsiderTransactionsRow({isOpen, colSpan, owner}) {

    return (
        <TableRow>
            <TableCell colSpan={colSpan} sx={{padding: 0}}>
                <Collapse in={isOpen}>
                    <Table style={{backgroundColor: '#f5f5f5'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Type</TableCell>
                                <TableCell align="left">Difference</TableCell>
                                <TableCell align="left">Owned after</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {owner.documents.map((doc, i) => (
                                <TableRow key={i}>
                                    <TableCell align="left">{formatDate(doc.date)}</TableCell>
                                    <TableCell align="left"><Link href={doc.url} target="_blank">{doc.type}</Link></TableCell>
                                    <TableCell align="left">{diff(doc.nonDerivativeDiff)}</TableCell>
                                    <TableCell align="left">{numberWithCommas(doc.ownedAfter)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Collapse>
            </TableCell>
        </TableRow>
    );
}
