import React from 'react';
import { colors as c } from '../../common/theme';
const PasswordIcon = () => {
    return (
        <svg
            width="22"
            height="23"
            viewBox="0 0 22 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.71921 20.146C7.64982 19.7915 5.75591 18.7091 4.38636 17.0982C3.00164 15.4695 2.23906 13.3926 2.23906 11.25C2.23906 9.10749 3.00164 7.03055 4.38636 5.40185C5.43006 4.17423 6.77843 3.25406 8.27679 2.72856L8.0434 3.14354C7.86498 3.46074 7.97704 3.86271 8.29371 4.04143C8.60454 4.21844 9.01072 4.11082 9.19011 3.79068L10.1793 2.03208C10.3573 1.73272 10.249 1.3148 9.94816 1.13999L8.21865 0.094878C7.90739 -0.0932079 7.50291 0.00703135 7.31518 0.318779C7.12745 0.630527 7.22751 1.03566 7.53873 1.2237L7.9233 1.45608C6.17348 2.05343 4.59857 3.1188 3.38423 4.54711C1.79701 6.41408 0.922852 8.7946 0.922852 11.25C0.922852 13.7055 1.79701 16.086 3.38427 17.953C4.95343 19.7986 7.12441 21.039 9.49733 21.4454C9.84727 21.5081 10.1973 21.2676 10.257 20.9069C10.3182 20.548 10.0775 20.2073 9.71921 20.146Z"
                fill={c.gray.light}
            />
            <path
                d="M19.1156 4.54711C17.5465 2.70145 15.3755 1.46114 13.0026 1.05469C12.6442 0.993385 12.3042 1.23447 12.2429 1.59328C12.1817 1.95214 12.4224 2.29276 12.7806 2.35415C14.85 2.70866 16.7439 3.79103 18.1135 5.40189C19.4982 7.03059 20.2608 9.10754 20.2608 11.2501C20.2608 13.3926 19.4982 15.4696 18.1135 17.0983C17.0698 18.3259 15.7214 19.2461 14.2231 19.7716L14.4564 19.3566C14.6349 19.0394 14.5228 18.6374 14.2061 18.4587C13.8895 18.2801 13.4882 18.3923 13.3097 18.7095L12.3206 20.4681C12.1426 20.7674 12.2506 21.1853 12.5517 21.3601L14.2812 22.4053C14.5874 22.5917 14.9958 22.4958 15.1847 22.1814C15.3724 21.8696 15.2724 21.4645 14.9612 21.2764L14.5766 21.044C16.3264 20.4467 17.9013 19.3813 19.1157 17.953C20.7029 16.086 21.577 13.7055 21.577 11.25C21.577 8.79456 20.7029 6.41408 19.1156 4.54711Z"
                fill={c.gray.light}
            />
            <path
                d="M11.2496 4.83405C9.02393 4.83405 7.2132 6.64771 7.2132 8.87701V9.44162C6.60794 9.73505 6.18945 10.3564 6.18945 11.0739V15.2386C6.18945 16.2379 7.00112 17.0508 7.99873 17.0508H14.5004C15.4981 17.0508 16.3097 16.2379 16.3097 15.2386V11.0739C16.3097 10.3564 15.8913 9.73505 15.286 9.44162V8.87701C15.286 6.64771 13.4753 4.83405 11.2496 4.83405ZM11.2496 6.1524C12.7496 6.1524 13.9698 7.37466 13.9698 8.87701V9.26171H8.52945V8.87701C8.52945 7.37466 9.74968 6.1524 11.2496 6.1524ZM14.9935 15.2386C14.9935 15.5109 14.7724 15.7324 14.5005 15.7324H7.99877C7.72688 15.7324 7.5057 15.5109 7.5057 15.2386V11.0739C7.5057 10.8016 7.72688 10.58 7.99877 10.58H14.5005C14.7724 10.58 14.9935 10.8016 14.9935 11.0739V15.2386Z"
                fill={c.gray.light}
            />
            <path
                d="M11.2499 12.0411C10.8865 12.0411 10.5918 12.3362 10.5918 12.7003V13.6123C10.5918 13.9763 10.8865 14.2715 11.2499 14.2715C11.6134 14.2715 11.908 13.9763 11.908 13.6123V12.7003C11.908 12.3362 11.6134 12.0411 11.2499 12.0411Z"
                fill={c.gray.light}
            />
        </svg>
    );
};

export default PasswordIcon;
