import React, {useState} from 'react';
import TableContainer from '@mui/material/TableContainer';
import {Paper} from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import LinearProgress from '@mui/material/LinearProgress';
import ApiError from '../parts/ApiError';
import TablePagination from '@mui/material/TablePagination';
import apiService from '../../common/utils/apiService';

const classes = {
    row: {
        '& td, th': {
            paddingTop: 0,
            paddingBottom: 0,
        }
    }
};

interface Props {
    ticker: string;
}

interface Company {
    ticker: string;
    name: string;
    sector: string;
    industry: string;
    country: string;
}

export default function CompanyTable({ticker} : Props) {
    const [page, setPage] = useState(0);

    const response = apiService<Company[]>(
        'company/companies'
        + '?start=' + (20 * page + 1)
        + (ticker ? '&ticker=' + ticker : ''), 0
    );

    if (response.error) {
        return <ApiError error={response.error}/>;
    }

    if (response.isLoading) {
        return <LinearProgress />;
    }

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Ticker</TableCell>
                        <TableCell align="right">Name</TableCell>
                        <TableCell align="right">Sector</TableCell>
                        <TableCell align="right">Industry</TableCell>
                        <TableCell align="right">Cuntry</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {response.result.map((row, i) => (
                        <TableRow key={i} sx={classes.row}>
                            <TableCell component="th" scope="row">
                                <a href={'company/' + row.ticker}>{row.ticker}</a>
                            </TableCell>
                            <TableCell align="right">{row.name}</TableCell>
                            <TableCell align="right">{row.sector}</TableCell>
                            <TableCell align="right">{row.industry}</TableCell>
                            <TableCell align="right">{row.country}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[20]}
                component="div"
                rowsPerPage={20}
                count={7672}
                page={page}
                onPageChange={handleChangePage}
            />
        </TableContainer>
    );
}
