import Grid from '@mui/material/Grid';
import React from 'react';

interface Props {
    children: any;
    wide?: boolean;
}

export default function CenteredDiv({children, wide} : Props) {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={wide ? 12 : 9} sm={wide ? 12 : 6} md={wide ? 12 : 4} lg={wide ? 12 : false} xl={wide ? 12 : false}>
                {children}
            </Grid>
        </Grid>
    );
}
