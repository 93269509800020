import React, { ChangeEvent } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { Box, IconButton, styled } from '@mui/material';
import {
    ExpandMore,
    KeyboardArrowLeft,
    KeyboardArrowRight,
} from '@mui/icons-material';

import { useFilterContext } from '../../common/context/FilterContext';
import FirstPage from '../icons/FirstPage';
import { colors as c } from '../../common/theme';

interface PaginationProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: any, newPage: number) => void;
}

const StyledIconButton = styled(IconButton)({
    border: `1px solid ${c.gray.dim}`,
    padding: '12px',
    borderRadius: '4px',
    width: '44px',
    height: '44px',
    '&:hover': {
      borderColor: c.gray.light,
      backgroundColor: 'transparent',
      stroke: c.primary,
      fill: c.primary,
    },
    '&:hover path': { stroke: c.primary },
  });

const StyledExpandMore = styled(ExpandMore)({
    fill: c.primary,
  });

function TablePaginationActions(props: PaginationProps) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event: any) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: any) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: any) => {
        onPageChange(event, page + 1);
    };

    return (
        <Box display="flex" gap={1} marginLeft="14px">
            <StyledIconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                color="primary"
            >
                <FirstPage />
            </StyledIconButton>
            <StyledIconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                color="primary"
            >
                <KeyboardArrowLeft />
            </StyledIconButton>
            <StyledIconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                color="primary"
            >
                <KeyboardArrowRight />
            </StyledIconButton>
        </Box>
    );
}

interface Props {
    pages: number[];
}

export default function Pagination({ pages }: Props) {
    const { response, pageAndRows } = useFilterContext();

    const handleChangePage = (event: any, newPage: number) => {
        pageAndRows.set(newPage, pageAndRows.rowsPerPage, true);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        pageAndRows.set(0, parseInt(event.target.value, 10), true);
    };
    return (
        <TablePagination
            sx={{ borderBottom: 'none' }}
            rowsPerPageOptions={pages}
            count={response.result ? response.result.resultCount : 0}
            rowsPerPage={pageAndRows.rowsPerPage}
            page={pageAndRows.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            slotProps={{
                select: { IconComponent: StyledExpandMore },
            }}
        />
    );
}
