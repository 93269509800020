import React from 'react'
import {colors as c} from '../../common/theme'

const BarchartIcon = () => {

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.38849 13.1822H1.99219C1.66855 13.1822 1.40625 13.4445 1.40625 13.7682V19.4141C1.40625 19.7377 1.66855 20 1.99219 20H5.38864C5.71213 20 5.97458 19.7377 5.97458 19.4141V13.7682C5.97442 13.4445 5.71213 13.1822 5.38849 13.1822Z" fill={c.primary}/>
        <path d="M11.6914 10.0264H8.29492C7.97128 10.0264 7.70898 10.2887 7.70898 10.6123V19.4141C7.70898 19.7377 7.97128 20 8.29492 20H11.6914C12.0149 20 12.2773 19.7377 12.2773 19.4141V10.6123C12.2773 10.2887 12.0149 10.0264 11.6914 10.0264Z" fill={c.primary}/>
        <path d="M17.994 5.26016H14.5977C14.274 5.26016 14.0117 5.52246 14.0117 5.8461V19.4141C14.0117 19.7377 14.274 20 14.5977 20H17.994C18.3176 20 18.5799 19.7377 18.5799 19.4141V5.8461C18.5799 5.52246 18.3176 5.26016 17.994 5.26016Z" fill={c.primary}/>
        <path d="M18.4681 0.735009C18.3847 0.584253 18.2391 0.477899 18.07 0.444177L15.8976 0.0114378C15.5804 -0.0518862 15.2717 0.154107 15.2085 0.47149C15.1452 0.788873 15.3512 1.09741 15.6686 1.16073L16.2741 1.28127L11.1181 4.15847L8.6906 2.89993C8.52046 2.81173 8.31783 2.81234 8.1483 2.9016L1.75762 6.26189C1.47121 6.41265 1.36119 6.76681 1.5118 7.05321C1.61662 7.25265 1.82018 7.36663 2.0309 7.36663C2.12276 7.36663 2.21614 7.34496 2.30312 7.29919L8.42296 4.08111L10.8587 5.34392C11.0336 5.43456 11.242 5.43136 11.414 5.33553L16.8406 2.30712L16.6319 2.85796C16.5171 3.16055 16.6694 3.49883 16.972 3.61358C17.0404 3.63952 17.1105 3.65173 17.1797 3.65173C17.416 3.65173 17.6388 3.50753 17.7276 3.27331L18.5035 1.22665C18.5646 1.06551 18.5516 0.885614 18.4681 0.735009Z" fill={c.primary}/>
        <path d="M11.6915 20C12.015 20 12.2774 19.7377 12.2774 19.4141V10.6123C12.2774 10.2887 12.015 10.0264 11.6915 10.0264H9.99316V20H11.6915Z" fill={c.primary}/>
        <path d="M18.4684 0.735009C18.3849 0.584253 18.2393 0.477899 18.0702 0.444177L15.8979 0.0114378C15.5806 -0.0518862 15.2719 0.154107 15.2088 0.47149C15.1454 0.788873 15.3514 1.09741 15.6688 1.16073L16.2743 1.28127L11.1183 4.15847L9.99316 3.57513V4.89516L10.8589 5.34392C11.0337 5.43456 11.2422 5.43136 11.4141 5.33553L16.8408 2.30712L16.6321 2.85796C16.5174 3.16055 16.6696 3.49883 16.9722 3.61358C17.0406 3.63952 17.1108 3.65173 17.1797 3.65173C17.4163 3.65173 17.639 3.50753 17.7278 3.27331L18.5036 1.22665C18.5648 1.06551 18.5518 0.885614 18.4684 0.735009Z" fill={c.primary}/>
    </svg>
  )
}

export default BarchartIcon
