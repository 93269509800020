// https://calebmer.com/tangents/opaque-types-in-typescript.html

/** A string in 'YYYY-MM-DD' format. */
export type DateString = string & {_opaque: typeof DateStringSymbol};
declare const DateStringSymbol: unique symbol;

export function makeDateString(s: string): DateString {
  if (/^\d{4}-\d{2}-\d{2}$/.test(s)) {
    return s as DateString;
  }
  throw new Error(`invalid date: ${s}`);
}

/** A string in 'HH:mm' format. */
export type TimeString = string & {_opaque: typeof TimeStringSymbol};
declare const TimeStringSymbol: unique symbol;

export function makeTimeString(s: string): TimeString {
  if (/^([01]\d|2[0-3]):([0-5]\d)$/.test(s)) {
    return s as TimeString;
  }
  throw new Error(`invalid time: ${s}`);
}
