import {Error} from './Error';
import {FilterResultSummary} from './FilterResultSummary';
import {noOp} from '../common/utils/utils';

export class ApiResponse<T>{
    private readonly _result : T;
    private readonly _isLoading : boolean;
    private readonly _error : Error | null;
    private readonly _fetchData : (path: string) => void;
    private readonly _invalidateResult : () => void;

    get result(): T {
        return this._result;
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    get error(): Error | null {
        return this._error;
    }

    get fetchData(): (path: string) => void {
        return this._fetchData;
    }

    get invalidateResult(): () => void {
        return this._invalidateResult;
    }

    constructor(result: T, isLoading: boolean, error: Error | null, fetchData: (path: string) => void, invalidateResult: () => void) {
        this._result = result;
        this._isLoading = isLoading;
        this._error = error;
        this._fetchData = fetchData;
        this._invalidateResult = invalidateResult;
    }

    static empty(): ApiResponse<FilterResultSummary> {
        return new ApiResponse(FilterResultSummary.empty(), false, null, noOp(), noOp());
    }
}
