import React from 'react';
import { colors as c } from '../../common/theme';
const SubscriptionsIcon = () => {
    return (
        <svg
            width="24"
            height="21"
            viewBox="0 0 24 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.2928 13.75C11.4557 13.7793 11.6236 13.7432 11.76 13.6496C11.8965 13.5559 11.9905 13.4122 12.0217 13.2496C12.0629 13.0372 12.0841 12.8214 12.085 12.6051C12.085 11.1262 11.1574 9.84238 9.80918 9.21777C10.1045 8.95909 10.3412 8.64035 10.5035 8.28285C10.6658 7.92535 10.7499 7.53733 10.7502 7.14472C10.7502 5.61191 9.48867 4.36504 7.9377 4.36504C6.38672 4.36504 5.1252 5.61191 5.1252 7.14472C5.12557 7.5409 5.21122 7.93235 5.37632 8.29248C5.54142 8.65261 5.78211 8.97299 6.08203 9.23183C4.74785 9.86054 3.83203 11.1367 3.83203 12.6062C3.8333 12.8216 3.85428 13.0363 3.89473 13.2478C3.92137 13.3913 3.99733 13.521 4.10946 13.6144C4.2216 13.7078 4.36285 13.7591 4.50879 13.7594C4.54693 13.7596 4.58501 13.7561 4.62246 13.7488C4.70322 13.734 4.78026 13.7033 4.84918 13.6587C4.9181 13.614 4.97754 13.5562 5.02412 13.4886C5.0707 13.4209 5.10349 13.3448 5.12063 13.2645C5.13778 13.1842 5.13893 13.1013 5.12402 13.0205C5.09745 12.884 5.08333 12.7453 5.08184 12.6062C5.08184 11.2047 6.3709 10.065 7.95937 10.065C9.54785 10.065 10.8363 11.2047 10.8363 12.6062C10.835 12.7451 10.8209 12.8836 10.7941 13.0199C10.7789 13.1006 10.7797 13.1835 10.7965 13.2639C10.8133 13.3443 10.8458 13.4205 10.8921 13.4884C10.9384 13.5562 10.9976 13.6142 11.0664 13.6591C11.1351 13.704 11.2121 13.7349 11.2928 13.75ZM7.9377 5.61718C8.79902 5.61718 9.50039 6.3039 9.50039 7.14707C9.50039 7.99023 8.79727 8.67754 7.9377 8.67754C7.07812 8.67754 6.375 7.98965 6.375 7.1459C6.375 6.30215 7.07812 5.61601 7.9377 5.61601V5.61718Z"
                fill={c.gray.light}
            />
            <path
                d="M19.151 5.39806L15.0219 9.42697L13.5817 7.98498C13.4636 7.87249 13.3061 7.81064 13.143 7.81261C12.9799 7.81458 12.824 7.88023 12.7087 7.99553C12.5933 8.11084 12.5275 8.26668 12.5254 8.42979C12.5234 8.5929 12.5851 8.75036 12.6975 8.86857L15.0126 11.1836L20.0253 6.29279C20.1439 6.17709 20.2117 6.019 20.2138 5.8533C20.2159 5.68759 20.1521 5.52784 20.0364 5.4092C19.9207 5.29055 19.7626 5.22272 19.5969 5.22063C19.4312 5.21854 19.2714 5.28237 19.1528 5.39806H19.151Z"
                fill={c.gray.light}
            />
            <path
                d="M22.6254 8.62539C22.7911 8.62523 22.95 8.5593 23.0671 8.44207C23.1842 8.32484 23.25 8.16591 23.25 8.0002V3.1252C23.2494 2.46239 22.9858 1.82691 22.5172 1.35818C22.0486 0.889456 21.4132 0.625776 20.7504 0.625H3.2502C2.58734 0.625775 1.95186 0.889437 1.48315 1.35815C1.01444 1.82686 0.750775 2.46234 0.75 3.1252V15.0004C0.750776 15.6632 1.01446 16.2986 1.48318 16.7672C1.95191 17.2358 2.58739 17.4994 3.2502 17.5H8.06309C8.14528 17.4999 8.22668 17.516 8.30261 17.5475C8.37853 17.579 8.44748 17.6252 8.50547 17.6834L11.1158 20.2937C11.2319 20.4099 11.3698 20.502 11.5215 20.5648C11.6732 20.6277 11.8358 20.66 12 20.66C12.1642 20.66 12.3268 20.6277 12.4785 20.5648C12.6302 20.502 12.7681 20.4099 12.8842 20.2937L15.4945 17.6834C15.6119 17.5665 15.7707 17.5006 15.9363 17.5H20.7504C21.4131 17.4992 22.0484 17.2356 22.517 16.767C22.9856 16.2984 23.2492 15.6631 23.25 15.0004V8.125C23.245 7.96258 23.177 7.80847 23.0604 7.69534C22.9437 7.58221 22.7876 7.51894 22.6251 7.51894C22.4626 7.51894 22.3065 7.58221 22.1898 7.69534C22.0732 7.80847 22.0052 7.96258 22.0002 8.125V15.0004C21.9997 15.3317 21.8679 15.6493 21.6336 15.8836C21.3993 16.1179 21.0817 16.2497 20.7504 16.2502H15.9363C15.6902 16.2493 15.4464 16.2971 15.2189 16.3909C14.9914 16.4847 14.7848 16.6227 14.6109 16.7969L12 19.4102L9.38906 16.7969C9.21531 16.6223 9.00865 16.4838 8.78105 16.3896C8.55345 16.2953 8.30942 16.2472 8.06309 16.2479H3.2502C2.91882 16.2475 2.60111 16.1158 2.36679 15.8814C2.13248 15.6471 2.0007 15.3294 2.00039 14.998V3.1252C2.0007 2.79382 2.13248 2.47611 2.36679 2.24179C2.60111 2.00748 2.91882 1.8757 3.2502 1.87539H20.7504C21.0817 1.87586 21.3993 2.00768 21.6336 2.24196C21.8679 2.47625 21.9997 2.79387 22.0002 3.1252V8.0002C22.0002 8.16601 22.0661 8.32503 22.1833 8.44228C22.3006 8.55952 22.4596 8.62539 22.6254 8.62539Z"
                fill={c.gray.light}
            />
        </svg>
    );
};

export default SubscriptionsIcon;
