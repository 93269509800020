export class ApiClient {
    private readonly prefix: string | null = null;

    public constructor(prefix?: string) {
        if (prefix) {
            this.prefix = prefix;
        }
    }

    public async post<TRequest = any, TResponse = any>(
        url: string,
        data?: TRequest,
        token?: string,
        headers?: RequestHeaders
    ): Promise<TResponse> {
        const params: RequestInit = {};
        if (data) {
            params.body = JSON.stringify(data);
        }
        if (headers) {
            params.headers = headers;
        }

        params.method = 'POST';
        return this.sendRequest(url, params, token);
    }

    public async get<TResponse = any>(url: string, token?: string): Promise<TResponse> {
        return this.sendRequest(url, { method: 'GET' }, token);
    }

    public async delete<TResponse = any>(url: string, token?: string): Promise<TResponse> {
        return this.sendRequest(url, { method: 'DELETE' }, token);
    }

    private async sendRequest<TResponse = any>(
        url: string,
        params?: RequestInit,
        token?: string
    ): Promise<TResponse> {
        const finalParams: RequestInit = {
            credentials: 'same-origin',
            ...params,
            headers: {
                ...(token ? { 'X-CSRF': token } : {}),
                'Content-Type': 'application/json',
                ...params?.headers,
            },
        };

        const response = await fetch(`${this.prefix}${url}`, finalParams);

        if (response.ok) {
            if (response.redirected) {
                const redirectPath = new URL(response.url).pathname;
                const currentPath = new URL(window.location.href).pathname;
                if (currentPath !== redirectPath) {
                    window.location.href = redirectPath;
                }
                return Promise.reject('Redirected');
            }
            return response.json();
        }
        return Promise.reject(response.status);
    }
}

declare interface RequestHeaders {
    [key: string]: string;
}

export default Object.freeze(new ApiClient('/api'));
