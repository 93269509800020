import React, {ReactNode} from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import CompanyPage from './page/CompanyPage';
import Navigation from './component/navigation/Navigation';
import DetailsPage from './page/DetailsPage';
import LoginPage from './page/LoginPage';
import SignUpPage from './page/SignUpPage';
import SendResetLinkPage from './page/SendResetLinkPage';
import ResetPasswordPage from './page/ResetPasswordPage';
import ChangePasswordPage from './page/ChangePasswordPage';
import SubscriptionPage from './page/SubscriptionPage';
import {ContextProvider} from './common/context/AppContext';
import RedirectHandler from './internal/RedirectHandler';
import StandardBody from './StandardBody';
import ForbiddenPage from './page/ForbiddenPage';
import FailurePage from './page/FailurePage';
import {StaticUrls} from './staticUrls';
import Filter from './component/filter/Filter';
import ProfilePage from './page/ProfilePage';
import MySetupsPage from './page/MySetupsPage';
import AccountSubscriptionPage from './page/AccountSubscriptionPage';
import AdminPage from './page/AdminPage';
import Authenticated from './internal/Authenticated';
import {FilterContextProvider} from './common/context/FilterContext';
import ChartPage from './page/ChartPage';
import { Page } from './constants/enums';
import {appendScript} from './common/utils/utils';

interface WrapperProps {
    children: NonNullable<ReactNode>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WrapperState {
}

class Wrapper extends React.Component<Partial<WrapperProps>, WrapperState> {
    componentDidMount() {
        appendScript('https://js-eu1.hs-scripts.com/25710102.js');
    }

    render() {
        return (
            <>
                <Navigation/>
                {this.props.children}
            </>);
    }
}

export default function App() {

    return (
        <ContextProvider>
            <StandardBody>
                <BrowserRouter>
                    <Route key="/*" path="/*" component={RedirectHandler} />
                    <Switch>
                        <Route exact path={StaticUrls.LOGIN} render={() => <LoginPage/>}/>
                        <Route exact path={StaticUrls.RESET_LINK} render={() => <SendResetLinkPage/>}/>
                        <Route exact path={StaticUrls.RESET} render={() => <ResetPasswordPage />}/>
                        <Route exact path={StaticUrls.SIGNUP} render={() => <SignUpPage/>}/>
                        <Route exact path={StaticUrls.FORBIDDEN} render={() => <ForbiddenPage/>}/>
                        <Route exact path={StaticUrls.FAIL} render={() => <FailurePage/>}/>
                        <Authenticated>
                            <Route exact path="/" render={() => <FilterContextProvider><Wrapper><Filter/></Wrapper></FilterContextProvider>}/>
                            <Route exact path="/chart" render={() => <Wrapper><ChartPage /></Wrapper>}/>
                            <Route exact path="/company/:ticker"
                                   render={(props) => <Wrapper><DetailsPage props={props}/></Wrapper>}/>
                            <Route exact path="/company" render={() => <Wrapper><CompanyPage/></Wrapper>}/>
                            <Route exact path="/account"
                                   render={() => <Wrapper><ProfilePage page={Page.SUBSCRIPTION}><AccountSubscriptionPage/></ProfilePage></Wrapper>}/>
                            <Route exact path="/account/setups"
                                   render={() => <Wrapper><ProfilePage page={Page.SETUPS}><MySetupsPage/></ProfilePage></Wrapper>}/>
                            <Route exact path="/account/change-password"
                                   render={() => <Wrapper><ProfilePage page={Page.PASSWORD}><ChangePasswordPage/></ProfilePage></Wrapper>}/>
                            <Route exact path="/account/subscription"
                                   render={() => <Wrapper><ProfilePage page={Page.SUBSCRIPTION}><AccountSubscriptionPage/></ProfilePage></Wrapper>}/>
                            <Route exact path={StaticUrls.SUBSCRIPTION} render={() => <Wrapper><SubscriptionPage/></Wrapper>}/>
                            <Route exact path={StaticUrls.ADMIN} render={() => <Wrapper><AdminPage /></Wrapper>}/>
                        </Authenticated>
                    </Switch>
                </BrowserRouter>
            </StandardBody>
        </ContextProvider>
    );
}
