import React from 'react';
import { IconButton, Zoom } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    show: boolean;
    deleteCallback: () => void;
}

const DeleteButton = ({ show, deleteCallback }: Props) => {
    return (
        <Zoom in={show}>
            <IconButton sx={{ padding: 0, height: '6px' }} size="small" color="primary">
                <CloseIcon fontSize="inherit" onClick={deleteCallback} />
            </IconButton>
        </Zoom>
    );
};

export default DeleteButton;
