import React from 'react'

const StripeIcon = () => {
    return (
        <svg width="50" height="21" viewBox="0 0 50 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.51387 8.15395C3.51387 7.61235 3.9583 7.40395 4.69453 7.40395C5.75 7.40395 7.0833 7.72348 8.13887 8.29292V5.02895C6.98613 4.57065 5.84727 4.39009 4.69453 4.39009C1.875 4.39009 0 5.86235 0 8.32075C0 12.154 5.27773 11.5429 5.27773 13.1957C5.27773 13.8345 4.72227 14.0429 3.94443 14.0429C2.7917 14.0429 1.31943 13.5707 0.152734 12.9318V16.2373C1.44443 16.7929 2.75 17.029 3.94443 17.029C6.8333 17.029 8.81943 15.5985 8.81943 13.1124C8.80557 8.97348 3.51387 9.70952 3.51387 8.15395ZM12.9027 1.61235L9.51387 2.33452L9.5 13.4595C9.5 15.5152 11.0417 17.029 13.0973 17.029C14.2361 17.029 15.0694 16.8207 15.5277 16.5707V13.7512C15.0834 13.9318 12.8889 14.5707 12.8889 12.5151V7.58452H15.5277V4.62622H12.8889L12.9027 1.61235ZM19.8473 5.65395L19.625 4.62622H16.625V16.779H20.0973V8.54292C20.9166 7.47348 22.3056 7.66792 22.7361 7.82065V4.62622C22.2917 4.45952 20.6667 4.15395 19.8473 5.65395ZM23.5833 4.62622H27.0694V16.779H23.5833V4.62622ZM23.5833 3.57065L27.0694 2.82065V0.0012207L23.5833 0.737353V3.57065ZM34.3194 4.39009C32.9583 4.39009 32.0833 5.02895 31.5973 5.47348L31.4166 4.61235H28.3611V20.8067L31.8333 20.0707L31.8473 16.1401C32.3473 16.5012 33.0833 17.0151 34.3056 17.0151C36.7917 17.0151 39.0556 15.0151 39.0556 10.6124C39.0417 6.58452 36.75 4.39009 34.3194 4.39009ZM33.4861 13.9595C32.6667 13.9595 32.1805 13.6679 31.8473 13.3068L31.8333 8.15395C32.1944 7.75122 32.6944 7.47348 33.4861 7.47348C34.75 7.47348 35.625 8.89009 35.625 10.7095C35.625 12.5707 34.7639 13.9595 33.4861 13.9595ZM50 10.7512C50 7.19565 48.2777 4.39009 44.9861 4.39009C41.6805 4.39009 39.6805 7.19575 39.6805 10.7235C39.6805 14.904 42.0417 17.0151 45.4305 17.0151C47.0833 17.0151 48.3333 16.6401 49.2777 16.1124V13.3345C48.3334 13.8068 47.25 14.0985 45.875 14.0985C44.5277 14.0985 43.3333 13.6262 43.1806 11.9874H49.9723C49.9723 11.8067 50 11.0845 50 10.7512ZM43.1389 9.43178C43.1389 7.86235 44.0973 7.20952 44.9723 7.20952C45.8194 7.20952 46.7223 7.86235 46.7223 9.43178H43.1389Z" fill="#6772E5"/>
        </svg>
    )
}

export default StripeIcon
