export class AbstractFilter {
    private readonly _label: string;
    private readonly _tooltip: string;
    private readonly _type: string;

    constructor(label: string, tooltip: string, type: string) {
        this._label = label;
        this._tooltip = tooltip;
        this._type = type;
    }

    get label(): string {
        return this._label;
    }

    get tooltip(): string {
        return this._tooltip;
    }

    get type(): string {
        return this._type;
    }
}
