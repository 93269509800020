import Box from '@mui/material/Box';
import React from 'react';

interface PanelProps {
    children: any;
    value: any;
    index: any;
    other?: any;
}

export default function TabPanel({ children, value, index, ...other } : PanelProps) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
