/* eslint-disable react/prop-types */
import React from 'react';
import Paper from '@mui/material/Paper';
import ApiError from '../parts/ApiError';
import LinearProgress from '@mui/material/LinearProgress';
import {formatQueryDate, gap, largeNumber, monthAndDay} from '../../common/utils/utils';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import BulletSeparator from '../parts/BulletSeparator';
import Grid from '@mui/material/Grid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers';
import CustomDateTimePicker from '../parts/CustomDateTimePicker';
import apiService from '../../common/utils/apiService';
import { Box } from '@mui/material';

function InfoCard({top, bottom, date, additional}) {
    return (
        <Card style={{boxShadow: 'none'}}>
            <CardContent>
                <Typography>
                    {largeNumber(top)} {additional ? additional : ''}
                </Typography>
                <Typography color="textSecondary">
                    {bottom} {date ? '(' + monthAndDay(date) + ')' : ''}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default function Details({ticker, date, setDate}) {
    const response = apiService('company/companies/' + ticker + '/details?asOf=' + formatQueryDate(date));

    if (response.error) {
        return <ApiError error={response.error}/>;
    }

    if (response.isLoading) {
        return <LinearProgress />;
    }

    const handleDateChange = date => {
        setDate(date.valueOf());
    };

    return (
        <Paper>
            <Grid container justifyContent="space-between" spacing={1}>
                <Grid item>
                    <p style={{paddingLeft:'16px', paddingTop:'16px'}}>{response.result.country}<BulletSeparator/>{response.result.sector}<BulletSeparator/>{response.result.industry}</p>
                </Grid>
                <Grid item>
                    <Box sx={{paddingRight: 2}}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <CustomDateTimePicker date={date} handleChange={handleDateChange} label={'As of'}/>
                        </LocalizationProvider>
                    </Box>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-start" spacing={1} sx={{paddingY: 1, paddingLeft: 2}}>
                <Grid item>
                    <h1 style={{margin: 0}}>{response.result.name}</h1>
                </Grid>
                <Grid item sx={{margin: 0, transform: 'translateY(40%)'}}>
                    <Typography color="textSecondary">
                        {response.result.ticker}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-start">
                <Grid item>
                    <InfoCard top={response.result.sharesOutstanding} bottom={'Shares outstanding'}/>
                </Grid>
                <Grid item>
                    <InfoCard top={response.result.marketCap} bottom={'Market cap'}/>
                </Grid>
                <Grid item>
                    <InfoCard
                        top={response.result.latestTransaction.close}
                        bottom={'At close'}
                        date={response.result.latestTransaction.date}
                        additional={gap(response.result.latestTransaction.priceGap)}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}
