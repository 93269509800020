import {ResolutionString} from '../charting_library';

enum Constants {
    OneDayMillis = 86400 * 1000,
}

interface FromToOffset {
    fromOffset: number;
    toOffset: number;
}

function isWeekend(date: Date): boolean {
    let dayOfWeek: number = date.getDay();
    return dayOfWeek === 6 || dayOfWeek === 0;
}

function plusDay(date: Date) {
    return new Date(date.getTime() + Constants.OneDayMillis);
}

function minusDay(date: Date) {
    return new Date(date.getTime() - Constants.OneDayMillis);
}

function nextDay(date: Date): Date {
    let nextDay: Date = plusDay(date);
    while (isWeekend(nextDay)) {
        nextDay = plusDay(nextDay);
    }
    return nextDay;
}

function prevDay(date: Date): Date {
    let prevDay: Date = minusDay(date);
    while (isWeekend(prevDay)) {
        prevDay = minusDay(prevDay);
    }
    return prevDay;
}

function addDaysSkippingWeekend(d: Date, days: number): Date {
    for (let i: number = 0; i < days; i++) {
        d = nextDay(d);
    }

    return d;
}

function subtractDaysSkippingWeekend(d: Date, days: number): Date {
    for (let i: number = 0; i < days; i++) {
        d = prevDay(d);
    }

    return d;
}

export function getOffsetDays(resolution: ResolutionString) {
    let daysOffset: number = 0;
    switch (resolution) {
        case '5':
            daysOffset = 1;
            break;
        case '15':
            daysOffset = 3;
            break;
        case '30':
            daysOffset = 6;
            break;
        case '60':
            daysOffset = 12;
            break;
        case '1D':
            daysOffset = 30;
            break;
    }
    return daysOffset;
}

export function subtractDays(fromSeconds: number, daysOffset: number) {
    return subtractDaysSkippingWeekend(new Date(fromSeconds * 1000), daysOffset).getTime() / 1000;
}

function addDays(toSeconds: number, daysOffset: number) {
    return addDaysSkippingWeekend(new Date(toSeconds * 1000), daysOffset).getTime() / 1000;
}

export function getOffset(resolution: ResolutionString, fromSeconds: number, toSeconds: number): FromToOffset {
    let daysOffset = getOffsetDays(resolution);
    return {
        fromOffset: subtractDays(fromSeconds, daysOffset),
        toOffset: addDays(toSeconds, daysOffset)
    };
}