import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import IconButton from '@mui/material/IconButton';

interface Props {
    expanded: boolean;
    className?: string;
}

export default function ExpandLessOrMore({ expanded, className }: Props) {
    return (
        <IconButton className={className} size="large">
            {expanded ? (
                <ExpandLessIcon color="primary" />
            ) : (
                <ExpandMoreIcon color="primary" />
            )}
        </IconButton>
    );
}
