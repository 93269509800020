import {
	DatafeedConfiguration,
	ErrorCallback,
	GetMarksCallback,
	HistoryCallback,
	IDatafeedChartApi,
	IDatafeedQuotesApi,
	IExternalDatafeed,
	LibrarySymbolInfo,
	Mark,
	OnReadyCallback,
	QuotesCallback,
	ResolutionString,
	ResolveCallback,
	SearchSymbolResultItem,
	SearchSymbolsCallback,
	ServerTimeCallback,
	SubscribeBarsCallback,
	TimescaleMark,
	SymbolResolveExtension,
    MarkCustomColor
} from '../../charting_library/datafeed-api';

import {
	getErrorMessage,
	logMessage,
	RequestParams,
	UdfErrorResponse,
} from './helpers';

import {
	GetBarsResult,
	HistoryProvider,
	PeriodParamsWithOptionalCountback,
} from './history-provider';

import { IQuotesProvider } from './iquotes-provider';
import { DataPulseProvider } from './data-pulse-provider';
import { QuotesPulseProvider } from './quotes-pulse-provider';
import { SymbolsStorage } from './symbols-storage';
import { Requester } from './requester';
import {getOffsetDays, subtractDays} from '../offsetUtil';
import { TimeString } from '../../types/types';

export interface UdfCompatibleConfiguration extends DatafeedConfiguration {
	// tslint:disable:tv-variable-name
	supports_search?: boolean;
	supports_group_request?: boolean;
	// tslint:enable:tv-variable-name
}

export interface ResolveSymbolResponse extends LibrarySymbolInfo {
	s: undefined;
}

// it is hack to let's TypeScript make code flow analysis
export interface UdfSearchSymbolsResponse extends Array<SearchSymbolResultItem> {
	s?: undefined;
}

export enum Constants {
	SearchItemsLimit = 30,
	OneMonthSeconds = 30 * 86400,
	FiveMonthsSeconds = OneMonthSeconds * 5,
	FifteenMonthsSeconds = OneMonthSeconds * 15,
	ThirtyMonthsSeconds = OneMonthSeconds * 30
}

type UdfDatafeedMarkType<T extends TimescaleMark | Mark> = {
	[K in keyof T]: T[K] | T[K][];
} & {
	id: (string | number)[];
};

type UdfDatafeedMark = UdfDatafeedMarkType<Mark>;
type UdfDatafeedTimescaleMark = UdfDatafeedMarkType<TimescaleMark>;

function extractField<Field extends keyof Mark>(data: UdfDatafeedMark, field: Field, arrayIndex: number): Mark[Field];
function extractField<Field extends keyof TimescaleMark>(data: UdfDatafeedTimescaleMark, field: Field, arrayIndex: number): TimescaleMark[Field];
function extractField<T, TField extends keyof T>(data: T, field: TField, arrayIndex: number): T[TField] {
	const value = data[field];
	return Array.isArray(value) ? value[arrayIndex] : value;
}

export type IntradayRange = {
	_tag: 'IntradayRange';
	from: TimeString;
	to: TimeString;
}
export function IntradayRange(from: TimeString, to: TimeString): IntradayRange {
	return { _tag: 'IntradayRange', from, to };
}

export type IntradayRangeNumeric = {
	_tag: 'IntradayRangeNumeric';
	from: number;
	to: number;
}
export function IntradayRangeNumeric(from: number, to: number): IntradayRangeNumeric {
	return { _tag: 'IntradayRangeNumeric', from, to };
}

/**
 * This class implements interaction with UDF-compatible datafeed.
 * See UDF protocol reference at https://github.com/tradingview/charting_library/wiki/UDF
 */
export class UDFCompatibleDatafeedBase
  implements IExternalDatafeed, IDatafeedQuotesApi, IDatafeedChartApi
{
  protected _configuration: UdfCompatibleConfiguration = defaultConfiguration();
  private readonly _datafeedURL: string;
  private readonly _configurationReadyPromise: Promise<void>;

  private _symbolsStorage: SymbolsStorage | null = null;

  private readonly _historyProvider: HistoryProvider;
  private readonly _dataPulseProvider: DataPulseProvider;

  private readonly _quotesProvider: IQuotesProvider;
  private readonly _quotesPulseProvider: QuotesPulseProvider;

  private readonly _requester: Requester;

  private readonly _from: number;
  private readonly _to: number;

  private readonly _intradayRange?: IntradayRangeNumeric;

  protected constructor(
    datafeedURL: string,
    quotesProvider: IQuotesProvider,
    requester: Requester,
    from: number,
    to: number,
    intradayRange?: IntradayRangeNumeric
  ) {
    this._datafeedURL = datafeedURL;
    this._requester = requester;
    this._historyProvider = new HistoryProvider(datafeedURL, this._requester);
    this._quotesProvider = quotesProvider;

    this._from = from;
    this._to = to;

    this._intradayRange = intradayRange;

    this._dataPulseProvider = new DataPulseProvider(this._historyProvider, 0);
    this._quotesPulseProvider = new QuotesPulseProvider(this._quotesProvider);

    this._configurationReadyPromise = this._requestConfiguration().then(
      (configuration: UdfCompatibleConfiguration | null) => {
        if (configuration === null) {
          configuration = defaultConfiguration();
        }

        this._setupWithConfiguration(configuration);
      }
    );
  }

  public onReady(callback: OnReadyCallback): void {
    this._configurationReadyPromise.then(() => {
      callback(this._configuration);
    });
  }

  public getQuotes(
    symbols: string[],
    onDataCallback: QuotesCallback,
    onErrorCallback: (msg: string) => void
  ): void {
    this._quotesProvider
      .getQuotes(symbols)
      .then(onDataCallback)
      .catch(onErrorCallback);
  }

  public subscribeQuotes(
    symbols: string[],
    fastSymbols: string[],
    onRealtimeCallback: QuotesCallback,
    listenerGuid: string
  ): void {
    this._quotesPulseProvider.subscribeQuotes(
      symbols,
      fastSymbols,
      onRealtimeCallback,
      listenerGuid
    );
  }

  public unsubscribeQuotes(listenerGuid: string): void {
    this._quotesPulseProvider.unsubscribeQuotes(listenerGuid);
  }

  public getMarks(
    symbolInfo: LibrarySymbolInfo,
    from: number,
    to: number,
    onDataCallback: GetMarksCallback<Mark>,
    resolution: ResolutionString
  ): void {
    const marks: Mark[] = [];
    if (resolution === "1D") {
      marks.push({
        id: 1,
        time: this._from,
        color: "blue",
        label: "v",
        labelFontColor: "blue",
        minSize: 15,
        text: "Selected day",
      });
    } else {
      marks.push({
        id: 1,
        time: this._from,
        color: "blue",
        label: "v",
        labelFontColor: "blue",
        minSize: 15,
        text: "Timeframe start",
      });
      marks.push({
        id: 2,
        time: this._to,
        color: "blue",
        label: "v",
        labelFontColor: "blue",
        minSize: 15,
        text: "Timeframe end",
      });
    }

    if (this._intradayRange) {
      const color: MarkCustomColor = {
        color: '#9f5fff',
        background: '#5f9fff'
      }
      marks.push({
        id: 3,
        time: this._intradayRange.from,
        color: color,
        label: "I",
        labelFontColor: "blue",
        minSize: 15,
        text: "Intraday range start",
      });
      marks.push({
        id: 4,
        time: this._intradayRange.to,
        color: color,
        label: "I",
        labelFontColor: "blue",
        minSize: 15,
        text: "Intraday range end",
      });
    }

    onDataCallback(marks);
  }

  public getTimescaleMarks(
    symbolInfo: LibrarySymbolInfo,
    from: number,
    to: number,
    onDataCallback: GetMarksCallback<TimescaleMark>,
    resolution: ResolutionString
  ): void {
    const marks: TimescaleMark[] = [];
    if (resolution === "1D") {
      marks.push({
        id: 1,
        time: this._from,
        color: "#245eef",
        label: "^",
        tooltip: ["Selected day"],
      });
    } else {
      marks.push({
        id: 1,
        time: this._from,
        color: "#245eef",
        label: "^",
        tooltip: ["Timeframe start"],
      });
      marks.push({
        id: 2,
        time: this._to,
        color: "#245eef",
        label: "^",
        tooltip: ["Timeframe end"],
      });
    }

    onDataCallback(marks);
  }

  public getServerTime(callback: ServerTimeCallback): void {
    if (!this._configuration.supports_time) {
      return;
    }

    this._send<string>("time")
      .then((response: string) => {
        const time = parseInt(response);
        if (!isNaN(time)) {
          callback(time);
        }
      })
      .catch((error?: string | Error) => {
        logMessage(
          `UdfCompatibleDatafeed: Fail to load server time, error=${getErrorMessage(
            error
          )}`
        );
      });
  }

  public searchSymbols(
    userInput: string,
    exchange: string,
    symbolType: string,
    onResult: SearchSymbolsCallback
  ): void {
    if (this._configuration.supports_search) {
      const params: RequestParams = {
        limit: Constants.SearchItemsLimit,
        query: userInput.toUpperCase(),
        type: symbolType,
        exchange: exchange,
      };

      this._send<UdfSearchSymbolsResponse | UdfErrorResponse>("search", params)
        .then((response: UdfSearchSymbolsResponse | UdfErrorResponse) => {
          if (response.s !== undefined) {
            logMessage(
              `UdfCompatibleDatafeed: search symbols error=${response.errmsg}`
            );
            onResult([]);
            return;
          }

          onResult(response);
        })
        .catch((reason?: string | Error) => {
          logMessage(
            `UdfCompatibleDatafeed: Search symbols for '${userInput}' failed. Error=${getErrorMessage(
              reason
            )}`
          );
          onResult([]);
        });
    } else {
      if (this._symbolsStorage === null) {
        throw new Error(
          "UdfCompatibleDatafeed: inconsistent configuration (symbols storage)"
        );
      }

      this._symbolsStorage
        .searchSymbols(
          userInput,
          exchange,
          symbolType,
          Constants.SearchItemsLimit
        )
        .then(onResult)
        .catch(onResult.bind(null, []));
    }
  }

  public resolveSymbol(
    symbolName: string,
    onResolve: ResolveCallback,
    onError: ErrorCallback,
    extension?: SymbolResolveExtension
  ): void {
    logMessage("Resolve requested");

    const currencyCode = extension && extension.currencyCode;
    const unitId = extension && extension.unitId;

    const resolveRequestStartTime = Date.now();
    function onResultReady(symbolInfo: LibrarySymbolInfo): void {
      logMessage(`Symbol resolved: ${Date.now() - resolveRequestStartTime}ms`);
      onResolve(symbolInfo);
    }

    if (!this._configuration.supports_group_request) {
      const params: RequestParams = {
        symbol: symbolName,
      };
      if (currencyCode !== undefined) {
        params.currencyCode = currencyCode;
      }
      if (unitId !== undefined) {
        params.unitId = unitId;
      }

      this._send<ResolveSymbolResponse | UdfErrorResponse>("symbols", params)
        .then((response: ResolveSymbolResponse | UdfErrorResponse) => {
          if (response.s !== undefined) {
            onError("unknown_symbol");
          } else {
            onResultReady(response);
          }
        })
        .catch((reason?: string | Error) => {
          logMessage(
            `UdfCompatibleDatafeed: Error resolving symbol: ${getErrorMessage(
              reason
            )}`
          );
          onError("unknown_symbol");
        });
    } else {
      if (this._symbolsStorage === null) {
        throw new Error(
          "UdfCompatibleDatafeed: inconsistent configuration (symbols storage)"
        );
      }

      this._symbolsStorage
        .resolveSymbol(symbolName, currencyCode, unitId)
        .then(onResultReady)
        .catch(onError);
    }
  }

  public getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParamsWithOptionalCountback,
    onResult: HistoryCallback,
    onError: ErrorCallback
  ): void {
    if (periodParams.firstDataRequest) {
      let limit: number | null = null;
      switch (resolution) {
        case "1":
          limit = Constants.OneMonthSeconds;
          break;
        case "2":
          limit = Constants.OneMonthSeconds * 2;
          break;
        case "3":
          limit = Constants.OneMonthSeconds * 3;
          break;
        case "5":
          limit = Constants.FiveMonthsSeconds;
          break;
        case "15":
          limit = Constants.FifteenMonthsSeconds;
          break;
        case "30":
          limit = Constants.ThirtyMonthsSeconds;
          break;
      }
      if (limit) {
        this._historyProvider
          .getBars(symbolInfo, resolution, {
            from: subtractDays(this._from, getOffsetDays(resolution)),
            to: this._to + limit,
            countBack: periodParams.countBack,
            firstDataRequest: periodParams.firstDataRequest,
          })
          .then((result: GetBarsResult) => {
            onResult(result.bars, result.meta);
          })
          .catch(onError);
        return;
      }
    }
    this._historyProvider
      .getBars(symbolInfo, resolution, periodParams)
      .then((result: GetBarsResult) => {
        onResult(result.bars, result.meta);
      })
      .catch(onError);
  }

  public subscribeBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
    listenerGuid: string,
    onResetCacheNeededCallback: () => void
  ): void {}

  public unsubscribeBars(listenerGuid: string): void {}

  protected _requestConfiguration(): Promise<UdfCompatibleConfiguration | null> {
    return this._send<UdfCompatibleConfiguration>("config").catch(
      (reason?: string | Error) => {
        logMessage(
          `UdfCompatibleDatafeed: Cannot get datafeed configuration - use default, error=${getErrorMessage(
            reason
          )}`
        );
        return null;
      }
    );
  }

  private _send<T>(urlPath: string, params?: RequestParams): Promise<T> {
    return this._requester.sendRequest<T>(this._datafeedURL, urlPath, params);
  }

  private _setupWithConfiguration(
    configurationData: UdfCompatibleConfiguration
  ): void {
    this._configuration = configurationData;

    if (configurationData.exchanges === undefined) {
      configurationData.exchanges = [];
    }

    if (
      !configurationData.supports_search &&
      !configurationData.supports_group_request
    ) {
      throw new Error(
        "Unsupported datafeed configuration. Must either support search, or support group request"
      );
    }

    if (
      configurationData.supports_group_request ||
      !configurationData.supports_search
    ) {
      this._symbolsStorage = new SymbolsStorage(
        this._datafeedURL,
        configurationData.supported_resolutions || [],
        this._requester
      );
    }

    logMessage(
      `UdfCompatibleDatafeed: Initialized with ${JSON.stringify(
        configurationData
      )}`
    );
  }
}

function defaultConfiguration(): UdfCompatibleConfiguration {
	return {
		supports_search: false,
		supports_group_request: true,
		supported_resolutions: [
			'1' as ResolutionString,
			'5' as ResolutionString,
			'15' as ResolutionString,
			'30' as ResolutionString,
			'60' as ResolutionString,
			'1D' as ResolutionString,
			'1W' as ResolutionString,
			'1M' as ResolutionString,
		],
		supports_marks: false,
		supports_timescale_marks: false,
	};
}
