import React from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { IconButton, TableContainer, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import apiService from '../common/utils/apiService';
import ApiError from '../component/parts/ApiError';
import { sendDelete } from '../common/utils/sendBody';
import Setup from '../types/Setup';
import { colors as c } from '../common/theme';

export default function MySetupsPage() {
    const response = apiService<Setup[]>('setup');

    if (response.error) {
        return <ApiError error={response.error} />;
    }

    if (response.isLoading) {
        return <LinearProgress />;
    }

    const clearCustomFilters = (setupName: string) => {
        localStorage.removeItem(`customFilters-${setupName}`);
    };

    const remove = async (id: number, setupName: string) => {
        clearCustomFilters(setupName);
        sendDelete('/api/setup/' + id, () => response.fetchData('setup'));
    };

    return (
        <>
            <Typography
                fontSize="20px"
                fontWeight={600}
                padding="30px 54px 24px"
                lineHeight="20px"
            >
                My setups
            </Typography>
            <TableContainer sx={{ paddingX: '54px' }}>
                <Table>
                    <TableBody>
                        {response.result.map((e, i) => (
                            <TableRow
                                key={i}
                                sx={{
                                    borderBottom: `1px solid ${c.divider}`,
                                    borderTop: `1px solid ${c.divider}`,
                                }}
                            >
                                <TableCell
                                    sx={{
                                        color: c.gray.light,
                                        fontSize: '16px',
                                        border: 'none',
                                        lineHeight: '20px',
                                        padding: '3px 0',
                                    }}
                                >
                                    {e.name}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{ border: 'none', padding: '3px 0' }}
                                >
                                    <IconButton
                                        onClick={() => remove(e.id, e.name)}
                                        size="large"
                                        color="primary"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <IconButton
                                        href={'/' + e.link}
                                        size="large"
                                        color="primary"
                                    >
                                        <ChevronRightIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
