import { Redirect, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { StaticUrls } from '../staticUrls';
import {useAppContext} from '../common/context/AppContext';
import {encodedLocation, hasAdminRole} from '../common/utils/utils';

function skipRedirectToLogin(url: string) {
    const allowedUrls = [StaticUrls.LOGIN, StaticUrls.FORBIDDEN, StaticUrls.FAIL, StaticUrls.RESET, StaticUrls.SIGNUP, StaticUrls.RESET_LINK];
    return !allowedUrls.find(allowed => url.endsWith(allowed));
}

function unauthenticatedUrl(url: string) {
    const unauthenticatedUrls = [StaticUrls.LOGIN, StaticUrls.FORBIDDEN, StaticUrls.RESET, StaticUrls.SIGNUP, StaticUrls.RESET_LINK];
    return unauthenticatedUrls.find(allowed => url.endsWith(allowed));
}

function failUrl(url: string) {
    const failUrls = [StaticUrls.FAIL];
    return failUrls.find(allowed => url.endsWith(allowed));
}

function subscribedUrl(url: string) {
    const allowedUrls = [StaticUrls.FILTER];
    return allowedUrls.find(allowed => url.endsWith(allowed));
}

function adminUrl(url: string) {
    const adminUrls = [StaticUrls.ADMIN];
    return adminUrls.find(allowed => url.endsWith(allowed));
}

export default function RedirectHandler() {
    const match = useRouteMatch();
    const { global: { username, subscriptionActive, error, roles } = {} } = useAppContext();

    if (error) {
        return <Redirect to={StaticUrls.FAIL} />;
    } else if (failUrl(match.url)) {
        return <Redirect to={'/'} />
    }

    if (!username && skipRedirectToLogin(match.url)) {
        return <Redirect to={StaticUrls.LOGIN + '?redir=' + encodedLocation()} />;
    }

    if (username && unauthenticatedUrl(match.url)) {
        return <Redirect to={'/'} />;
    }

    if (username && !subscriptionActive && subscribedUrl(match.url)) {
        return <Redirect to={StaticUrls.SUBSCRIPTION} />
    }

    // if (subscriptionActive && subscriptionUrl(match.url)) {
    //     return <Redirect to={'/'} />;
    // }

    if (adminUrl(match.url) && !hasAdminRole(roles)) {
        return <Redirect to={StaticUrls.FILTER} />
    }
    return <></>;
}
