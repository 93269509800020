import * as React from 'react';
import './index.css';
import {
	widget,
	ChartingLibraryWidgetOptions,
	LanguageCode,
	IChartingLibraryWidget,
	ResolutionString,
} from '../charting_library';
import moment from 'moment-timezone';
import {UDFCompatibleDatafeed} from './data/udf-compatible-datafeed';
import {formatDate} from "../common/utils/utils";
import {getOffset} from './offsetUtil';
import { IntradayRange, IntradayRangeNumeric } from './data/udf-compatible-datafeed-base';
import { DateString, TimeString } from '../types/types';

export interface ChartState {
	resolution: ResolutionString;
	studies: string[];
}

export interface ChartContainerProps {
	symbol: ChartingLibraryWidgetOptions['symbol'];
	interval?: ChartingLibraryWidgetOptions['interval'];

	date: DateString;
	timeFrom: TimeString;
	timeTo: TimeString;

	intradayRange?: IntradayRange;

	chartState?: object;

	// BEWARE: no trailing slash is expected in feed URL
	datafeedUrl?: string;
	libraryPath?: ChartingLibraryWidgetOptions['library_path'];
	chartsStorageUrl?: ChartingLibraryWidgetOptions['charts_storage_url'];
	chartsStorageApiVersion?: ChartingLibraryWidgetOptions['charts_storage_api_version'];
	clientId?: ChartingLibraryWidgetOptions['client_id'];
	userId?: ChartingLibraryWidgetOptions['user_id'];
	fullscreen?: ChartingLibraryWidgetOptions['fullscreen'];
	autosize?: ChartingLibraryWidgetOptions['autosize'];
	studiesOverrides?: ChartingLibraryWidgetOptions['studies_overrides'];
	container?: ChartingLibraryWidgetOptions['container'];
	size?: 'small' | 'mid' | 'full'
	theme?: 'Light' | 'Dark'
	syncCharts?: (state: object) => void
}

export interface ChartContainerState {
}

function getLanguageFromURL(): LanguageCode | null {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	/* eslint-disable no-restricted-globals*/
	const results = regex.exec(location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
}

export class TradingViewChart extends React.Component<ChartContainerProps, ChartContainerState> {
	public static defaultProps: Omit<ChartContainerProps, 'container' | 'date' | 'timeFrom' | 'timeTo'> = {
		symbol: 'AAPL',
		interval: '1' as ResolutionString,
		datafeedUrl: '/api/udf' as string,
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
		theme: 'Dark',
	};

	private tvWidget: IChartingLibraryWidget | null = null;
	private ref: React.RefObject<HTMLDivElement> = React.createRef();
	private formattedDate = formatDate(this.props.date);

	private timeFrom: number = moment.tz(this.formattedDate + ' ' + this.props.timeFrom, 'America/New_York').unix()
	private timeTo: number = moment.tz(this.formattedDate + ' ' + this.props.timeTo, 'America/New_York').unix();

	private intradayRangeNumeric?: IntradayRangeNumeric = 
		this.props.intradayRange != undefined
		? IntradayRangeNumeric(
			moment.tz(this.formattedDate + ' ' + this.props.intradayRange.from, 'America/New_York').unix(),
			moment.tz(this.formattedDate + ' ' + this.props.intradayRange.to, 'America/New_York').unix()
		)
		: undefined;

	public componentDidMount(): void {
		if (!this.ref.current) {
			return;
		}

		const tvChart = this;

		const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this.props.symbol as string,
      // timezone: 'America/New_York',
      timezone: "exchange",
      disabled_features: [
        "symbol_search_hot_key",
        "border_around_the_chart",
        "remove_library_container_border",
        "main_series_scale_menu",
        "go_to_date",
        "control_bar",
        "timeframes_toolbar",
        "save_chart_properties_to_local_storage",
        "header_undo_redo",
        "header_compare",
        "header_saveload",
        "display_market_status",
        "header_symbol_search",
        "edit_buttons_in_legend",
        "scales_date_format",
        "pricescale_currency",
        "countdown",
      ],
      theme: this.props.theme,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: new UDFCompatibleDatafeed(
        this.props.datafeedUrl ? this.props.datafeedUrl : "",
        this.timeFrom,
        this.timeTo,
        this.intradayRangeNumeric
      ),
      interval: this.props.interval as ChartingLibraryWidgetOptions["interval"],
      container: this.ref.current,
      library_path: this.props.libraryPath as string,
      timeframe: { from: this.timeFrom, to: this.timeTo },

      locale: getLanguageFromURL() || "en",
      enabled_features: ["move_logo_to_main_pane", "pre_post_market_sessions"],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides,
    };

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;

		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				if (tvChart.props.syncCharts) {
					const syncIndicatorsBtn = tvWidget.createButton();
					syncIndicatorsBtn.innerHTML = "Sync charts";
					syncIndicatorsBtn.setAttribute("title", "Sync interval and indicators on all charts");
					syncIndicatorsBtn.classList.add("isInteractive-uO7HM85b");
					syncIndicatorsBtn.classList.add("button-uO7HM85b");
					syncIndicatorsBtn.addEventListener("click",
						(function() {
							if (tvChart.props.syncCharts) {
								const studyTemplate = tvWidget.activeChart().createStudyTemplate({
									saveSymbol: false,
									saveInterval: true
								});
								tvChart.props.syncCharts(studyTemplate);
							}
						}));
				}

				const initialTimeframeBtn = tvWidget.createButton();
				initialTimeframeBtn.innerHTML = "Initial timeframe";
				initialTimeframeBtn.setAttribute("title", "Reset to initial timeframe");
				initialTimeframeBtn.classList.add("isInteractive-uO7HM85b");
				initialTimeframeBtn.classList.add("button-uO7HM85b");
				initialTimeframeBtn.addEventListener("click",
					(function() {
						tvWidget?.activeChart().dataReady(() => {
							const offset = getOffset(tvWidget.activeChart().resolution(), tvChart.timeFrom, tvChart.timeTo);
							tvWidget?.activeChart().setVisibleRange({ from: offset.fromOffset, to: offset.toOffset })
						})
					}));

				const datBtn = tvWidget.createButton();
				datBtn.innerHTML = tvChart.props.symbol + " - " + tvChart.props.date;
			});

			tvWidget.activeChart().onIntervalChanged().subscribe(null, (interval, timeframeObj) => {
				const offset = getOffset(interval, tvChart.timeFrom, tvChart.timeTo);
				timeframeObj.timeframe = {
					from: offset.fromOffset,
					to: offset.toOffset,
					// @ts-ignore
					type: "time-range"
				};
			});

			if (this.props.chartState) {
				this.tvWidget?.activeChart().applyStudyTemplate(this.props.chartState)
			}
		});
	}

	public componentWillUnmount(): void {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	public componentDidUpdate(prevProps: Readonly<Partial<ChartContainerProps>>, prevState: Readonly<ChartContainerState>, snapshot?: any) {
		if (this.tvWidget) {
			if (prevProps.chartState !== this.props.chartState) {
			  this.tvWidget.onChartReady(() => {
				if (this.tvWidget?.activeChart() && this.props.chartState) {
				  this.tvWidget?.activeChart().applyStudyTemplate(this.props.chartState)
				}
			  })
			}
		  }
	}

	shouldComponentUpdate(nextProps: Readonly<Partial<ChartContainerProps>>, nextState: Readonly<ChartContainerState>) {
        return JSON.stringify(this.props) !== JSON.stringify(nextProps);
    }

	
	public render(): JSX.Element {
		let className = 'TVChartContainer';
		if (this.props.size === 'mid') {
			className = 'midTVChartContainer';
		} else if (this.props.size === 'small') {
			className = 'smallTVChartContainer';
		}
		return (
			<div
				ref={ this.ref }
				className={className}
			/>
		);
	}
}
