import React from 'react'
import { Button, Grid } from '@mui/material'
import ControlPointIcon from '@mui/icons-material/ControlPoint';

import {colors as c} from '../../common/theme';

interface IAddFilterButton {
    onClick:() => void,
}

const AddFilterButton = ({onClick} : IAddFilterButton) => {

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
    <Button variant="outlined" sx={{
        height: '38px',
        textTransform: 'none',
        fontWeight:'bold',
        color: c.gray.medium,
        marginTop: '26px',
        border: '2px solid rgba(255, 255, 255, 0.23)',
        '&:hover': {
            border: '2px solid rgba(255, 255, 255, 0.5)', '& svg': {color: 'white'}
        },
    }} onClick={onClick} endIcon={<ControlPointIcon color="primary"/>} disableFocusRipple>
        Add
    </Button>
</Grid>
  )
}

export default AddFilterButton
