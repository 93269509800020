import React from 'react';
import { Box, Typography } from '@mui/material';

import { useAppContext } from '../common/context/AppContext';
import { formatDate, getCookie } from '../common/utils/utils';
import ProductDisplay from '../component/checkout/ProductDisplay';
import FilledButton from '../component/buttons/FilledButton';
import { colors as c } from '../common/theme';
import Grid from '@mui/material/Grid';

export default function AccountSubscriptionPage() {
    const { global: { subscribedUntil, subscription } = {} } = useAppContext();

    const manage = async () => {
        const token = getCookie('XSRF-TOKEN');
        const params: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...(token ? { 'X-XSRF-TOKEN': token } : {}),
            },
        };
        const response = await fetch(
            '/api/stripe/create-customer-portal-session',
            params
        );
        const session = await response.json();

        if (session.url) {
            window.location.href = session.url;
        }
    };
    return (
        <Box sx={{ padding: '30px 54px' }}>
            <Typography
                fontSize="20px"
                lineHeight="20px"
                fontWeight={600}
                marginBottom="24px"
            >
                Subscribtion status:
            </Typography>
            <Box height="1px" bgcolor={c.divider} width="100%" />
            <Typography marginTop="31px" marginBottom="24px" color={c.gray.light}>
                Subscribed until:{' '}
                {subscribedUntil ? formatDate(subscribedUntil) : ''}
            </Typography>
            {subscription && subscription === 'FREE' ? (
                <Box sx={{ paddingTop: 4 }}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        style={{ minHeight: '100vh' }}
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ProductDisplay upgrade />
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <FilledButton type="submit" onClick={manage}>
                    Manage
                </FilledButton>
            )}
        </Box>
    );
}
