import {StateHoldingFilter} from '../../types/StateHoldingFilter';
import {StateHoldingTimeFilter} from '../../types/StateHoldingTimeFilter';
import {StateHoldingBooleanFilter} from '../../types/StateHoldingBooleanFilter';
import {AbstractStateHoldingFilter} from '../../types/AbstractStateHoldingFilter';
import {AbstractFilter} from '../../types/AbstractFilter';
import {DateFilter} from '../../types/DateFilter';
import {NumericFilter} from '../../types/NumericFilter';
import {TimeFilter} from '../../types/TimeFilter';
import {BooleanFilter} from '../../types/BooleanFilter';
import {AutocompleteFilter} from '../../types/AutocompleteFilter';
import {Filters} from '../../common/context/FilterContextTypes';

export class FilterArray {
    public name: string;
    public type: string;
    public filters: AbstractStateHoldingFilter[];

    constructor(name: string, type: string, filters: AbstractStateHoldingFilter[]) {
        this.name = name;
        this.type = type;
        this.filters = filters;
    }
}

export function filterArray(filters: Filters): AbstractFilter[] {
    // prettier-ignore
    return [
        DateFilter.of('Date', 'select the dates you want to get data for', 'desc', filters.dateTo.val, filters.dateTo.set, filters.dateFrom.val, filters.dateFrom.set),
        NumericFilter.of('Performance %', 'Day performance percentage of the stock', 'tech', filters.performance.val, filters.performance.set),
        NumericFilter.of('Performance Open - HOD %', 'Day change from Open to HOD', 'tech', filters.performanceOpenHigh.val, filters.performanceOpenHigh.set),
        NumericFilter.of('Performance Open - LOD %', 'Day change from Open to LOD', 'tech', filters.performanceOpenLow.val, filters.performanceOpenLow.set),
        NumericFilter.of('Performance HOD - Close %', 'Day change from HOD to Close', 'tech', filters.performanceHighClose.val, filters.performanceHighClose.set),
        NumericFilter.of('Performance LOD - Close %', 'Day change from LOD to Close', 'tech', filters.performanceLowClose.val, filters.performanceLowClose.set),
        NumericFilter.of('Performance LOD - HOD %', 'Day change from LOD to HOD', 'tech', filters.performanceLowHigh.val, filters.performanceLowHigh.set),
        NumericFilter.of('Performance HOD - LOD %', 'Day change from HOD to LOD', 'tech', filters.performanceHighLow.val, filters.performanceHighLow.set),
        NumericFilter.of('Performance PM High - HOD %', 'Change from Pre Market High to HOD', 'tech', filters.performancePmHighHigh.val, filters.performancePmHighHigh.set),
        NumericFilter.of('Gap %', 'Difference between previous day close and current day open', 'tech', filters.gap.val, filters.gap.set),
        NumericFilter.of('PM Gap %', 'Difference between previous day close and current day pre-market open', 'tech', filters.pmGap.val, filters.pmGap.set),
        NumericFilter.of('PM High Gap %', 'Difference between previous day close and current day pre-market high', 'tech', filters.pmHighGap.val, filters.pmHighGap.set),
        NumericFilter.of('Change Previous Day %', 'Difference between the close from the previous trading day to the close from the day before that', 'tech', filters.changePreviousDay.val, filters.changePreviousDay.set),
        NumericFilter.of('Change 5 Days %', 'Difference between the current price to the price 5 (trading) days before', 'tech', filters.changeFiveDays.val, filters.changeFiveDays.set),
        NumericFilter.of('Change 10 Days %', 'Difference between the current price to the price 10 (trading) days before', 'tech', filters.changeTenDays.val, filters.changeTenDays.set),
        NumericFilter.of('Change 20 Days %', 'Difference between the current price to the price 20 (trading) days before', 'tech', filters.changeTwentyDays.val, filters.changeTwentyDays.set),
        NumericFilter.of('Volume', 'select the number of shares traded in the security daily', 'desc', filters.volume.val, filters.volume.set),
        NumericFilter.of('Volume in dollars $', 'The total value of the stock in dollars', 'desc', filters.dollarVolume.val, filters.dollarVolume.set),
        NumericFilter.of('Volume (pre market)', 'select the number of shares traded in the security in pre market', 'desc', filters.pmVolume.val, filters.pmVolume.set),
        NumericFilter.of('Volume Yesterday %', 'Difference between total volume of yesterday to the average daily volume', 'tech', filters.volumeYesterday.val, filters.volumeYesterday.set),
        NumericFilter.of('Average volume', 'select daily (9:30am-4:00pm) average volume', 'desc', filters.avgVol.val, filters.avgVol.set),
        NumericFilter.of('Average volume (pre market)', 'select pre market (4:00am-9:30am) average volume', 'desc', filters.avgPmVol.val, filters.avgPmVol.set),
        NumericFilter.of('RVOL', 'select daily (9:30am-4:00pm) relative volume (ratio between current volume and 1-month average)', 'desc', filters.rvol.val, filters.rvol.set),
        NumericFilter.of('RVOL (pre market)', 'select pre market (4:00am-9:30am) relative volume (ratio between current volume and 1-month average)', 'desc', filters.pmRvol.val, filters.pmRvol.set),
        NumericFilter.of('Price', 'select the price of the stock', 'desc', filters.priceRange.val, filters.priceRange.set),
        NumericFilter.of('Shares outstanding', 'select shares outstanding (represents the total number of shares issued by the company and held by its shareholders)', 'desc', filters.shares.val, filters.shares.set),
        NumericFilter.of('Market cap', 'select market cap (the total market value of all of a company’s outstanding shares)', 'desc', filters.marketCap.val, filters.marketCap.set),
        NumericFilter.of('ADR', '14 day Average Day Range', 'tech', filters.dailyRangeAvg.val, filters.dailyRangeAvg.set),
        NumericFilter.of('ATR', '14 day Average True Range', 'tech', filters.trueRangeAvg.val, filters.trueRangeAvg.set),
        TimeFilter.of('HOD time', 'select the timeframe of high of day', 'tech', filters.hodTo.val, filters.hodTo.set, filters.hodFrom.val, filters.hodFrom.set),
        TimeFilter.of('LOD time', 'select the timeframe of low of day', 'tech', filters.lodTo.val, filters.lodTo.set, filters.lodFrom.val, filters.lodFrom.set),
        TimeFilter.of('PM high time', 'select the timeframe of pre market high time', 'tech', filters.pmHighTo.val, filters.pmHighTo.set, filters.pmHighFrom.val, filters.pmHighFrom.set),
        TimeFilter.of('PM low time', 'select the timeframe of pre market low time', 'tech', filters.pmLowTo.val, filters.pmLowTo.set, filters.pmLowFrom.val, filters.pmLowFrom.set),
        NumericFilter.of('PM Fade %', 'Pre-market high to open price performance', 'tech', filters.pmFade.val, filters.pmFade.set),
        BooleanFilter.of('PM Break', 'Did HOD exceed PM High', 'tech', filters.pmBreak.val, filters.pmBreak.set),
        AutocompleteFilter.of('Tickers', 'select the tickers you want to filter', 'desc', filters.tickers.val, filters.tickers.set, 'filter/tickers'),
        AutocompleteFilter.of('Sectors', 'select the sector you want to filter', 'desc', filters.sectors.val, filters.sectors.set, 'filter/sectors'),
        AutocompleteFilter.of('Industries', 'select the industries you want to filter', 'desc', filters.industries.val, filters.industries.set, 'filter/industries'),
        AutocompleteFilter.of('Countries', 'select the countries you want to filter', 'desc', filters.countries.val, filters.countries.set, 'filter/countries'),
    ];
}

export function newFilterArray (name: string): FilterArray {
    if (Number(name)) {
        return new FilterArray(name, 'day', [
            StateHoldingFilter.of('Performance %', 'Day performance percentage of the stock', 'tech'),
            StateHoldingFilter.of('Performance Open - HOD %', 'Day change from Open to HOD', 'tech'),
            StateHoldingFilter.of('Performance Open - LOD %', 'Day change from Open to LOD', 'tech'),
            StateHoldingFilter.of('Performance HOD - Close %', 'Day change from HOD to Close', 'tech'),
            StateHoldingFilter.of('Performance LOD - Close %', 'Day change from LOD to Close', 'tech'),
            StateHoldingFilter.of('Performance LOD - HOD %', 'Day change from LOD to HOD', 'tech'),
            StateHoldingFilter.of('Performance HOD - LOD %', 'Day change from HOD to LOD', 'tech'),
            StateHoldingFilter.of('Performance PM High - HOD %', 'Change from Pre Market High to HOD', 'tech'),
            StateHoldingFilter.of('Gap %', 'Difference between previous day close and current day open', 'tech'),
            StateHoldingFilter.of('PM Gap %', 'Difference between previous day close and current day pre-market open', 'tech'),
            StateHoldingFilter.of('PM High Gap %', 'Difference between previous day close and current day pre-market high', 'tech'),
            StateHoldingFilter.of('Volume', 'select the number of shares traded in the security daily', 'desc'),
            StateHoldingFilter.of('Volume in dollars $', 'The total value of the stock in dollars', 'desc'),
            StateHoldingFilter.of('Volume (pre market)', 'select the number of shares traded in the security in pre market', 'desc'),
            StateHoldingFilter.of('Average volume', 'select daily (9:30am-4:00pm) average volume', 'desc'),
            StateHoldingFilter.of('Average volume (pre market)', 'select pre market (4:00am-9:30am) average volume', 'desc'),
            StateHoldingFilter.of('RVOL', 'select daily (9:30am-4:00pm) relative volume (ratio between current volume and 1-month average)', 'desc'),
            StateHoldingFilter.of('RVOL (pre market)', 'select pre market (4:00am-9:30am) relative volume (ratio between current volume and 1-month average)', 'desc'),
            StateHoldingFilter.of('Price', 'select the price of the stock', 'desc'),
            StateHoldingFilter.of('Shares outstanding', 'select shares outstanding (represents the total number of shares issued by the company and held by its shareholders)', 'desc'),
            StateHoldingFilter.of('Market cap', 'select market cap (the total market value of all of a company’s outstanding shares)', 'desc'),
            StateHoldingTimeFilter.of('HOD time', 'select the timeframe of high of day', 'tech'),
            StateHoldingTimeFilter.of('LOD time', 'select the timeframe of low of day', 'tech'),
            StateHoldingTimeFilter.of('PM high time', 'select the timeframe of pre market high time', 'tech'),
            StateHoldingTimeFilter.of('PM low time', 'select the timeframe of pre market low time', 'tech'),
            StateHoldingFilter.of('PM Fade %', 'Pre-market high to open price performance', 'tech'),
            StateHoldingBooleanFilter.of('PM Break', 'Did HOD exceed PM High', 'tech'),
        ]);
    } else {
        return new FilterArray(name, 'range', [
            StateHoldingFilter.of('Performance %', 'Performance change in selected period', 'tech'),
            StateHoldingFilter.of('Volume', 'Volume sum traded in selected period', 'desc'),
            StateHoldingFilter.of('Volume in dollars $', 'Dollar volume sum traded in selected period', 'desc'),
        ]);
    }
}

export interface IntraFilters {
    moveDuration: StateHoldingFilter;
    time: StateHoldingTimeFilter;
    performance: StateHoldingFilter;
    volume: StateHoldingFilter;

}

export function intraRangeFilters (): IntraFilters {
    return {
        moveDuration: StateHoldingFilter.from('Move duration', 'Select duration of the move', 'tech', ['5m', '10m']),
        time: StateHoldingTimeFilter.of('Time of day', 'Select timeframe of the move', 'tech'),
        performance: StateHoldingFilter.of('Performance %', 'Performance change in selected duration', 'tech'),
        volume: StateHoldingFilter.of('Volume', 'Volume sum traded in selected duration', 'desc'),
    }
}
