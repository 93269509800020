import React, {useState} from 'react';
import FromToInput, {FromToInputProps} from './FromToInput';

export default function WrappedFromToInput({
    value,
    setValue,
    label,
    tooltip,
    checkActiveCount,
    disableNulls,
    deleteCallback,
}: FromToInputProps) {
    const [stateVal, setStateVal] = useState(value);
    const handleChange = (v: any) => {
        setValue(v);
        setStateVal(v);
    };
    return <FromToInput
        value={stateVal}
        setValue={handleChange}
        label={label}
        checkActiveCount={checkActiveCount}
        tooltip={tooltip}
        disableNulls={disableNulls}
        deleteCallback={deleteCallback}
    />;
}
