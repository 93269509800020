import React, { ChangeEvent, memo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, styled } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

import apiService from '../../common/utils/apiService';
import Label from '../inputs/Label';
import { colors as c } from '../../common/theme';

export interface FilterAutocompleteProps {
    label: string;
    path: string;
    value: string;
    setValue: (v: any) => void;
    tooltip?: string;
    checkActiveCount: () => void;
    disableNulls?: boolean;
    deleteCallback?: () => void;
}

const StyledExpandMore = styled(ExpandMore)({
    fill: c.primary,
  });
const StyledCloseIcon = styled(CloseIcon)({
    fill: c.primary,
  });

export default memo(function FilterAutocomplete({
    label,
    path,
    value,
    setValue,
    tooltip,
    checkActiveCount,
    disableNulls,
    deleteCallback,
}: FilterAutocompleteProps) {
    const [query, setQuery] = useState('');
    const response = apiService<any[]>(path + (query ? '?query=' + query : ''));

    const changeQuery = (event: ChangeEvent<any>) => {
        setQuery(event.target.value);
    };

    const changeValue = (event: any, value: any[]) => {
        setValue(value);
        setQuery('');
    };

    const clearValuesAndRemoveFilter = () => {
        if (deleteCallback) {
            setValue([]);
            setQuery('');
            deleteCallback();
        }
    };
    const disable = disableNulls && (!value || value.length === 0);
    return (
        <Label
            label={label}
            tooltip={tooltip}
            deleteCallback={
                deleteCallback ? clearValuesAndRemoveFilter : undefined
            }
        >
            <Box
                display="flex"
                border="1px solid"
                sx={{
                    transition:
                        'border-color 0.3s ease-out, background-color 0.3s ease-out',
                }}
                borderColor={
                    !value || value.length === 0
                        ? c.gray.dim
                        : `${c.primary}66`
                }
                bgcolor={!value || value.length === 0 ? c.gray.darker : c.gray.dark}
                borderRadius={1}
                padding="12px"
                minWidth={300}
            >
                <Autocomplete
                    onMouseEnter={checkActiveCount}
                    disabled={disable}
                    fullWidth
                    multiple
                    size="small"
                    options={
                        response.isLoading || response.error
                            ? []
                            : response.result
                    }
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    value={Array.isArray(value) ? value : [value]}
                    onChange={changeValue}
                    popupIcon={<StyledExpandMore />}
                    ChipProps={{ sx: { height: '20px' } }}
                    clearIcon={<StyledCloseIcon />}
                    renderInput={(params) => (
                        <TextField
                            variant="standard"
                            onChange={changeQuery}
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                slotProps: {
                                    input: { style: { padding: 0 } },
                                },
                            }}
                        />
                    )}
                />
            </Box>
        </Label>
    );
});
