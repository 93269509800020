import React, { FC } from 'react';
import { Box } from '@mui/material';

import FilledButton from '../buttons/FilledButton';
import OutlinedButton from '../buttons/OutlinedButton';

type FilterActionsProps = {
    onSearch: () => void;
    onCancel: () => void;
    onClear: () => void;
};

const FilterActions: FC<FilterActionsProps> = ({ onSearch, onCancel, onClear }) => {
    return (
        <Box display="flex" gap={2} padding="16px 24px">
            <FilledButton onClick={onSearch} fontSize={14} borderRadius="4px">
                Search
            </FilledButton>
            <OutlinedButton onClick={onCancel} fontSize={14} borderRadius="4px">
                Cancel
            </OutlinedButton>
            <OutlinedButton onClick={onClear} fontSize={14} borderRadius="4px">
                Clear
            </OutlinedButton>
        </Box>
    );
};

export default FilterActions;
