import {colors as c}  from '../theme';

export default function getBorderColor(
    focused: boolean,
    value: string[],
    err: boolean
) {
    if (focused) {
        return c.gray.light;
    } else if (!value[0] && !value[1]) {
        return c.gray.dim;
    } else if (err) {
        return 'red';
    } else {
        return `${c.primary}66`;
    }
}
