import React from 'react';
import { Collapse, List, ListItem, ListItemText } from '@mui/material';

import ExpandLessOrMore from './ExpandLessOrMore';
import { colors as c } from '../../common/theme';

const classes = {
    listItem: {
        overflow: 'hidden',
        '&:hover': {
            color: c.primary,
        },
    },
};

interface IFilterGroup {
    onClick: () => void;
    expanded: boolean;
    groupName: string;
    content: JSX.Element[];
}

const FilterGroup = ({
    onClick,
    expanded,
    content,
    groupName,
}: IFilterGroup) => {
    return (
        <>
            <ListItem onClick={onClick} sx={classes.listItem}>
                <ListItemText
                    primary={groupName}
                    primaryTypographyProps={{ style: { fontWeight: 600 } }}
                />
                <ExpandLessOrMore expanded={expanded} />
            </ListItem>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {content}
                </List>
            </Collapse>
        </>
    );
};

export default FilterGroup;
