import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {gap, percentage} from '../../common/utils/utils';
import Table from '@mui/material/Table';
import {Paper} from '@mui/material';
import {FilterResultSummary} from '../../types/FilterResultSummary';
import TableContainer from '@mui/material/TableContainer';
import TooltipLabel from '../parts/TooltipLabel';

interface Props {
    priceData: FilterResultSummary | null;
    hideSearchInfo?: boolean;
    showAvg: boolean;
}

export default function TableSummary({priceData, hideSearchInfo, showAvg} : Props) {

    return (
        priceData ? <>
            <TableContainer component={Paper} sx={{padding: '16px'}}>
            <Table sx={{paddingBottom: 1}}>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <TooltipLabel
                            defaultStyle
                            label={showAvg ? 'Avg CHG %' : 'Median CHG'}
                            tooltip="Average performance of the setup in the given time frame" />
                    </TableCell>
                    <TableCell>
                        <TooltipLabel
                            defaultStyle
                            label="Closed green %"
                            tooltip="Value shows on how many occasions the stock closed green in the the given time frame" />
                    </TableCell>
                    <TableCell>
                        <TooltipLabel
                            defaultStyle
                            label="Closed red %"
                            tooltip="Value shows on how many occasions the stock closed red in the given time frame" />
                    </TableCell>
                    <TableCell>
                        <TooltipLabel
                            defaultStyle
                            label={showAvg ? 'Avg green CHG %' : 'Median gren CHG'}
                            tooltip="Value shows what was the average performance of the stock when it closed green in the given time frame" />
                    </TableCell>
                    <TableCell>
                        <TooltipLabel
                            defaultStyle
                            label={showAvg ? 'Avg red CHG %' : 'Median red CHG'}
                            tooltip="Value shows what was the average performance of the stock when it closed red in the given time frame" />
                    </TableCell>
                    <TableCell>{showAvg ? 'Avg CHG (Open - HOD)' : 'Median CHG (Open - HOD)'}</TableCell>
                    <TableCell>{showAvg ? 'Avg CHG (Open - LOD)' : 'Median CHG (Open - LOD)'}</TableCell>
                    <TableCell>{showAvg ? 'Avg CHG (HOD - Close)' : 'Median CHG (HOD - Close)'}</TableCell>
                    <TableCell>{showAvg ? 'Avg CHG (LOD - Close)' : 'Median CHG (LOD - Close)'}</TableCell>
                    <TableCell>{showAvg ? 'Avg CHG (LOD - HOD)' : 'Median CHG (LOD - HOD)'}</TableCell>
                    <TableCell>{showAvg ? 'Avg CHG (HOD - LOD)' : 'Median CHG (HOD - LOD)'}</TableCell>
                    <TableCell>{showAvg ? 'Avg CHG (PM High - HOD)' : 'Median CHG (PM High - HOD)'}</TableCell>
                    <TableCell>PM High break %</TableCell>
                    <TableCell>Avg HOD time</TableCell>
                    <TableCell>Avg LOD time</TableCell>
                    {hideSearchInfo
                        ? null
                        : <>
                            <TableCell>Result count</TableCell>
                            <TableCell>Tickers processed</TableCell>
                            <TableCell>Duration</TableCell>
                        </>
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>{gap(showAvg ? priceData.avgPerformance : priceData.medianPerformance)}</TableCell>
                    <TableCell>{gap(priceData.greenRatio)}</TableCell>
                    <TableCell>{gap(priceData.redRatio ? -priceData.redRatio : priceData.redRatio)}</TableCell>
                    <TableCell>{gap(showAvg ? priceData.avgPerformanceGreen : priceData.medianPerformanceGreen)}</TableCell>
                    <TableCell>{gap(showAvg ? priceData.avgPerformanceRed : priceData.medianPerformanceRed)}</TableCell>
                    <TableCell>{gap(showAvg ? priceData.performanceOpenHigh : priceData.medianPerformanceOpenHigh)}</TableCell>
                    <TableCell>{gap(showAvg ? priceData.performanceOpenLow : priceData.medianPerformanceOpenLow)}</TableCell>
                    <TableCell>{gap(showAvg ? priceData.performanceHighClose : priceData.medianPerformanceHighClose)}</TableCell>
                    <TableCell>{gap(showAvg ? priceData.performanceLowClose : priceData.medianPerformanceLowClose)}</TableCell>
                    <TableCell>{gap(showAvg ? priceData.performanceLowHigh : priceData.medianPerformanceLowHigh)}</TableCell>
                    <TableCell>{gap(showAvg ? priceData.performanceHighLow : priceData.medianPerformanceHighLow)}</TableCell>
                    <TableCell>{gap(showAvg ? priceData.performancePmHighHigh : priceData.medianPerformancePmHighHigh)}</TableCell>
                    <TableCell>{percentage(priceData.pmHighBrakeRatio)}</TableCell>
                    <TableCell>{priceData.avgHighTime}</TableCell>
                    <TableCell>{priceData.avgLowTime}</TableCell>
                    {hideSearchInfo
                        ? null
                        : <>
                            <TableCell>{priceData ? priceData.resultCount : 0}</TableCell>
                            <TableCell>{priceData ? (priceData.processed + '/' + priceData.tickersCount) : 0}</TableCell>
                            <TableCell>{priceData ? priceData.duration + 's' : 0}</TableCell>
                        </>
                    }
                </TableRow>
            </TableBody>
            </Table></TableContainer></> : null
    );
}
