import { createTheme } from '@mui/material/styles';

export const colors = {
    primary: '#00D0B5',
    white : '#FFFFFF',
    divider: 'rgba(255, 255, 255, 0.08)',
    red: '#BB4744',
    lightBlue: '#8FD1D6',
    bg:{ light: '#131313', dark: '#0F0F0F'},
    gray:{ dim:'#323232', light: '#878787',medium:'#D0D5DD', dark: '#1C1C1C', darker:'#121515'}
    }

export const getTheme = () => {
  
    return createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: colors.primary,
            },
            secondary: { 
                main: colors.lightBlue
            },
            background:{
                default: colors.bg.dark,
                paper: colors.bg.light,
            },
        },
        typography: {
            fontFamily: 'Inter, Arial, sans-serif',
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        scrollbarColor: colors.primary,
                        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                            backgroundColor: colors.gray.dim,
                            width: '2px',
                            height: '2px',
                        },
                        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb':
                            {
                                backgroundColor: colors.primary,
                                borderRadius: '8px',
                            },
                    },
                },
            },
            MuiTypography: {
                variants: [
                    {
                        props: { variant: 'h1' },
                        style: {
                            fontSize: '1.25rem',
                            fontWeight: 500,
                        },
                    },
                    {
                        props: { variant: 'h2' },
                        style: {
                            fontSize: '1.10rem',
                            fontWeight: 500,
                        },
                    },
                    {
                        props: { variant: 'h3' },
                        style: {
                            fontSize: '1rem',
                            fontWeight: 500,
                        },
                    },
                ],
            },
            MuiList: {
                styleOverrides: {
                    root: {
                        backgroundColor: colors.bg.light,
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundImage: 'none',
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        textDecoration: 'none',
                    },
                },
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgba(17, 19, 19, 0.9)',
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        padding: '5px 12px',
                        borderBottom: `${colors.divider} solid 1px`,
                    },
                },
            },
        },
    });
};
