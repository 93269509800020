import React from 'react';
import { Button, Typography } from '@mui/material';

import { colors } from '../../common/theme';

type FilledButtonProps = {
    children: React.ReactNode;
    type?: 'button' | 'submit' | 'reset';
    fullWidth?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    fontSize?: number;
    borderRadius?: string;
};

const FilledButton = ({
    children,
    type,
    fullWidth,
    disabled,
    onClick,
    fontSize = 16,
    borderRadius = '8px',
}: FilledButtonProps) => {
    return (
        <Button
            onClick={onClick}
            disabled={disabled}
            type={type}
            variant="contained"
            sx={{
                transition: 'background-color 0.3s ease-out, border-color 0.3s ease-out',
                padding: '12px 16px',
                borderRadius,
                border: `1px solid ${colors.primary}`,
                ':hover': { backgroundColor: `${colors.primary}99` },
                ':disabled': {
                    backgroundColor: 'gray',
                    borderColor: 'gray',
                },
            }}
            fullWidth={fullWidth}
            disableRipple
        >
            <Typography fontWeight={600} fontSize={fontSize} textTransform="none" color={colors.gray.dim}>
                {children}
            </Typography>
        </Button>
    );
};

export default FilledButton;
