import React from 'react';
import { Typography } from '@mui/material';
import { FilterResultSummary } from '../../types/FilterResultSummary';

interface Props {
    priceData: FilterResultSummary;
}

export default function ProcessSummary({ priceData }: Props) {
    return priceData ? (
        <Typography whiteSpace="nowrap" fontSize="14px">
            Tickers processed: {priceData ? priceData.processed + '/' + priceData.tickersCount : 0}
        </Typography>
    ) : null;
}
