import React from 'react';
import { Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { colors } from '../../common/theme';

type Props = {
    onClick: () => void;
    direction: 'left' | 'right';
    disabled: boolean;
};

const directionIcons = {
    left: <ChevronLeftIcon />,
    right: <ChevronRightIcon />,
};

function ChartNavButton({ onClick, direction, disabled }: Props) {
    
    return (
        <Button
            onClick={onClick} 
            variant="contained"
            type="button"
            disableRipple
            style={{ backgroundColor: disabled ? 'gray':'', borderColor: disabled ? 'gray':''}}
            sx={{
                position: 'absolute',
                top: '50%',
                left: direction === 'left' ? '-60px' : 'auto',
                right: direction === 'right' ? '-60px' : 'auto',
                transition: 'background-color 0.3s ease-out, border-color 0.3s ease-out',
                padding: '10px 10px',
                minWidth: '0px',
                borderRadius: '50px',
                border: `1px solid ${colors.primary}`,
                ':hover': { backgroundColor: `${colors.primary}99`
                }}}
        >
            {directionIcons[direction]}
        </Button>
    );
}

export default ChartNavButton;
