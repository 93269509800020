import React, {useState} from 'react';
import TableContainer from '@mui/material/TableContainer';
import {
    Checkbox,
    IconButton,
    InputAdornment,
    ListItemText, 
    ListSubheader, 
    MenuItem,
    Modal,
    Select,
    TextField,
    Tooltip,
    Typography,
    SelectChangeEvent,
    Box,
    styled,
    Paper
} from '@mui/material';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

import ApiError from '../parts/ApiError';
import { formatDate, formatTime, gap, largeNumber, yesOrNo } from '../../common/utils/utils';
import EnhancedTableHead from '../parts/EnhancedTableHead';
import {NormalisedEntry} from '../../types/NormalisedEntry';
import {useFilterContext} from '../../common/context/FilterContext';
import {TradingViewChart} from '../../chart';
import {sendBody} from '../../common/utils/sendBody';
import BarchartIcon from '../icons/BarchartIcon';
import TableViewPagination from './TableViewPagination';
import {colors as c} from '../../common/theme';
import ApiService from '../../common/utils/apiService';
import { Fields } from '../../types/Fields';
import ChartNavButton from '../buttons/ChartNavButton';
import { makeTimeString } from '../../types/types';

const StyledControlPointIcon = styled(ControlPointIcon)({
    fill: c.primary,
  });

function getDefaultIds(headCells: { id: string; isDefault: boolean }[]): string[] {
    return headCells
        .filter(cell => cell.isDefault)
        .map(cell => cell.id);
}
function getDefaultSortOrder(headCells: { id: string; }[]): string[] {
    return headCells.map(cell => cell.id);
}

export default function DataTable() {
    const {dataResponse, filters, getQuery, fetch, ordering} = useFilterContext();
    const fields = ApiService<Fields>('filter/fields');
    const [chartIndex, setChartIndex] = useState< number| null>(null);
    const [focused, setFocused] = useState<number | null>(null);
    const [searchText, setSearchText] = useState<string>('');
   
    const showInsiderOwnership = fields && fields.result && fields.result.insiderOwnership;

    const handleClose = () => {
        setChartIndex(null);
    };

    const handleRequestSort = (property: string) => {
        const isDesc = ordering.orderBy === property && ordering.order === 'desc';
        ordering.set(isDesc ? 'asc' : 'desc', property);
    };

    const exclude = function (ticker: string, date: string) {
        sendBody(
            {},
            '/api/filter/exclude'  + getQuery() + '&ticker=' + ticker + '&day=' + date,
            'DELETE',
            () => fetch()
        );
    };

    const headCells = [
        { id: 'ticker', isDefault: true, format: (value :string) => value, numeric: false, label: 'Ticker' },
        { id: 'date', isDefault: true, format: (value :string) => formatDate(value), numeric: false, label: 'Date' },
        { id: 'open', isDefault: false, format: (value :string) => value, numeric: false, label: 'Open' },
        { id: 'high', isDefault: false, format: (value :string) => value, numeric: false, label: 'High' },
        { id: 'low', isDefault: false, format: (value :string) => value, numeric: false, label: 'Low' },
        { id: 'close', isDefault: false, format: (value :string) => value, numeric: false, label: 'Close' },
        { id: 'priceGap', isDefault: true, format: (value :number) => gap(value), numeric: false, label: 'Gap %'},
        { id: 'performance', isDefault: true, format: (value :number) => gap(value) ,numeric: false, label: 'Performance\n(' + formatTime(filters.outputFrom.val) + ' - ' + formatTime(filters.outputTo.val) + ')'},
        { id: 'allDayPerformance', isDefault: false, format: (value :number) => gap(value), numeric: false, label: 'Day\nperformance'},
        { id: 'performanceOpenHigh', isDefault: true, format: (value :number) => gap(value), numeric: false, label: 'Open-\nHOD%', tooltip: 'Performance Open-HOD'},
        { id: 'performanceOpenLow', isDefault: true, format: (value :number) => gap(value), numeric: false, label: 'Open-\nLOD%', tooltip: 'Performance Open-LOD'},
        { id: 'performanceHighClose', isDefault: true, format: (value :number) => gap(value), numeric: false, label: 'HOD-\nClose%', tooltip: 'Performance HOD-Close'},
        { id: 'performanceLowClose', isDefault: true, format: (value :number) => gap(value), numeric: false, label: 'LOD-\nClose%', tooltip: 'Performance LOD-Close'},
        { id: 'performanceLowHigh', isDefault: true, format: (value :number) => gap(value), numeric: false, label: 'LOD-\nHOD%', tooltip: 'Performance LOD-HOD'},
        { id: 'performanceHighLow', isDefault: true, format: (value :number) => gap(value), numeric: false, label: 'HOD-\nLOD%', tooltip: 'Performance HOD-LOD'},
        { id: 'performancePmHighHigh', isDefault: false, format: (value :number) => gap(value), numeric: false, label: 'PM High-\nHOD%', tooltip: 'Performance Pre Market High-HOD'},
        { id: 'pmGap', isDefault: false, format: (value :number) => gap(value), numeric: false, label: 'PM Gap %', tooltip: 'Pre Market Gap'},
        { id: 'pmHighGap', isDefault: false, format: (value :number) => gap(value), numeric: false, label: 'PM High Gap %', tooltip: 'Pre Market High Gap'},
        { id: 'volumeYesterday', isDefault: false, format: (value :number) => gap(value), numeric: false, label: 'Volume\nYesterday %', tooltip: 'Volume of previous day'},
        { id: 'changePreviousDay', isDefault: false, format: (value :number) => gap(value), numeric: false, label: 'Change\nPrevious Day %', tooltip: 'Change of previous day'},
        { id: 'changeFiveDays', isDefault: false, format: (value :number) => gap(value), numeric: false, label: 'Change\n5 Days %', tooltip: 'Change Five Days %'},
        { id: 'changeTenDays', isDefault: false, format: (value :number) => gap(value), numeric: false, label: 'Change\n10 Days %', tooltip: 'Change Ten Days %'},
        { id: 'changeTwentyDays', isDefault: false, format: (value :number) => gap(value), numeric: false, label: 'Change\n20 Days %', tooltip: 'Change Twenty Days %'},
        { id: 'dailyRange', isDefault: false, format: (value :string) => value, numeric: false, label: 'Daily Range', tooltip: 'Daily Range'},
        { id: 'dailyRangeAvg', isDefault: false, format: (value :string) => value, numeric: false, label: 'ADR', tooltip: 'Last 14 days Average of Daily Range'},
        { id: 'trueRange', isDefault: false, format: (value :string) => value, numeric: false, label: 'True Range', tooltip: 'True Range'},
        { id: 'trueRangeAvg', isDefault: false, format: (value :string) => value, numeric: false, label: 'ATR', tooltip: 'Last 14 days Average of True Range'},
        { id: 'pmVolume', isDefault: true, format: (value :number) => largeNumber(value), numeric: false, label: 'Volume PM', tooltip: 'Volume Pre Market (4:00 - 9:30)' },
        { id: 'relativePmVolume', isDefault: true, format: (value :number) => largeNumber(value), numeric: false, label: 'RVOL PM', tooltip: 'Relative Volume Pre Market (4:00 - 9:30), compared with past month\'s average PM volume' },
        { id: 'volume', isDefault: true, format: (value :number) => largeNumber(value), numeric: false, label: 'Volume\n(' + formatTime(filters.outputFrom.val) + ' - ' + formatTime(filters.outputTo.val) + ')' },
        { id: 'relativeVolume', isDefault: false, format: (value :number) => largeNumber(value), numeric: false, label: 'RVOL\n(' + formatTime(filters.outputFrom.val) + ' - ' + formatTime(filters.outputTo.val) + ')', tooltip: 'Compared with past month\'s average volume in same timeframe' },
        { id: 'allDayVolume', isDefault: true, format: (value :number) => largeNumber(value), numeric: false, label: 'Volume' },
        { id: 'allDayRelativeVolume', isDefault: true, format: (value :number) => largeNumber(value), numeric: false, label: 'RVOL', tooltip: 'Relative volume, compared with past months average' },
        { id: 'avgVolume', isDefault: true, format: (value :number) => largeNumber(value), numeric: false, label: 'Average\nvolume', tooltip: 'Average volume, compared with past months average' },
        { id: 'avgPmVolume', isDefault: true, format: (value :number) => largeNumber(value), numeric: false, label: 'Average\nPM volume', tooltip: 'Average pre-market (PM) volume, compared with past months average PM volume' },
        { id: 'highOfDayET', isDefault: true, format: (value :string) => value, numeric: false, label: 'High of day', tooltip: 'Time of the highest price' },
        { id: 'lowOfDayET', isDefault: true, format: (value :string) => value, numeric: false, label: 'Low of day', tooltip: 'Time of the lowest price' },
        { id: 'pmHighET', isDefault: true, format: (value :string) => value ? value : '-', numeric: false, label: 'PM high time', tooltip: 'Highest price during pre-market time' },
        { id: 'pmHighValue', isDefault: false, format: (value :string) => value ? value : '-', numeric: false, label: 'PM high', tooltip: 'Highest price during pre-market' },
        { id: 'pmLowET', isDefault: false, format: (value :string) => value ? value : '-', numeric: false, label: 'PM low time', tooltip: 'Lowest price during pre-market time' },
        { id: 'pmLowValue', isDefault: false, format: (value :string) => value ? value : '-', numeric: false, label: 'PM low', tooltip: 'Lowest price during pre-market' },
        { id: 'pmBreak', isDefault: true, format: (value :boolean) => yesOrNo(value), numeric: false, label: 'PM high break', tooltip: 'Whether PM high was exceeded during trading hours' },
        { id: 'pmFade', isDefault: true, format: (value :number) => gap(value), numeric: false, label: 'PM fade', tooltip: 'Price change percentage from PM high to Open price' },
        { id: 'dollarVolume', isDefault: true, format: (value :number) => largeNumber(value), numeric: false, label: 'Volume\nin Dollars $' },
        { id: 'insiderOwnership', isDefault: false, format: (value :string) => value != null ? value + '%' : '-', numeric: false, label: 'Insider ownership' },
        { id: 'sharesOutstanding', isDefault: true, format: (value :number) => largeNumber(value), numeric: false, label: 'Shares\noutstanding' },
        { id: 'marketCap', isDefault: true, format: (value :number) => largeNumber(value), numeric: false, label: 'Market cap' },
        { id: 'actions', isDefault: false, numeric: false, label: 'Chart', noSort: true },
        { id: 'exclude', isDefault: false, numeric: false, label: 'Exclude', noSort: true },
        // { id: 'float', numeric: false, label: 'Public float' },
    ];

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const cells: string[] = event.target.value as string[];
        setSelectedCells(cells);
        localStorage.setItem('cells', JSON.stringify(cells));
    };

    function getValueFormat(columnName: string) {
        const cell = headCells.find(cell => cell.id === columnName);
        return cell ? cell.format : (value: any) => value;
    }

    const getColumnOrder = () => {
        const defaultColOrder = getDefaultSortOrder(headCells);
        const customColumnOrder = localStorage.getItem('customColumnOrder');
        return customColumnOrder ? JSON.parse(customColumnOrder) : defaultColOrder;
    }

    function initialCells(): string[] {
        const saved = localStorage.getItem('cells');
        const defaultCells: string[] = getDefaultIds(headCells);
        if (saved === null) {
            return defaultCells;
        }
        const initialValue = JSON.parse(saved);
        return initialValue || defaultCells;
    }

    const [selectedCells, setSelectedCells] = useState<string[]>(initialCells());
    const [columnOrder, setColumnOrder] = useState<string[]>(getColumnOrder());

    const filterCells = (arr: typeof headCells) : any[] => {
        return arr.filter(e => {
            if (e.id === 'actions' || e.id === 'exclude') {
                return false;
            }
            if (e.id === 'insiderOwnership' && !showInsiderOwnership) {
                return false;
            }
            return true;
        });
    }

    const containsText = (text: string, searchText: string) => {
        return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    }

    const onNextChart = () => {
        const disabled = chartIndex === dataResponse.result.data.length -1
        if (chartIndex !== null && !disabled) {
            setChartIndex(chartIndex + 1);
        }
    }
    const onPrevChart = () => {
        const disabled = chartIndex === 0
        if (chartIndex !== null && !disabled) {
            setChartIndex(chartIndex - 1);
        }
    }

    if (dataResponse.error) {
        return <ApiError error={dataResponse.error}/>;
    }

    return <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography color={c.gray.medium} fontSize="20px" fontWeight={600}>
                    Events
                    </Typography>
                <Box display="flex" alignItems="center">
                    <TableViewPagination />
                    <Box
                        border="1px solid"
                        borderColor={c.gray.dim}
                        borderRadius={1}
                        display="flex"
                        padding="10px 16px 10px 14px"
                        alignItems="center"
                        width={189}
                        sx={{
                            ':hover': { borderColor: c.gray.light },
                        }}>
                            <Select
                                MenuProps={{
                                    autoFocus: false,
                                    slotProps: {
                                        root: {
                                            slotProps: { backdrop: { sx: { backgroundColor: 'transparent' } } },
                                        },
                                    },
                                }}
                                multiple
                                fullWidth
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    padding: 0,
                                }}
                                inputProps={{sx:{padding:0}}}
                                variant="standard"
                                disableUnderline
                                value={selectedCells}
                                onChange={handleChange}
                                onClose={() => setSearchText('')}
                                IconComponent={StyledControlPointIcon}
                                renderValue={() => 'Columns'}
                                >
                                <ListSubheader disableSticky>
                                    <TextField 
                                        size="small"
                                        autoFocus
                                        placeholder="Type filter name..."
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon/>
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onKeyDown={(e)=> {
                                            if (e.key !== 'Escape') {
                                                e.stopPropagation();
                                            }
                                        }}
                                        />
                                </ListSubheader>
                                {filterCells(headCells).map((cell) => (
                                    containsText(cell.label, searchText) ? <MenuItem key={cell.id} value={cell.id}>
                                        <Checkbox checked={selectedCells.indexOf(cell.id) > -1} />
                                        <ListItemText primary={cell.label} />
                                    </MenuItem> : null
                                ))}
                            </Select>
                    </Box>
                </Box>
            </Box>
        <TableContainer component={Paper}>
            <Table>
                <EnhancedTableHead enabledCells={selectedCells} headCells={headCells} order={ordering.order} orderBy={ordering.orderBy} onRequestSort={handleRequestSort} columnOrder={columnOrder} setColumnOrder={setColumnOrder} fields={fields} />
                <TableBody>
                    {dataResponse.result && dataResponse.result.data
                        ? dataResponse.result.data
                            .map((row, i) => (
                                <TableRow hover selected={focused === i} key={i}
                                    onClick={() => setFocused(i)}
                                    >
                                        {columnOrder.map((columnName: string) => selectedCells.includes(columnName) && 
                                            <TableCell key={columnName} align="left">
                                                {getValueFormat(columnName)?.(row[columnName as keyof NormalisedEntry] as never)}
                                            </TableCell>
                                        )}
                                    <TableCell align="center">
                                        <Tooltip title="View chart">
                                            <IconButton onClick={() => setChartIndex(i)} size="small">
                                                <BarchartIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Exclude from statistics">
                                            <IconButton onClick={() => exclude(row.ticker, row.date)} size="small">
                                                <CloseIcon color="primary"/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    {/*<TableCell align="left">{largeNumber(row.publicFloat)}</TableCell>*/}
                                </TableRow>
                            )) : null}
                </TableBody>
            </Table>
        </TableContainer>
            <Box display="flex" justifyContent="flex-end">
                <TableViewPagination />
            </Box>
        { chartIndex !== null && <Modal
            open={chartIndex !== null}
            onClose={handleClose}
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            <Box sx={{position: 'absolute', width: 'calc(100vw - 160px)', zIndex:40}}   >
                <ChartNavButton onClick={onPrevChart} direction="left" disabled={chartIndex === 0}/>
                <TradingViewChart
                    key={chartIndex}
                    theme="Dark"
                    symbol={dataResponse.result.data[chartIndex].ticker}
                    date={dataResponse.result.data[chartIndex].date}
                    timeFrom={makeTimeString('04:00')}
                    timeTo={makeTimeString('20:00')}
                    intradayRange={dataResponse.result.data[chartIndex].intradayRange}
                    />
                <ChartNavButton onClick={onNextChart} direction="right" disabled={chartIndex === dataResponse.result.data.length -1}/>
            </Box>
        </Modal>}
    </>;
}
