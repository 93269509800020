import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
    Box,
    Dialog,
    IconButton,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';

import { StaticUrls } from '../../staticUrls';
import { sendBody } from '../../common/utils/sendBody';
import { useFilterContext } from '../../common/context/FilterContext';
import { FilterArray } from './FilterArray';
import { bodyToDto } from '../../common/context/dtoUtil';
import FilledButton from '../buttons/FilledButton';
import {colors as c} from '../../common/theme';

interface Props {
    dialogOpen: boolean;
    setDialogOpen: (v: boolean) => void;
    updateSetups: () => void;
    tabs: FilterArray[];
    saveCustomFilters: (setupName: string) => void;
}

export default function CreateSetup({
    dialogOpen,
    setDialogOpen,
    updateSetups,
    tabs,
    saveCustomFilters,
}: Props) {
    const { getQuery } = useFilterContext();

    const [open, setOpen] = useState(false);
    const [err, setErr] = useState(undefined);
    const [name, setName] = useState<string>('');

    function createSetupCallback() {
        return () => {
            setDialogOpen(false);
            setOpen(true);
            updateSetups();
            saveCustomFilters(name);
        };
    }

    const createSetup = function () {
        sendBody(
            {
                name: name,
                multidayFilters: JSON.stringify(bodyToDto(tabs)),
            },
            '/api/setup' + getQuery(),
            'POST',
            createSetupCallback(),
            setErr
        );
    };

    const handleClose = (
        event: React.SyntheticEvent | React.MouseEvent,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleCloseDialog = () => {
        setErr(undefined);
        setName('');
        setDialogOpen(false);
    };

    return (
        <>
            <Dialog
                fullWidth
                open={dialogOpen}
                onClose={handleCloseDialog}
                PaperProps={{
                    sx: { border: `1px solid ${c.divider}` },
                }}
            >
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderBottom={`1px solid ${c.divider}`}
                    paddingLeft="16px"
                >
                    <Typography fontWeight={600}>Create setup</Typography>
                    <IconButton
                        color="primary"
                        onClick={handleCloseDialog}
                        aria-label="close"
                        sx={{ padding: '11px', marginRight: '5px' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box padding="24px 16px">
                    {err && (
                        <Alert
                            severity="error"
                            variant="outlined"
                            sx={{ marginY: '12px' }}
                        >
                            {err}
                        </Alert>
                    )}

                    <TextField
                        autoComplete="off"
                        name="name"
                        onChange={(event) => setName(event.target.value)}
                        defaultValue={name}
                        variant="outlined"
                        placeholder="Name"
                        fullWidth
                        inputProps={{
                            sx: {
                                padding: '11px 14px',
                                fontSize: '14px',
                                lineHeight: '24px',
                            },
                        }}
                    />
                </Box>
                <Box padding="0 16px 27px">
                    <FilledButton
                        disabled={!name}
                        type="button"
                        onClick={createSetup}
                        fontSize={14}
                        borderRadius="4px"
                    >
                        Add
                    </FilledButton>
                </Box>
            </Dialog>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose as any}
                message="Setup saved"
                action={
                    <>
                        <Button
                            color="secondary"
                            size="small"
                            component={Link}
                            to={StaticUrls.MY_SETUPS}
                        >
                            My setups
                        </Button>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>
                }
            />
        </>
    );
}
