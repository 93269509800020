import React from 'react';
import {useAppContext} from '../common/context/AppContext';

interface Props {
    children: any[];
}

export default function Authenticated({children} : Props) {
    const { global: { username } = {} } = useAppContext();
    if (!username) {
        return <></>;
    }
    return (
        <>
            {children}
        </>
    );
}
