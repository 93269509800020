import React, { memo } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Box, Typography } from '@mui/material';

import CustomTimePicker from './CustomTimePicker';
import Label from '../inputs/Label';
import { colors as c } from '../../common/theme';

export interface FromToTimeProps {
    dateTo: any;
    setDateTo: (v: any) => void;
    dateFrom: any;
    setDateFrom: (v: any) => void;
    label: string;
    tooltip?: string;
    checkActiveCount?: () => void;
    disableNulls?: boolean;
    deleteCallback?: () => void;
}

export default memo(function FromToTime({
    dateTo,
    setDateTo,
    dateFrom,
    setDateFrom,
    label,
    tooltip,
    checkActiveCount,
    disableNulls,
    deleteCallback,
}: FromToTimeProps) {
    const handleDateToChange = (date: any) => {
        setDateTo(date);
    };

    const handleDateFromChange = (date: any) => {
        setDateFrom(date);
    };

    const clearValuesAndRemoveFilter = () => {
        if (deleteCallback) {
            setDateTo(null);
            setDateFrom(null);
            deleteCallback();
        }
    };
    const disable = disableNulls && !dateFrom && !dateTo;
    return (
        <Label
            label={label}
            tooltip={tooltip}
            deleteCallback={
                deleteCallback ? clearValuesAndRemoveFilter : undefined
            }
        >
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Box
                    display="flex"
                    border="1px solid"
                    sx={{
                        transition:
                            'border-color 0.3s ease-out, background-color 0.3s ease-out',
                    }}
                    borderColor={
                        !dateFrom && !dateTo
                            ? c.gray.dim
                            : `${c.primary}66`
                    }
                    bgcolor={!dateFrom && !dateTo ? c.gray.darker : c.gray.dark}
                    borderRadius={1}
                    padding="12px"
                    minWidth={300}
                    gap={2}
                >
                    <CustomTimePicker
                        onMouseEnter={checkActiveCount}
                        disabled={disable}
                        date={dateFrom}
                        handleChange={handleDateFromChange}
                        label="From"
                    />
                    <Typography color={c.gray.dim}>-</Typography>
                    <CustomTimePicker
                        onMouseEnter={checkActiveCount}
                        disabled={disable}
                        date={dateTo}
                        handleChange={handleDateToChange}
                        label="To"
                    />
                </Box>
            </LocalizationProvider>
        </Label>
    );
});
