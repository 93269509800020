import React from 'react';
import { styled } from '@mui/system';
import { Switch, SwitchProps } from '@mui/material';
import { colors as c } from '../../common/theme';

const CustomSwitch = styled((props: SwitchProps) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))({
    width: 56,
    height: 27,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(29px)',
            color: c.primary,
            '& + .MuiSwitch-track': {
                backgroundColor: c.gray.dim,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            opacity: 0.5,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 27,
        height: 27,
    },
    '& .MuiSwitch-track': {
        borderRadius: 27 / 2,
        backgroundColor: c.gray.dim,
        opacity: 1,
        transition: 'background-color 300ms',
    },
});

export default CustomSwitch;
