import {Box, Typography} from '@mui/material';
import CustomSwitch from '../inputs/CustomSwitch';
import {colors as c} from '../../common/theme';
import React, {ReactElement} from 'react';

interface ToggleProps {
    checked: boolean;
    handleSwitch: () => void;
    leftOption: string;
    rightOption: string;
    style?: any;
    justifyRight?: boolean;
    icon?: ReactElement;
}

export default function Toggle({checked, handleSwitch, leftOption, rightOption, style, justifyRight, icon} : ToggleProps) {
    return <Box style={style} display="flex" gap="10px" alignItems="center" justifyContent={justifyRight ? 'right' : 'left'} marginLeft="24px" marginRight="24px">
        <Typography fontSize="14px">{leftOption}</Typography>
        <CustomSwitch checked={checked} onChange={handleSwitch}/>
        <Typography fontSize="14px" color={checked ? c.lightBlue : c.white}>{rightOption}</Typography>
        {icon ? icon : null}
    </Box>;
}
