/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import TableContainer from '@mui/material/TableContainer';
import {Box, Paper} from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import LinearProgress from '@mui/material/LinearProgress';
import ApiError from '../parts/ApiError';
import moment from 'moment-timezone'
import PriceFilterToolbar from '../parts/PriceFilterToolbar';
import {formatDate, formatQueryDate, gap, largeNumber} from '../../common/utils/utils';
import apiService from '../../common/utils/apiService';

const classes = {
    row: {
        '& td, th': {
            paddingTop: 0,
            paddingBottom: 0,
        }
    },
    spacer: {
        paddingTop: 1
    }
};

export default function PriceDataTable({ticker}) {

    const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'month'));
    const [dateTo, setDateTo] = useState(moment());
    const [period, setPeriod] = useState('ONE_DAY');

    const handleDateToChange = date => {
        setDateTo(date.valueOf());
    };

    const handleDateFromChange = date => {
        setDateFrom(date.valueOf());
    };

    const handleChangePeriod = (event) => {
        setPeriod(event.target.value);
    };

    const response = apiService(
        'price/' + ticker
        + (dateFrom ? '?from=' + formatQueryDate(dateFrom) : '')
        + (dateTo ? '&to=' + formatQueryDate(dateTo) : '')
        + (period ? '&period=' + period : '')
    );

    if (response.error) {
        return <ApiError error={response.error}/>;
    }

    if (response.isLoading) {
        return <LinearProgress />;
    }

    return (
        <div>
            <PriceFilterToolbar
                dateTo={dateTo} handleDateToChange={handleDateToChange}
                dateFrom={dateFrom} handleDateFromChange={handleDateFromChange}
                period={period} handleChangePeriod={handleChangePeriod}
            />
            <Box sx={classes.spacer}/>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell align="right">Gap</TableCell>
                            <TableCell align="right">Open</TableCell>
                            <TableCell align="right">Close</TableCell>
                            <TableCell align="right">High</TableCell>
                            <TableCell align="right">Low</TableCell>
                            <TableCell align="right">Volume</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {response.result.map((row, i) => (
                            <TableRow key={i} sx={classes.row}>
                                <TableCell component="th" scope="row">
                                    {formatDate(row.date)}
                                </TableCell>
                                <TableCell align="right">{gap(row.priceGap)}</TableCell>
                                <TableCell align="right">{row.open}</TableCell>
                                <TableCell align="right">{row.close}</TableCell>
                                <TableCell align="right">{row.high}</TableCell>
                                <TableCell align="right">{row.low}</TableCell>
                                <TableCell align="right">{largeNumber(row.volume)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
