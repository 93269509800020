import React, { useCallback, useState } from 'react';
import {
    Box,
    Checkbox,
    Dialog,
    DialogContent,
    IconButton,
    ListItemText,
    MenuItem,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import FilterSearch from './FilterSearch';
import FilterGroup from './FilterGroup';
import { FilterArray } from '../filter/FilterArray';
import {
    groupedDayTFilters,
    groupedDayZeroFilters,
    groupedRangeFilters,
} from '../../constants/groupedFilters';
import { colors as c } from '../../common/theme';

interface IAddFilterDialog {
    dialogOpen: boolean;
    setDialogOpen: (v: boolean) => void;
    customFilters: string[][];
    handleAddFilter: (filter: string) => void;
    handleAddMultipleFilters: (filters: string[]) => void;
    selectedTab: number;
    tabs: FilterArray[];
}
interface IFilterListComponent {
    group: 'all' | 'descriptive' | 'technical' | 'volume';
    customFilters: string[][];
}

const AddFilterDialog = ({
    dialogOpen,
    setDialogOpen,
    customFilters,
    handleAddFilter,
    handleAddMultipleFilters,
    selectedTab,
    tabs,
}: IAddFilterDialog) => {
    const [allOpen, setAllOpen] = useState(false);
    const [descriptiveOpen, setDescriptiveOpen] = useState(false);
    const [technicalsOpen, setTechnicalsOpen] = useState(false);
    const [volumeOpen, setVolumeOpen] = useState(false);

    const FilterListComponent = ({
        group,
        customFilters,
    }: IFilterListComponent) => {
        const getFilters = (filterArray: string[]) => {
            return filterArray.map((filter: string) => (
                <MenuItem
                    key={filter}
                    value={filter}
                    sx={{
                        '&:hover': {
                            color: c.primary,
                        },
                    }}
                    onClick={() => handleAddFilter(filter)}
                >
                    <Checkbox
                        checked={
                            (customFilters[selectedTab] ?? []).includes(
                                filter
                            ) ?? false
                        }
                        color="primary"
                        size="small"
                    />
                    <ListItemText primary={filter} />
                </MenuItem>
            ));
        };

        if (tabs && tabs[selectedTab - 1]?.type === 'range') {
            if (group === 'descriptive') return null;
            return getFilters(groupedRangeFilters[group]);
        } else if (tabs && tabs[selectedTab - 1]?.type === 'day') {
            if (group === 'descriptive') return null;
            return getFilters(groupedDayTFilters[group]);
        }

        return getFilters(groupedDayZeroFilters[group]);
    };

    const searchOptions = () => {
        if (tabs && tabs[selectedTab - 1]?.type === 'range') {
            return groupedRangeFilters.all;
        } else if (tabs && tabs[selectedTab - 1]?.type === 'day') {
            return groupedDayTFilters.all;
        }
        return groupedDayZeroFilters.all;
    };
    const all = FilterListComponent({ group: 'all', customFilters });
    const descriptive = FilterListComponent({
        group: 'descriptive',
        customFilters,
    });
    const technical = FilterListComponent({
        group: 'technical',
        customFilters,
    });
    const volume = FilterListComponent({ group: 'volume', customFilters });

    const onFilterSearchSelectMultiple = (filters: string[]) => {
        handleAddMultipleFilters(filters);
    };

    const onCloseHandler = useCallback(() => {
        setAllOpen(false);
        setDescriptiveOpen(false);
        setTechnicalsOpen(false);
        setVolumeOpen(false);
        setDialogOpen(false);
    }, [setDialogOpen]);

    return (
        <Dialog
            fullWidth
            onClose={onCloseHandler}
            open={dialogOpen}
            PaperProps={{
                sx: {
                    border: `1px solid ${c.divider}`,
                    maxWidth: '315px',
                    overflow: 'hidden',
                },
            }}
        >
            <DialogContent sx={{ padding: 0 }}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    borderBottom={`1px solid ${c.divider}`}
                    paddingLeft="16px"
                >
                    <Typography fontWeight={600}>Add filters</Typography>
                    <IconButton
                        color="primary"
                        onClick={onCloseHandler}
                        aria-label="close"
                        sx={{ padding: '11px', marginRight: '5px' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <FilterSearch
                    options={searchOptions()}
                    selectedFilters={customFilters[selectedTab] ?? []}
                    onChange={onFilterSearchSelectMultiple}
                    onClose={onCloseHandler}
                />
                {!!all && !!all.length && (
                    <FilterGroup
                        onClick={() => setAllOpen(!allOpen)}
                        expanded={allOpen}
                        content={all}
                        groupName="All"
                    />
                )}
                {!!descriptive && !!descriptive.length && (
                    <FilterGroup
                        onClick={() => setDescriptiveOpen(!descriptiveOpen)}
                        expanded={descriptiveOpen}
                        content={descriptive}
                        groupName="Descriptive"
                    />
                )}
                {!!technical && !!technical.length && (
                    <FilterGroup
                        onClick={() => setTechnicalsOpen(!technicalsOpen)}
                        expanded={technicalsOpen}
                        content={technical}
                        groupName="Technical"
                    />
                )}

                {!!volume && !!volume.length && (
                    <FilterGroup
                        onClick={() => setVolumeOpen(!volumeOpen)}
                        expanded={volumeOpen}
                        content={volume}
                        groupName="Volume"
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default AddFilterDialog;
