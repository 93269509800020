import {AbstractStateHoldingFilter} from './AbstractStateHoldingFilter';

export class StateHoldingAutocompleteFilter extends AbstractStateHoldingFilter {
    private _value: any;
    private _path: string;
    private _setValue: (v: any) => void;
    constructor(label: string, tooltip: string, type: string, path: any) {
        super('auto', label, tooltip, type);
        this._value = [];
        this._setValue = (v: any) => this._value = v
        this._path = path;
    }

    static of(label: string, tooltip: string, type: string, path: string): StateHoldingAutocompleteFilter {
        return new StateHoldingAutocompleteFilter(label, tooltip, type, path);
    }

    static from(label: string, tooltip: string, type: string, path: any, value: string): StateHoldingAutocompleteFilter {
        const entry = new StateHoldingAutocompleteFilter(label, tooltip, type, path);
        entry._value = value;
        return entry;
    }

    get value(): any {
        return this._value;
    }

    get path(): string {
        return this._path;
    }

    get setValue(): (v: any) => void {
        return this._setValue;
    }
}
