import {Box, Grid} from '@mui/material';
import TypedFilterInput from './TypedFilterInput';
import React from 'react';
import {IntraFilters} from './FilterArray';
import {noOp} from '../../common/utils/utils';
import {AbstractFilter} from '../../types/AbstractFilter';
import Slider from '@mui/material/Slider';
import {StateHoldingFilter} from '../../types/StateHoldingFilter';
import Label from '../inputs/Label';

interface IntraRangeFiltersProps {
    intraRanges: IntraFilters;
    isNewLayout: boolean;
}

function FilterItem({filter, isNewLayout} : { filter: AbstractFilter,  isNewLayout: boolean}) {
    return (<Grid
        item
        xs={isNewLayout ? 12 : 12}
        sm={isNewLayout ? 12 : 6}
        md={isNewLayout ? 12 : 4}
        lg={isNewLayout ? 6 : 3}
        xl={isNewLayout ? 6 : 2}
    >
        <TypedFilterInput
            filter={filter}
            checkActiveCount={noOp}
        />
    </Grid>);
}

const marks = [
    {
        value: 0,
        label: '5m'
    },
    {
        value: 1,
        label: '10m'
    },
    {
        value: 2,
        label: '15m'
    },
    {
        value: 3,
        label: '20m'
    },
    {
        value: 4,
        label: '30m'
    },
    {
        value: 5,
        label: '1h'
    },
    {
        value: 6,
        label: '2h'
    },
    {
        value: 7,
        label: '3h'
    },
    {
        value: 8,
        label: '4h'
    },
];

function findLabel(label: string): number {
    for (let i = 0; i < marks.length; i++) {
        if (marks[i].label === label) {
            return i;
        }
    }
    return -1;
}

function rangeFromLabels(labels: string[]): number[] {
    if (!Array.isArray(labels) || labels.length === 0) {
        return [0, 8]
    }
    let min = 8;
    let max = 0;
    for (let i = 0; i < labels.length; i++) {
        const labelIdx = findLabel(labels[i]);
        if (labelIdx < 0) {
            continue;
        }
        if (min > labelIdx) {
            min = labelIdx;
        }
        if (max < labelIdx) {
            max = labelIdx;
        }
    }
    return [min, max]
}

function SliderInput({filter, isNewLayout} : { filter: StateHoldingFilter,  isNewLayout: boolean}) {
    const [value, setValue] = React.useState<number[]>(rangeFromLabels(filter.value));
    const handleChange = (event: Event, newValue: number | number[]) => {
        if (!Array.isArray(newValue)) {
            return;
        }
        setValue(newValue);

        const labels = [];
        for (let i = newValue[0]; i <= newValue[1]; i++) {
            labels.push(marks[i].label);
        }
        filter.setValue(labels);
    };

    function valuetext(value: number) {
        return marks[value].label;
    }

    return (<Grid
        item
        xs={isNewLayout ? 12 : 12}
        sm={isNewLayout ? 12 : 6}
        md={isNewLayout ? 12 : 4}
        lg={isNewLayout ? 6 : 3}
        xl={isNewLayout ? 6 : 2}
    >
        <Label
            label={filter.label}
            tooltip={filter.tooltip}
        >
            <Box
                display="flex"
                padding="12px"
                minWidth={300}
                gap={2}>
                <Slider
                    getAriaLabel={() => filter.label}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    disableSwap
                    step={null}
                    min={0}
                    max={8}
                    marks={marks}
                    valueLabelFormat={valuetext}
                />
            </Box>
        </Label>
    </Grid>);
}

export default function IntraRangeFilters({intraRanges, isNewLayout} : IntraRangeFiltersProps) {
    return (<>
        <SliderInput filter={intraRanges.moveDuration} isNewLayout={isNewLayout}/>
        <FilterItem filter={intraRanges.time} isNewLayout={isNewLayout}/>
        <FilterItem filter={intraRanges.performance} isNewLayout={isNewLayout}/>
        <FilterItem filter={intraRanges.volume} isNewLayout={isNewLayout}/>
    </>);
}
