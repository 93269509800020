import React, { useState } from 'react';
import {FormControl, Grid, IconButton, InputAdornment, ListSubheader, MenuItem, Select, TextField, SelectChangeEvent, styled} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SearchIcon from '@mui/icons-material/Search'
import { ExpandMore } from '@mui/icons-material';

import {formatTime} from '../../common/utils/utils';
import {useFilterContext} from '../../common/context/FilterContext';
import DividerFullWidth from '../parts/DividerFullWidth';
import {colors as c} from '../../common/theme';

const StyledExpandMore = styled(ExpandMore)({
    fill: c.primary,
  });

export default function SortSelect() {
    const {filters, ordering} = useFilterContext();
    const [searchText, setSearchText] = useState<string>('');

    const fields = [
        { id: 'ticker', label: 'Ticker' },
        { id: 'date', label: 'Date' },
        { id: 'priceGap', label: 'Premarket gap %'},
        { id: 'performance', label: 'Performance (' + formatTime(filters.outputFrom.val) + ' - ' + formatTime(filters.outputTo.val) + ')'},
        { id: 'allDayPerformance', label: 'Day performance'},
        { id: 'performanceOpenHigh', label: 'Open - HOD%'},
        { id: 'performanceOpenLow', label: 'Open - LOD%'},
        { id: 'performanceHighClose', label: 'HOD - Close%'},
        { id: 'performanceLowClose', label: 'LOD - Close%'},
        { id: 'performanceLowHigh', label: 'LOD - HOD%'},
        { id: 'performanceHighLow', label: 'HOD - LOD%'},
        { id: 'performancePmHighHigh', label: 'PM High - HOD%'},
        { id: 'pmGap', label: 'PM Gap %'},
        { id: 'pmHighGap', label: 'PM High Gap %'},
        { id: 'volumeYesterday', label: 'Volume Yesterday %'},
        { id: 'changePreviousDay', label: 'Change Previous Day %'},
        { id: 'changeFiveDays', label: 'Change Five Days %'},
        { id: 'changeTenDays', label: 'Change Ten Days %'},
        { id: 'changeTwentyDays', label: 'Change Twenty Days %'},
        { id: 'dailyRange', label: 'Daily Range'},
        { id: 'dailyRangeAvg', label: 'ADR'},
        { id: 'trueRange', label: 'True Range'},
        { id: 'trueRangeAvg', label: 'ATR'},
        { id: 'pmVolume', label: 'Volume PM' },
        { id: 'relativePmVolume', label: 'RVOL PM' },
        { id: 'volume', label: 'Volume (' + formatTime(filters.outputFrom.val) + ' - ' + formatTime(filters.outputTo.val) + ')' },
        { id: 'relativeVolume', label: 'RVOL (' + formatTime(filters.outputFrom.val) + ' - ' + formatTime(filters.outputTo.val) + ')' },
        { id: 'allDayVolume', label: 'Volume day' },
        { id: 'allDayRelativeVolume', label: 'RVOL day' },
        { id: 'avgVolume', label: 'Average volume' },
        { id: 'avgPmVolume', label: 'Average PM volume' },
        { id: 'highOfDayET', label: 'High of day' },
        { id: 'lowOfDayET', label: 'Low of day' },
        { id: 'sharesOutstanding', label: 'Shares outstanding' },
        { id: 'marketCap', label: 'Market cap' },
    ];

    const handleChangeOrderBy = (event: SelectChangeEvent<string>) => {
        ordering.set(ordering.order, event.target.value as string, true);
    };

    const handleChangeOrder = () => {
        const newOrder = ordering.order === 'asc' ? 'desc' : 'asc';
        ordering.set(newOrder, ordering.orderBy, true);
    };
    const containsText = (text: string, searchText: string) => {
        return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    }

    return (
        <Grid container>
            <Grid item>
                <FormControl sx={{ border: `1px solid ${c.gray.dim}`, borderRadius: 1, padding: '6px 0 6px 14px',':hover': { borderColor: c.gray.light }, }}>
                    <Select
                        IconComponent={StyledExpandMore}
                        value={ordering.orderBy}
                        onChange={handleChangeOrderBy}
                        MenuProps={{
                            autoFocus: false,
                            slotProps: {
                                root: {
                                    slotProps: { backdrop: { sx: { backgroundColor: 'transparent' } } },
                                },
                            },
                        }}
                        onClose={()=> setSearchText('')}
                        variant="standard"
                        disableUnderline
                        sx={{
                            fontSize: '14px',
                            fontWeight: 600,
                            marginRight: '14px',
                            padding: 0,
                            width: 159,
                        }}
                    >
                    <ListSubheader disableSticky sx={{padding:0, lineHeight:'24px'}}>
                        <TextField 
                         size="small"
                         autoFocus
                         placeholder="Type filter name..."
                         fullWidth
                         variant="standard"
                         sx={{marginBottom: 1, marginLeft: 1}}
                         InputProps={{
                            disableUnderline: true,
                         startAdornment: (
                             <InputAdornment position="start">
                                <SearchIcon color="primary"/>
                             </InputAdornment>)}}
                         onChange={(e) => setSearchText(e.target.value)}
                         onKeyDown={(e)=> {
                            if (e.key !== 'Escape') {
                                e.stopPropagation();
                            }}}/>
                            <DividerFullWidth />
                    </ListSubheader>
                        <MenuItem value="last" disabled>
                            Sort by
                        </MenuItem>
                        {fields
                            .filter(field => searchText !== '' ? containsText(field.label, searchText) : true)
                            .map((field, i) => (
                                <MenuItem key={i} value={field.id}>{field.label}</MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <IconButton size="small" onClick={handleChangeOrder}>
                    {ordering.order === 'asc' ? <ArrowUpwardIcon color="primary"/> : <ArrowDownwardIcon color="primary"/>}
                </IconButton>
            </Grid>
        </Grid>
    );
}
