import {ApiResponse} from '../../types/ApiResponse';
import {FilterResultSummary} from '../../types/FilterResultSummary';
import {FilterArray, IntraFilters} from '../../component/filter/FilterArray';

export interface MultiParamsBody {
    filters: FilterArray[];
    intra: IntraFilters;
}

export function makeBody(filters: FilterArray[], intra: IntraFilters): MultiParamsBody {
    return {filters, intra}
}

export interface FilterContextType {
    response: ApiResponse<FilterResultSummary>;
    dataResponse: ApiResponse<FilterResultSummary>;
    filters: Filters;
    getQuery: () => string;
    search: (body?: MultiParamsBody) => void;
    fetch: () => void;
    finish: () => void;
    clear: () => void;
    pageAndRows: PageAndRows;
    ordering: Ordering;
    isNewLayout: boolean;
    setIsNewLayout: (b: boolean) => void;
}

export interface Filters {
    dateFrom: Filter<any>;
    dateTo: Filter<any>;
    insiderOwnership: Filter<any>;
    priceRange: Filter<any>;
    rvol: Filter<any>;
    pmRvol: Filter<any>;
    avgVol: Filter<any>;
    avgPmVol: Filter<any>;
    performance: Filter<any>;
    performanceOpenHigh: Filter<any>;
    performanceOpenLow: Filter<any>;
    performanceHighClose: Filter<any>;
    performanceLowClose: Filter<any>;
    performanceLowHigh: Filter<any>;
    performanceHighLow: Filter<any>;
    performancePmHighHigh: Filter<any>;
    pmGap: Filter<any>;
    pmHighGap: Filter<any>;
    volumeYesterday: Filter<any>;
    changePreviousDay: Filter<any>;
    changeFiveDays: Filter<any>;
    changeTenDays: Filter<any>;
    changeTwentyDays: Filter<any>;
    dailyRange: Filter<any>;
    dailyRangeAvg: Filter<any>;
    trueRange: Filter<any>;
    trueRangeAvg: Filter<any>;
    gap: Filter<any>;
    marketCap: Filter<any>;
    shares: Filter<any>;
    volume: Filter<any>;
    pmVolume: Filter<any>;
    pmBreak: Filter<any>;
    pmFade: Filter<any>;
    tickers: Filter<any>;
    sectors: Filter<any>;
    industries: Filter<any>;
    countries: Filter<any>;
    outputFrom: Filter<any>;
    outputTo: Filter<any>;
    dollarVolume: Filter<any>;
    hodFrom: Filter<any>;
    hodTo: Filter<any>;
    lodFrom: Filter<any>;
    lodTo: Filter<any>;
    pmHighFrom: Filter<any>;
    pmHighTo: Filter<any>;
    pmLowFrom: Filter<any>;
    pmLowTo: Filter<any>;
}

export enum FilterType {
    DATE,
    TIME,
    NUMERIC,
    SELECT
}

export class Filter<T> {
    public val: T;
    public set: (v: T) => void;
    public queryKey: string;
    public type: FilterType;

    constructor(val: T, set: (v: T) => void, queryKey: string, type: FilterType) {
        this.val = val;
        this.set = set;
        this.queryKey = queryKey;
        this.type = type;
    }
}

interface PageAndRows {
    page: any;
    rowsPerPage: any;
    set: (p: any, r?: any, invalidate?: boolean) => void;
}

interface Ordering {
    order: any;
    orderBy: string;
    set: (o: any, ob: any, invalidate?: boolean) => void;
}
