import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { TableCell } from '@mui/material';

import { Order } from '../../types/Order';
import { NormalisedEntry } from '../../types/NormalisedEntry';
import SortableTableHeadCell from './SortableTableHeadCell';
import { Fields } from '../../types/Fields';
import { ApiResponse } from '../../types/ApiResponse';

interface Props {
    onRequestSort: any;
    order: Order;
    orderBy: string;
    headCells: any[];
    enabledCells: string[];
    columnOrder: string[];
    setColumnOrder: React.Dispatch<React.SetStateAction<string[]>>;
    fields: ApiResponse<Fields>;
}

export default function EnhancedTableHead({
    onRequestSort,
    order,
    orderBy,
    headCells,
    enabledCells,
    columnOrder,
    setColumnOrder,
    fields
}: Props) {
    const showInsiderOwnership = fields && fields.result && fields.result.insiderOwnership;
    const containsCell = (name: string): boolean => {
        return enabledCells.indexOf(name) >= 0;
    };

    const createSortHandler =
        (property: keyof NormalisedEntry) => {
            onRequestSort(property);
        };

    function getHeadCellById(id: string) {
        const cell = headCells.find((cell) => cell.id === id);
        if (!cell || (cell.id === 'insiderOwnership' && !showInsiderOwnership)) {
            return null;
        }
        return cell;
    }
    const sensors = useSensors(
        useSensor(PointerSensor,{activationConstraint: { distance: 10 }}),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragEnd(event: { active: any; over: any }) {
        const { active, over } = event;
        if (active.id !== over.id) {
            setColumnOrder((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);
                const newColumnOrder = arrayMove(items, oldIndex, newIndex);
                localStorage.setItem('customColumnOrder', JSON.stringify(newColumnOrder));
                return newColumnOrder;
            });
        }
    }

    return (
        <TableHead>
            <TableRow>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
                    accessibility={{ container: document.body }}
                >
                    <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                        {columnOrder.map((head, i) => {
                            const cell = getHeadCellById(head);
                            if (!cell) return null;
                            if (!containsCell(cell.id)) return null;
                            return (
                                <SortableTableHeadCell
                                    key={cell.id}
                                    order={order}
                                    orderBy={orderBy}
                                    createSortHandler={createSortHandler}
                                    cell={cell}
                                />
                            );
                        })}
                        <TableCell>
                            <span>Chart</span>
                        </TableCell>
                        <TableCell>
                            <span>Exclude</span>
                        </TableCell>
                    </SortableContext>
                </DndContext>
            </TableRow>
        </TableHead>
    );
}
