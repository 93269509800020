import React, { useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Input,
    InputAdornment,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import DividerFullWidth from './DividerFullWidth';
import { colors as c } from '../../common/theme';

interface IFilterSearch {
    options: string[];
    selectedFilters?: string[];
    onChange: (value: string[]) => void;
    onClose: () => void;
}

const buttonSecondary = {
    width: '100%',
    padding: '8px 0',
    textTransform: 'none',
    fontWeight: 'bold',
    border: '2px solid rgba(7, 117, 172, 0.5)',
    '&:hover': {
        border: '2px solid #0775ac',
    },
};

const buttonPrimary = {
    width: '100%',
    textTransform: 'none',
    padding: '8px 0',
    fontWeight: 'bold',
    border: '2px solid rgba(10, 205, 182, 0.5)',
    '&:hover': {
        border: `2px solid ${c.primary}`,
    },
};

const FilterSearch = ({ options, selectedFilters, onChange, onClose }: IFilterSearch) => {
    const [searchText, setSearchText] = useState<string>('');
    const [selected, setSelected] = useState<string[]>(selectedFilters ?? []);

    const isAllSelected = options.length === selected.length;

    const containsText = (text: string, searchText: string) => {
        return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    };

    const onSelectAll = (e: React.ChangeEvent<any>) => {
        e.stopPropagation();
        const newOptions = options.filter((option) => !selected.includes(option));
        setSelected((prev) => [...prev, ...newOptions]);
    };
    const onUnSelectAll = (e: React.ChangeEvent<any>) => {
        e.stopPropagation();
        setSelected([]);
    };
    const onCancel = (e: React.ChangeEvent<any>) => {
        e.stopPropagation();
        onClose();
    };
    const onApply = (e: React.ChangeEvent<any>) => {
        e.stopPropagation();
        const newOptions = options.filter((option) => selected.includes(option));
        onChange(newOptions);
        onClose();
    };

    return (
        <Select
            multiple
            displayEmpty
            variant="standard"
            disableUnderline
            MenuProps={{
                autoFocus: false,
                slotProps: {
                    root: {
                        slotProps: { backdrop: { sx: { backgroundColor: 'transparent' } } },
                    },
                },
            }}
            IconComponent={() => null}
            value={selected}
            onChange={(e) => setSelected(e.target.value as string[])}
            onClose={() => setSearchText('')}
            input={
                <Input
                    type="text"
                    startAdornment={<SearchIcon sx={{ marginRight: 2 }} />}
                    sx={{
                        width: '100%',
                        bgcolor: c.bg.light,
                        color: 'rgba(255, 255, 255, 0.7)',
                        paddingLeft: 2,
                        paddingTop: 1,
                    }}
                />
            }
            renderValue={() => 'Type filter name...'}
        >
            <ListSubheader disableSticky sx={{ padding: 0, lineHeight: '24px' }}>
                <TextField
                    size="small"
                    autoFocus
                    placeholder="Type filter name..."
                    fullWidth
                    variant="standard"
                    sx={{ paddingX: 2, paddingY: 1 }}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="primary" />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key !== 'Escape') {
                            e.stopPropagation();
                        }
                    }}
                />
                <DividerFullWidth />
            </ListSubheader>
            {options.map((filter) =>
                containsText(filter, searchText) ? (
                    <MenuItem key={filter} value={filter} sx={{ '&:hover': { color: c.primary } }}>
                        <Checkbox
                            checked={selected?.includes(filter) ?? false}
                            color="primary"
                            size="small"
                        />
                        <ListItemText primary={filter} />
                    </MenuItem>
                ) : null
            )}
            <Box
                display="flex"
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    minWidth: '315px',
                    padding: '8px',
                    gap: '8px',
                    backgroundColor: c.bg.light,
                }}
            >
                {isAllSelected ? (
                    <Button
                        sx={buttonSecondary}
                        size="medium"
                        variant="outlined"
                        color="secondary"
                        onClick={(e) => onUnSelectAll(e)}
                    >
                        Unselect all
                    </Button>
                ) : (
                    <Button
                        sx={buttonSecondary}
                        size="medium"
                        variant="outlined"
                        color="secondary"
                        onClick={(e) => onSelectAll(e)}
                    >
                        Select all
                    </Button>
                )}
                <Button
                    sx={buttonSecondary}
                    size="medium"
                    variant="outlined"
                    color="secondary"
                    onClick={(e) => onCancel(e)}
                >
                    Cancel
                </Button>
                <Button
                    sx={buttonPrimary}
                    size="medium"
                    variant="outlined"
                    color="primary"
                    onClick={(e) => onApply(e)}
                >
                    Apply
                </Button>
            </Box>
        </Select>
    );
};

export default FilterSearch;
