import React from 'react';
import Chart from 'react-apexcharts'

import LinearProgress from '@mui/material/LinearProgress';

import apiService from '../../common/utils/apiService';
import ApiError from '../parts/ApiError';
import {formatQueryTime} from '../../common/utils/utils';
import {colors as c} from '../../common/theme';

interface Props {
    ticker: string;
    date: string;
    timeFrom: any;
    timeTo: any;
}

export default function PriceChart({ ticker, date, timeFrom, timeTo } : Props) {
    const response = apiService<any[]>(
        'chart/' + ticker + '/intra?date=' + date + '&from=' + formatQueryTime(timeFrom) + '&to=' + formatQueryTime(timeTo)
    );

    if (response.error) {
        return <ApiError error={response.error}/>;
    }

    if (response.isLoading) {
        return <LinearProgress />;
    }

    return <Chart type="candlestick"
                  options={{
                      chart: {
                          type: 'candlestick',
                          width: '100%',
                          background: c.bg.light
                      },
                      theme: {
                          mode: 'dark',
                      },
                      title: {
                          text: ticker + ' (' + date + ')',
                          align: 'left'
                      },
                      xaxis: {
                          type: 'datetime',
                          tooltip: {
                              enabled: true,
                              formatter: function(value: string) {
                                  return new Date(value).toUTCString().slice(17, 22);
                              },
                          }
                      },
                      yaxis: {
                          tooltip: {
                              enabled: true
                          }
                      },
                  }}
                  series={[{
                      data: response.result
                  }]}
    />;
}
