import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import {Link} from '@mui/material';
import apiService from '../../common/utils/apiService';
import {gap} from '../../common/utils/utils';

const classes = {
    table: {
        paddingBottom: 1
    },
    title: {
        padding: 1
    },
    accordion: {
        padding: 0
    }
};

interface Search {
    query: string;
    user: string;
    avgPerformance: number;
    greenRatio: number;
    redRatio: number;
    processed: number;
    tickersCount: number;
    duration: number;
    resultCount: number;
    finished: boolean;
}

interface Props {
    type: 'DAILY' | 'INTRA'
}

export default function SearchSummary({type} : Props) {

    const response = apiService<Search[]>(
        type === 'DAILY'
            ? 'filter/cache-values?type='
            : 'filter/intra/cache-values?type=',
        2_000);

    return (
        response.result ? <Accordion>
            <AccordionSummary sx={classes.title} expandIcon={<ExpandMoreIcon />}>
                <Typography>Past searches</Typography>
            </AccordionSummary>
            <AccordionDetails sx={classes.accordion}>
                <Table sx={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>User</TableCell>
                            <TableCell>Avg. performance %</TableCell>
                            <TableCell>Green ratio %</TableCell>
                            <TableCell>Red ratio %</TableCell>
                            <TableCell>Result count</TableCell>
                            <TableCell>Tickers processed</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    {response.result.map((e, i) => (
                        <TableBody key={i}>
                            <TableRow>
                                <TableCell colSpan={8} align="center">
                                    <Link href={'/' + e.query}>{e.query}</Link>
                                </TableCell>
                            </TableRow>
                            <TableRow key={i}>
                                <TableCell>{e.user}</TableCell>
                                <TableCell>{gap(e.avgPerformance)}</TableCell>
                                <TableCell>{gap(e.greenRatio)}</TableCell>
                                <TableCell>{gap(e.redRatio ? -e.redRatio : e.redRatio)}</TableCell>
                                <TableCell>{e ? e.resultCount : 0}</TableCell>
                                <TableCell>{e ? (e.processed + '/' + e.tickersCount) : 0}</TableCell>
                                <TableCell>{e ? e.duration + 's' : 0}</TableCell>
                                <TableCell>{e.finished ? 'Finished' : 'Searching'}</TableCell>
                            </TableRow>
                        </TableBody>
                    ))}
                </Table>
            </AccordionDetails>
        </Accordion> : null
    );
}
