import Label from '../inputs/Label';
import Box from '@mui/material/Box';
import {colors as c} from '../../common/theme';
import {MenuItem, Select} from '@mui/material';
import {StyledExpandMore} from './StyledExpandMore';
import React from 'react';

interface SelectInputProps {
    setValue: (s: string) => void;
    label: string;
    tooltip: string;
    value: string;
    options?: string[]
}

export default function SelectInput({setValue, label, tooltip, value, options} : SelectInputProps) {
    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    return <Label
        label={label}
        tooltip={tooltip}
    >
        <Box
            border="1px solid"
            borderColor={c.primary + '66'}
            bgcolor={c.gray.dark}
            borderRadius={1}
            display="flex"
            padding="12px"
            alignItems="center"
            marginRight="auto"
            sx={{
                ':hover': { borderColor: c.gray.light },
            }}
        >
            <Select
                fullWidth
                variant="standard"
                defaultValue={value}
                onChange={handleChange}
                IconComponent={StyledExpandMore}
                disableUnderline
                MenuProps={{
                    slotProps: {
                        root: {
                            slotProps: { backdrop: { sx: { backgroundColor: 'transparent' } } },
                        },
                    },
                }}
                sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    padding: 0,
                    height: '24px'
                }}
            >
                {options?.map((val, i) => {
                    return (
                        <MenuItem key={i} value={val} sx={{ ':hover': { color: c.primary } }}>
                            {val}
                        </MenuItem>
                    );
                })}
            </Select>
        </Box>
    </Label>
}
