import React, { useCallback, useState } from 'react';

import {Box, Button, Grid, IconButton, Paper, Typography} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ControlPoint } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

import DaySelect from './DaySelect';
import TabPanel from './TabPanel';
import {filterArray, FilterArray, IntraFilters, intraRangeFilters, newFilterArray} from './FilterArray';
import { Range } from '../../types/Range';
import AddFilterDialog from '../parts/AddFilterDialog';
import {
    maxAmountOfDayTFilters,
    maxAmountOfDayZeroFilters,
    maxAmountOfRangeFilters,
} from '../../constants/groupedFilters';
import FilterActions from './FilterActions';
import FilterInputHeader from '../parts/FilterInputHeader';
import DividerFullWidth from '../parts/DividerFullWidth';
import { useFilterContext } from '../../common/context/FilterContext';
import { colors as c } from '../../common/theme';
import IntraRangeFilters from './IntraRangeFilters';
import DayFilters from './DayFilters';
import Toggle from '../parts/Toggle';
import BetaIcon from '../icons/BetaIcon';
import {makeBody} from '../../common/context/FilterContextTypes';

const classes = {
    inputTab: {
        color: `${c.gray.medium} !important`,
        fontSize: '16px',
        textTransform: 'none',
        minWidth: 0,
        minHeight: '36px',
        paddingLeft: 1,
        paddingRight: 1,
        paddingTop: 1,
        paddingBottom: 1,
    },
    removeTab: {
        padding: 0,
        paddingLeft: 1,
        height: '18px',
        width: '18px',
    },
};

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ranges: Range[] = [
    {key: null, label: 'Past ranges'},
    {key: 'TWO_DAYS', label: '-2 days'},
    {key: 'THREE_DAYS', label: '-3 days'},
    {key: 'FOUR_DAYS', label: '-4 days'},
    {key: 'ONE_WEEK', label: '-1 week'},
    {key: 'TWO_WEEKS', label: '-2 weeks'},
    {key: 'ONE_MONTH', label: '-1 month'},
    {key: 'SIX_MONTHS', label: '-6 months'},
    {key: 'ONE_YEAR', label: '-1 year'},
    {key: null, label: 'Future ranges'},
    {key: 'TWO_DAYS_AFTER', label: '+2 days'},
    {key: 'THREE_DAYS_AFTER', label: '+3 days'},
    {key: 'FOUR_DAYS_AFTER', label: '+4 days'},
    {key: 'ONE_WEEK_AFTER', label: '+1 week'},
    {key: 'TWO_WEEKS_AFTER', label: '+2 weeks'},
    {key: 'ONE_MONTH_AFTER', label: '+1 month'},
    {key: 'SIX_MONTHS_AFTER', label: '+6 months'},
    {key: 'ONE_YEAR_AFTER', label: '+1 year'},
];

function rangeLabel(key: string): string {
    for (const range of ranges) {
        if (range.key === key) {
            return range.label;
        }
    }
    return '';
}

export default function FilterToolbar() {
    const { search, finish, filters, clear, isNewLayout } = useFilterContext();
    const [inputDialogOpen, setInputDialogOpen] = React.useState<boolean>(false);
    const [activeFilterType, setActiveFilterType] = React.useState('all');
    const [customFilters, setCustomFilters] = useState<string[][]>([[]]);
    const [addFiltersDialogOpen, setAddFiltersDialogOpen] = useState(false);
    const [tabs, setTabs] = React.useState<FilterArray[]>([]);
    const [intra, setIntra] = React.useState<IntraFilters>(intraRangeFilters());
    const [selectedTab, setSelectedTab] = React.useState<number>(0);
    const [render, setRender] = useState<number>(10);
    const [dayFilters, setDayFilters] = useState<boolean>(true);

    const showDayZeroAddFilters =
        activeFilterType === 'custom' && customFilters[0].length < maxAmountOfDayZeroFilters;
    const showTabsAddFilters =
        activeFilterType === 'custom' &&
        customFilters[selectedTab] &&
        ((tabs[selectedTab - 1]?.type === 'range' &&
            customFilters[selectedTab].length < maxAmountOfRangeFilters) ||
            (tabs[selectedTab - 1]?.type === 'day' &&
                customFilters[selectedTab].length < maxAmountOfDayTFilters));

    const isFilterRemovable = activeFilterType === 'custom';

    const handleChange = (event: React.ChangeEvent<any>, value: any) => {
        setSelectedTab(value);
    };

    const addTab = (value: string) => {
        tabs.push(newFilterArray(value));
        setSelectedTab(tabs.length);
        setTabs(tabs);
        setRender(render + 1);
        setCustomFilters((prev) => [...prev, []]);
    };

    const handleClear = () => {
        setSelectedTab(0);
        setIntra(intraRangeFilters());
        setDayFilters(true);
        setTabs([]);
        clear();
    };

    const removeCustomFilterOnTabDelete = () => {
        setCustomFilters((prev) => {
            const newState = [...prev];
            if (selectedTab >= 0 && selectedTab < newState.length) {
                newState.splice(selectedTab, 1);
            }
            return newState;
        });
    };

    function remove(name: string): void {
        removeCustomFilterOnTabDelete();
        setSelectedTab(0);

        for (let i = 0; i < tabs.length; i++) {
            if (tabs[i].name === name) {
                tabs.splice(i, 1);
                setTabs(tabs);
            }
        }
        setRender(render + 1);
    }

    const handleAddFilter = (label: string) => {
        if (customFilters[selectedTab]?.includes(label)) {
            setCustomFilters((prev) => {
                const newState = [...prev];
                newState[selectedTab] = newState[selectedTab].filter((item) => item !== label);
                return newState;
            });
        } else {
            setCustomFilters((prev) => {
                const newState = [...prev];
                if (!newState[selectedTab]) {
                    newState[selectedTab] = [];
                }
                newState[selectedTab].push(label);
                return newState;
            });
        }
    };

    const handleAddMultipleFilters = (filters: string[]) => {
        setCustomFilters((prev) => {
            const newState = [...prev];
            newState[selectedTab] = filters;
            return newState;
        });
    };

    const handleDeleteFilter = useCallback(
        (label: string) => {
            setCustomFilters((prev) => {
                const newState = [...prev];
                if (newState[selectedTab]) {
                    newState[selectedTab] = newState[selectedTab].filter((item) => item !== label);
                }
                return newState;
            });
        },
        [selectedTab, setCustomFilters]
    );

    function multiTabName(name: string) {
        const n = Number(name);
        if (n) {
            let numberAndSign;
            if (n < 0) {
                numberAndSign = '- ' + n * -1;
            } else {
                numberAndSign = '+ ' + n;
            }
            return 'Day (T ' + numberAndSign + ')';
        }
        return 'Range (' + rangeLabel(name) + ')';
    }

    const handleSwitch = () => {
        setDayFilters(!dayFilters);
    };

    return (
        <Paper>
            <FilterInputHeader
                enabled
                activeFilterTab={activeFilterType}
                setActiveFilterTab={setActiveFilterType}
                tabs={tabs}
                setTabs={setTabs}
                customFilters={customFilters}
                setCustomFilters={setCustomFilters}
            />
            <DividerFullWidth />
            <Box display="flex" alignItems="center" paddingRight="24px">
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                sx={{
                    display: 'flex',
                    padding: '13px 24px',
                }}
            >
                <Tab label="Day 0" {...a11yProps(0)} sx={classes.inputTab} />
                {render >= 0
                    ? tabs.map((value, i) => (
                          <Tab
                              key={i}
                              label={
                                  <div>
                                      <Typography textTransform="none" component="span">
                                          {multiTabName(value.name)}
                                      </Typography>
                                      <IconButton
                                          sx={classes.removeTab}
                                          onClick={() => remove(value.name)}
                                          size="small"
                                      >
                                          <CloseIcon color="primary" />
                                      </IconButton>
                                  </div>
                              }
                              sx={classes.inputTab}
                              {...a11yProps(i + 1)}
                          />
                      ))
                    : null}
            </Tabs>
                <Button
                    variant="outlined"
                    onClick={() => setInputDialogOpen(true)}
                    endIcon={<ControlPoint sx={{ fill: c.primary }} />}
                    sx={{
                        textTransform: 'none',
                        color: c.gray.medium,
                        marginLeft: isNewLayout ? 'auto' : 2,
                        borderColor: c.gray.dim,
                        ':hover': {
                            borderColor: c.gray.light,
                            backgroundColor: 'transparent',
                            '& svg': { fill: c.white },
                        },
                    }}
                >
                    Add
                </Button>
                </Box>
            <DividerFullWidth />
            <TabPanel value={selectedTab} index={0}>
                <Toggle
                    style={{paddingTop: '22px', paddingBottom: '21px'}}
                    checked={!dayFilters}
                    handleSwitch={handleSwitch}
                    leftOption="Daily"
                    rightOption="Intraday"
                    justifyRight
                    icon={<BetaIcon />}
                />
                <DividerFullWidth />
                <Grid
                    container
                    spacing={2}
                    columnSpacing={2}
                    marginTop={0}
                    padding="8px 24px"
                    height={isNewLayout ? 376 : 'auto'}
                    overflow="auto"
                    alignContent="flex-start"
                >
                    {dayFilters ? <DayFilters
                            selectedTab={0}
                            filterArray={filterArray(filters)}
                            activeFilterType={activeFilterType}
                            customFilters={customFilters}
                            isNewLayout={isNewLayout}
                            isFilterRemovable={isFilterRemovable}
                            showDayZeroAddFilters={showDayZeroAddFilters}
                            handleDeleteFilter={handleDeleteFilter}
                            setAddFiltersDialogOpen={setAddFiltersDialogOpen}
                        />
                        : <IntraRangeFilters intraRanges={intra} isNewLayout={isNewLayout} />}
                </Grid>
            </TabPanel>

            {tabs?.map((tab, i) => {
                return (
                    <TabPanel key={i} value={selectedTab} index={i + 1}>
                        <Grid
                            container
                            spacing={2}
                            marginTop={0}
                            padding="8px 24px"
                            height={isNewLayout ? 376 : 'auto'}
                            overflow="auto"
                            alignContent="flex-start"
                        >
                            <DayFilters
                                selectedTab={selectedTab}
                                filterArray={tab.filters}
                                activeFilterType={activeFilterType}
                                customFilters={customFilters}
                                isNewLayout={isNewLayout}
                                isFilterRemovable={isFilterRemovable}
                                showDayZeroAddFilters={showTabsAddFilters}
                                handleDeleteFilter={handleDeleteFilter}
                                setAddFiltersDialogOpen={setAddFiltersDialogOpen}
                            />
                        </Grid>
                    </TabPanel>
                );
            })}
            <FilterActions onSearch={() => search(makeBody(tabs, intra))} onCancel={finish} onClear={handleClear} />
            <DaySelect
                dialogOpen={inputDialogOpen}
                setDialogOpen={setInputDialogOpen}
                addInput={addTab}
                ranges={ranges}
            />
            <AddFilterDialog
                dialogOpen={addFiltersDialogOpen}
                setDialogOpen={setAddFiltersDialogOpen}
                customFilters={customFilters}
                handleAddFilter={handleAddFilter}
                handleAddMultipleFilters={handleAddMultipleFilters}
                selectedTab={selectedTab}
                tabs={tabs}
            />
        </Paper>
    );
}
