import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import CustomDateTimePicker from '../component/parts/CustomDateTimePicker';
import {Paper} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers';
import TimeframeToolbarChart from '../component/chart/TimeframeToolbarChart';

export default function ChartPage() {
    const [ticker, setTicker] = useState<string>('AAPL');
    const [date, setDate] = useState<moment.Moment>(moment());
    const [outputFrom] = useState<moment.Moment>(moment('0930', 'HHmm'));
    const [outputTo] = useState<moment.Moment>(moment('1600', 'HHmm'));

    const handleChangeTicker = (event: any) => {
        setTicker(event.target.value);
    };

    const handleDateFromChange = (date: any) => {
        setDate(date);
    };

    return (
        <div>
            <Grid container alignContent={'center'} alignItems={'center'}>
                <Grid item xs={12} sx={{padding: 2}}>
                    <Paper>
                        <Toolbar>
                            <TextField style={{width: '50%'}} label="Ticker search" value={ticker} onChange={handleChangeTicker}/>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <CustomDateTimePicker date={date} handleChange={handleDateFromChange} label="Date"/>
                            </LocalizationProvider>
                        </Toolbar>
                    </Paper>
                </Grid>
                <Grid item xs={12} sx={{padding: 2}}>
                    <TimeframeToolbarChart ticker={ticker} date={date.format('yyyy-MM-DD')} timeFrom={outputFrom} timeTo={outputTo} />
                </Grid>
            </Grid>
        </div>
    );
}
