import React, { FC } from 'react';
import { Box, MenuItem, Select } from '@mui/material';

import { colors as c } from '../../common/theme';
import {StyledExpandMore} from '../parts/StyledExpandMore';

type StatisticsSelectProps = {
    value: any;
    onChange: (value: 'day0' | 'day2') => void;
    disabled?: boolean;
};

const StatisticsSelect: FC<StatisticsSelectProps> = ({ value, onChange, disabled }) => {

    return (
        <Box
            border="1px solid"
            borderColor={c.gray.dim}
            borderRadius={1}
            display="flex"
            padding="6px 0 6px 14px"
            alignItems="center"
            marginRight="auto"
            width={189}
            sx={{
                ':hover': { borderColor: c.gray.light },
            }}
        >
            <Select
                disabled={disabled}
                fullWidth
                variant="standard"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                IconComponent={StyledExpandMore}
                disableUnderline
                MenuProps={{
                    slotProps: {
                        root: {
                            slotProps: { backdrop: { sx: { backgroundColor: 'transparent' } } },
                        },
                    },
                }}
                sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    marginRight: '14px',
                    padding: 0,
                }}
            >
                <MenuItem value="day0" sx={{ ':hover': { color: c.primary } }}>
                    Statistics
                </MenuItem>
                <MenuItem value="day2" sx={{ ':hover': { color: c.primary } }}>
                    Day 2 statistics
                </MenuItem>
            </Select>
        </Box>
    );
};

export default StatisticsSelect;
