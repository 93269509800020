import {AbstractFilter} from './AbstractFilter';

export class BaseFilter extends AbstractFilter {
    private readonly _value: any;
    private readonly _setValue: (v: any) => void;

    constructor(label: string, tooltip: string, type: string, value: any, setValue: (v: any) => void) {
        super(label, tooltip, type);
        this._value = value;
        this._setValue = setValue;
    }

    get value(): any {
        return this._value;
    }

    get setValue(): (v: any) => void {
        return this._setValue;
    }
}
