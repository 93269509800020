import { useState, useCallback } from 'react';
import qs from 'query-string';
import moment from 'moment-timezone'

export function useQueryString(key, initialValue) {
    const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
    const onSetValue = useCallback(
        newValue => {
            setValue(newValue);
            setQueryStringValue(key, newValue);
        },
        [key]
    );

    return [value, onSetValue];
}

export function useQueryDate(key, initialValue) {
    const qsVal = getQueryStringValue(key);
    const [value, setValue] = useState(qsVal ? moment(qsVal, 'YYYYMMDD') : initialValue);
    const onSetValue = useCallback(
        newValue => {
            setValue(newValue);
            newValue = newValue.format('YYYYMMDD');
            setQueryStringValue(key, newValue);
        },
        [key]
    );

    return [value, onSetValue];
}

export function useQueryTime(key, initialValue) {
    const qsVal = getQueryStringValue(key);
    const [value, setValue] = useState(qsVal ? moment(qsVal, 'HHmm') : initialValue);
    const onSetValue = useCallback(
        newValue => {
            setValue(newValue);
            if (newValue) {
                newValue = newValue.format('HHmm');
                setQueryStringValue(key, newValue);
            } else {
                setQueryStringValue(key, newValue);
            }
        },
        [key]
    );

    return [value, onSetValue];
}

const setQueryStringWithoutPageReload = qsValue => {
    const newUrl =
        window.location.protocol + '//' +
        window.location.host +
        window.location.pathname +
        qsValue;
    window.history.pushState({ path: newUrl }, '', newUrl);
};

const setQueryStringValue = (
    key,
    value,
    queryString = window.location.search) => {
    const values = qs.parse(queryString);
    if (Array.isArray(value)) {
        let emptyValues = true;
        for (let val of value) {
            if (val) {
                emptyValues = false;
                break;
            }
        }
        if (emptyValues) {
            delete values[key];
            const newQsValue = qs.stringify(
                { ...values },
                { skipNull: false });
            setQueryStringWithoutPageReload(`?${newQsValue}`);
            return;
        }
    }
    const newQsValue = qs.stringify(
        { ...values, [key]: value },
        { skipNull: false });
    setQueryStringWithoutPageReload(`?${newQsValue}`);
};

const getQueryStringValue = (
    key,
    queryString = window.location.search) => {
    const values = qs.parse(queryString);
    return values[key];
};
